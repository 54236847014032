<template>
  <button :class='["btn-icon-vertical" ,"btn-square"  ,"br-tr" ,"btn-transition" ,"btn" ,{"btn-primary":!delivery},{"btn-warning":delivery}]' @click="switchDelivery()">
    <i class="btn-icon-wrapper" style="display:block !important;">
      <font-awesome-icon v-if="delivery" :icon="['fal','biking-mountain']"/>
      <font-awesome-icon v-else :icon="['fal','burger-soda']"/>
    </i>

  </button>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {library} from "@fortawesome/fontawesome-svg-core"
import {
  faBikingMountain,
  faBurgerSoda
} from "@fortawesome/pro-light-svg-icons";
import {mapState,mapGetters} from "vuex"
import {Events} from "@/plugins/events";
import randomString from "randomstring";

library.add(faBikingMountain,
    faBurgerSoda  )


export default {
  name: "ButtonsDeliveryPickup",
  components:{
    'font-awesome-icon':FontAwesomeIcon,
  },
  props:{
    posType: String,
  },
  data(){
    return{
    }
  },
  computed:{
    ...mapState([
        'pos',
    ]),
    ...mapGetters({
      delivery:'pos/gastro/getDelivery',
      deliveryInfo:'pos/gastro/getDeliveryInfo',
    })
  },
  methods:{
    switchDelivery(){
      if(this.posType==='retail' || this.posType==='wholesaleRetail'){
        return
      }

      if(!this.pos.gastro.customer){
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_pleaseSelectCustomerFirstToSwitchToDelivery'),
          color: "warning"
        });
        return;
      }

      if(this.deliveryInfo){
        this.$store.dispatch('pos/gastro/setDelivery',!this.delivery);
        this.$store.commit('pos/gastro/setDeliveryInfo',null);
      }else{
          this.$root.$emit('showDeliveryAreaDialog')
      }
    },
  },
  mounted() {
    this.$root.$on('setDelivery',()=>{
      this.switchDelivery();
    });
  },
  beforeDestroy() {
    this.$root.$off('setDelivery')
  }
}
</script>

<style scoped>

</style>
