<template>
  <v-dialog v-if="this.$vuetify.breakpoint.smAndDown" v-model="dialog" max-width="600px" @click:outside="closeDialog()" >
    <v-card>

      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="text-black text-left card-header-tab card-header pt-1 pl-4 pr-1 pb-1" elevation="0">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ $t('generic.lang_createParty') }}
        </div>
      </v-card-title>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex style="margin-top: 20px;" xs12>
              <v-text-field v-model="partyName" :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_party')"
                            autofocus
                            dense outlined
                            required @focus="showTouchKeyboard"/>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions class="card-footer pt-0 pb-0 text-right">
        <v-btn color="error" small text @click="closeDialog()">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer/>
        <v-btn :disabled="!isNameValid || loading" :loading="loading" class="text-white mx-auto" color="#FF6969"
               elevation="0"
               rounded small @click="addParty">
          {{ $t('generic.lang_next') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-else v-model="dialog" max-width="600px" @click:outside="closeDialog()" >
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('generic.lang_createParty') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0"/>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex style="margin-top: 20px;" xs12>
              <v-text-field v-model="partyName"
                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="$t('generic.lang_party')"
                            filled
                            outlined
                            autofocus
                            @keypress="preventSpecialChars"
                            required @focus="showTouchKeyboard"/>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-divider class="ma-0"/>
      <v-card-actions>
        <v-btn color="error" text @click="closeDialog()">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer/>
        <v-btn :disabled="!isNameValid || loading" :loading="loading" color="success" @click="addParty">
          {{ $t('generic.lang_next') }}
        </v-btn>
      </v-card-actions>
      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="touchKeyboard"
                            :cancel="touchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidth"/>
      </div>
    </v-card>

  </v-dialog>
</template>
<style scoped>
#onScreenKeyboardDiv {
  z-index: 299 !important;
}
</style>
<script>
import {mapState} from "vuex";
import validation from "../../mixins/validation/validation";
import KeyboardMixIns from "../../mixins/KeyboardMixIns";

export default {
  name: "PartiesAddDialog",
  props: {
    showDialog: Boolean,
    posType: String
  },
  mixins:[validation, KeyboardMixIns],
  data() {
    return {
      dialog: this.showDialog,
      loading: false,
      partyName: ""
    }
  },

  computed: {
    ...mapState(['pos']),
    isNameValid() {
      if (this.partyName.length < 1)
        return false;

      let party = this.pos.gastro.table.parties.find((party) => {
        return (party.name.toString().toLowerCase() === this.partyName.toString().toLowerCase())||(party.name.toString().toLowerCase().replace('partei ','')===this.partyName.toString().toLowerCase());
      });

      if (!party)
        return true;

      return false;
    }
  },

  watch: {
    showDialog(val) {
      this.dialog = val;
    },
    dialogOpen(val) {
      if (!val) {
        this.closeDialog();
      }
    }
  },
  methods: {
    closeDialog(createdPartyName = "") {
      this.resetData();

      this.$emit("closeDialog", createdPartyName);
    },
    resetData() {
      this.loading = false;
      this.partyName = "";
    },
    addParty() {
      this.loading = true;

      this.$store.dispatch("pos/gastro/addTableParty", {
        posType: this.posType,
        party: {
          name: this.partyName
        }
      }).then((new_name) => {
        this.loading = false;
        this.closeDialog(new_name);
      });
    }
  }
}
</script>
