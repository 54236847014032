<template>
  <v-dialog max-width="600" scrollable persistent v-model="showDialog">
    <v-card>
      <v-img
          height="70"
          src="@/assets/images/sihot.png"
          contain
          style="margin-top: 10px; margin-bottom: 15px;"
      ></v-img>

      <v-card-text>
        <v-alert style="margin-top: 20px; margin-bottom: 60px;" color="error" dark v-if="errorMessage.length > 0">
          {{ convertErrorMessage }}
        </v-alert>

        <v-text-field style="margin-top: 20px;" :disabled="loading" @keyup.enter="checkVoucher" @focus="showTouchKeyboard" outlined dense
                      :label="$t('erp.lang_voucher')" :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="giftcard"></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" @click="closeDialog" :disabled="loading" text>{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="loading || giftcard.length < 2" :loading="loading" @click="checkVoucher">
          {{ $t('generic.lang_add') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard" style="z-index: 299 !important;">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"/>
    </div>

  </v-dialog>
</template>

<style scoped>
.occupationTable tr td:first-child {
  font-weight: bold;
}

.v-list .v-list-item:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
</style>

<script>
import {ENDPOINTS} from "@/config";
import KeyboardMixIns from "../../../mixins/KeyboardMixIns";
import {mapGetters} from "vuex";

export default {
  name: "ButtonsSIHOTVoucherDialog",
  mixins: [KeyboardMixIns],
  props: {
    showDialog: Boolean,
    posType: String
  },

  data() {
    return {
      loading: false,
      giftcard: "",
      randomTransNo: "",
      errorMessage: ""
    }
  },

  watch: {
    showDialog(val) {
      if (!val) {
        this.selectedRoom = null;
        this.giftcard = "";
        this.randomTransNo = "";
        this.errorMessage = "";
      } else {
        this.randomTransNo = this.generateTransNo(6);
      }
    },
    searchInput() {
      this.errorMessage = "";
    }
  },

  computed: {
    ...mapGetters({
      gastroTotalPrice: 'pos/gastro/totalPrice',
      retailTotalPrice: 'pos/retail/totalPrice',
      wholesaleRetailTotalPrice: 'pos/wholesaleRetail/totalPrice',
      retailGoodsValue: 'pos/retail/goodsValue',
      wholesaleRetailGoodsValue: 'pos/wholesaleRetail/goodsValue',
      gastroGoodsValue: 'pos/gastro/goodsValue'
    }),
    totalPrice() {
      if (this.posType === "gastro") {
        return this.gastroTotalPrice;
      }

      if (this.posType === "retail") {
        return this.retailTotalPrice;
      }
      
      if (this.posType === "wholesaleRetail") {
        return this.wholesaleRetailTotalPrice;
      }

      return 0;
    },
    goodsValue() {
      if (this.posType === "retail") {
        return this.retailGoodsValue;
      }
      
      if (this.posType === "wholesaleRetail") {
        return this.wholesaleRetailGoodsValue;
      }

      if (this.posType === "gastro") {
        return this.gastroGoodsValue;
      }

      return 0;
    },
    convertErrorMessage() {
      if (this.errorMessage === "gift voucher not found") return this.$t('generic.lang_couponNotFound');

      return this.errorMessage;
    }
  },

  methods: {
    generateTransNo(length) {
      let result = '';
      const characters = '0123456789';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    checkVoucher() {
      //CHECK IF TOTAL PRICE > 0
      if (this.goodsValue <= 0) {
        this.errorMessage = this.$t('generic.lang_no_items_booked_yet');
        return;
      }

      if (this.totalPrice <= 0) {
        this.errorMessage = this.$t('generic.lang_NoMoreVouchersCanBeBooked');
        return;
      }

      this.loading = true;
      this.errorMessage = "";

      //AXIOS CALL
      this.axios.post(ENDPOINTS.SIHOT.SEARCHVOUCHER, {
        query: this.giftcard,
        randomTransNo: this.randomTransNo
      }).then((res) => {
        this.loading = false;

        if (res.data.success) {
          // TODO: @dennis
          // CHECK IF VOUCHER IS VALID
          this.addVoucher(res.data.voucher.number, res.data.voucher.value);
        } else {
          this.errorMessage = res.data.error.code;
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    addVoucher(voucherID = "", voucherValue = 10) {
      let ean = "SIHOTVOUCHER$"+voucherID;

      //CREATE ITEM AS VOUCHER
      let item = {
        id: ean,
        ean: ean,
        name: "SIHOT Gutschein "+voucherID,
        originalSellPrice: (voucherValue * -1),
        sellPrice: (voucherValue * -1),
        weight: null,
        selectedExtras: [],
        mealSizeID: 0,
        depositgroupID: 0,
        discount: 0,
        warenameOverwrite: null,
        individualBookingNo: null
      };

      //BOOK VOUCHER
      this.$store.dispatch("pos/" + this.posType + "/bookItem", item).then(() => {
        this.closeDialog();
      });
    },
  }
}
</script>
