<template>
  <div>
    <button @click="showDialog = true" class="btn-icon-vertical btn-square br-tr btn-transition btn btn-outline-primary pa-0">
      <i class="btn-icon-wrapper" style="display:block !important;">
        <font-awesome-icon :icon="['fal', 'eject']"/>
      </i>
      <span v-if="showButtonText">{{ $t('generic.lang_cashDrawer') }}</span>
    </button>

    <ButtonsOpenCashDrawerDialog :show-dialog="showDialog" @close="showDialog = false"></ButtonsOpenCashDrawerDialog>
  </div>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {
  faEject,
} from '@fortawesome/pro-light-svg-icons'
import ButtonsOpenCashDrawerDialog from "@/components/pos/buttons/ButtonsOpenCashDrawerDialog";

library.add(
    faEject,
);

export default {
  name: "ButtonsOpenCashDrawer",

  components: {
    ButtonsOpenCashDrawerDialog,
    'font-awesome-icon': FontAwesomeIcon,
  },

  props: {
    posType: String,
    showButtonText: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showDialog: false,
    }
  }
}
</script>

<style scoped>
.btn-icon-vertical {
  padding: 0 !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>