<template>
  <v-dialog v-model="showDialog" persistent max-width="900">
    <v-card :disabled="loading">
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('erp.lang_open_order') }}</v-toolbar-title>

        <v-spacer />

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0" />
      <v-card-text class="overflow-y-auto px-0" style="max-height: 400px;">
        <v-container v-if="orders.length === 0 && !loading">
          <v-row>
            <v-col cols="12">
              <v-alert text color="primary" class="text-center">{{ $t('generic.lang_noOrdersFound') }}</v-alert>
            </v-col>
          </v-row>
        </v-container>
        <v-data-table v-if="orders.length > 0" v-model="selected" item-key="tableName" show-select
          :headers="dataTableHeaders" :items="orders" :loading="loading" :sort-by="['timestamp']" :sort-desc="[true]"
          class="elevation-1">
          <template v-slot:item.timestamp="{ item }">
            {{ formatTS(item.timestamp) }}
          </template>
          <template v-slot:item.isTakeAway="{ item }">
            {{item.isTakeAway?$t('generic.lang_offSiteSale'):$t('generic.lang_inHouseSale') }}
          </template>
          <template v-slot:item.crud="{ item }" class="">
            <div class="d-flex align-center justify-space-between">
              <v-btn color="success" @click="openSavedPreOrder(item)">{{ $t('generic.lang_open') }}</v-btn>
              <v-btn fab small color="error" depressed @click="deletePreorders([item.tableName])">
                <v-icon small>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer />
        <v-btn color="error" :disabled="selected.length < 1" @click="deletePreorders(selected.map(el=>el.tableName))">{{ $t('generic.lang_deleteSelection')
          }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ENDPOINTS } from '../../../config';
import { Events } from '../../../plugins/events';

export default {
  name: 'ButtonsPreordersDialog',

  props: {
    showDialog: Boolean,
    posType: String,
    item: Object,
  },

  data() {
    return {
      loading: false,
      selected: [],
      orders: [],
      orderToDelete: null,
      showDeleteWarning: false,
      tseErrorMessage: '',
    };
  },

  computed: {
    ...mapState(['pos']),
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient',
    }),

    dataTableHeaders() {
      return [
        {
          text: "#",
          value: "tableName",
        },
        {
          text: this.$t('generic.lang_pickUpNumber'),
          value: "pick_up_number",
        },
        {
          text: this.$t('generic.lang_Pagernummer'),
          value: "pager_number",
        },
        {
          text: this.$t('generic.lang_location'),
          value: "isTakeAway",
        },
        {
          text: this.$t('generic.lang_date'),
          value: "timestamp",
        },
        {
          text: "",
          value: "crud",
        }
      ]
    }
  },

  watch: {
    showDialog(val) {
      if (val) {
        this.orders = [];
        this.selected = [];
        this.getSavedOrders();
      }
    },
  },

  methods: {
    formatTS(timestamp) {
      return this.$moment.unix(timestamp).format("DD.MM.YYYY HH:mm")
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    getSavedOrders() {
      if (this.posType === 'gastro') {
        this.loading = true;
        this.axios
          .post(ENDPOINTS.POS.GASTRO.PREORDER.GETALL, {
            orderID: 0,
          })
          .then((res) => {
            if (res.data.success) {
              this.selected = [];
              this.orders = res.data.preorder;
            }
          })
          .catch(() => {
          }).finally(() => {
            this.loading = false;
          })
      }
    },
    openSavedPreOrder(order) {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.POS.GASTRO.PREORDER.GETBYTABLENAME, {
          tableName: order.tableName,
        })
        .then((res) => {
          if (res.data.success) {
            this.$store.commit('pos/gastro/setOpenItems', {
              orders: res.data.preorder.items?.map(item => ({ ...item, isWeighted: false, originalSellPrice: Number(item.originalSellPrice), sellPrice: Number(item.sellPrice) })) || [],
              party: {
                name: res.data.preorder.partyName,
              },
            });


            if (res.data.preorder.pick_up_number)
              this.$store.commit('pos/gastro/setPickup', res.data.preorder.pick_up_number);

            if (res.data.preorder.pager_number)
              this.$store.commit('pos/gastro/setPagerNo', res.data.preorder.pager_number);

            this.$store.commit('pos/gastro/setPreorderId', order.tableName);

            this.$store.commit('pos/gastro/setTakeAway', order.isTakeAway? 1: 0);


            this.closeDialog();
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred') + ': ' + res.data.msg,
              color: "error"
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred') + ': ' + err.message,
            color: "error"
          });
        }).finally(() => {
          this.loading = false;
        })
    },
    deletePreorders(orderIds) {
      this.$swal({
        title: this.$t('generic.lang_deletePreorder'),
        text: this.$t('generic.lang_deletePreorderMsg'),
        icon: "warning",
        confirmButtonText: this.$t('generic.lang_delete'),
        cancelButtonText: this.$t('generic.lang_cancel'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.loading = true;

          this.axios
            .post(ENDPOINTS.POS.GASTRO.PREORDER.DELETE, {
              preOrderIds: orderIds
            })
            .then((res) => {
              if (res.data.status==="SUCCESS") {
                this.getSavedOrders();
              } else {
                Events.$emit("showSnackbar", {
                  message: this.$t('generic.lang_errorOccurred') + ': ' + res.data.msg,
                  color: "error"
                });
              }
            })
            .catch((err) => {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred') + ': ' + err.message,
                color: "error"
              });
            }).finally(() => {
              this.loading = false;
            })
        },
        allowOutsideClick: () => false,
      });
    },
  },
};
</script>
