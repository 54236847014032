<template>
  <button :class='["btn-icon-vertical" ,"btn-square","border-right","pa-0","br-tr" ,"btn-transition" ,"btn" ,{"btn-success":(this.deliveryPayment===1)},{"btn-primary":(this.deliveryPayment===3)}]' @click="setDeliveryPayment">
    <i class="btn-icon-wrapper" style="display:block !important;">
      <font-awesome-icon style="font-size: 25px" v-if="deliveryPayment===1" :icon="['fad', 'euro-sign']"/>
      <font-awesome-icon style="font-size: 25px" v-else :icon="['fad', 'credit-card']"/>
    </i>
  </button>
</template>

<script>
import {mapGetters} from 'vuex'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {library} from "@fortawesome/fontawesome-svg-core"
import {
  faEuroSign,faCreditCard
} from "@fortawesome/pro-duotone-svg-icons";


library.add(
    faEuroSign,faCreditCard)

// BAR 1
// EC 3


export default {
  name: "ButtonsDeliveryPayment",
  components:{
    'font-awesome-icon':FontAwesomeIcon,
  },
  computed:{
    ...mapGetters({
      deliveryPayment:'pos/gastro/getDeliveryPayment',
    })
  },
  methods:{
    setDeliveryPayment(){
      this.$store.commit('pos/gastro/setDeliveryPayment',(this.deliveryPayment===1)?3:1)
    }
  }
}
</script>

<style scoped>

</style>
