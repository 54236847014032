export default {
    data() {
        return {
            bookingCount: 0,// counting the number of items that are booking
            isBooking: false,
        }
    },
    mounted() {
        window.eventBus.$on('booking', (status) => {
            if(status){
                this.isBooking = status;
                this.bookingCount++;
            }else{
                this.bookingCount--;
                if(this.bookingCount===0){
                    this.isBooking = false;
                }
            }
            this.$forceUpdate();
        });
    },
    beforeDestroy() {
        window.eventBus.$off('booking');
    }
}