<template>
  <div>
    <v-container fluid>
      <div role="button" @click="toggleDatePicker" class="d-flex justify-space-between white pa-2 radius-sm">
        <div class="d-flex align-center">
          <font-awesome-icon size="2x" :icon="['fal', 'calendar-alt']"
            class="primary--text d-inline"></font-awesome-icon>
          <span class="font-weight-bold ml-4 d-inline">{{
            datePickerTitle
            }}</span>
        </div>
        <div class="d-flex flex-column">
          <v-btn rounded text>
            <font-awesome-icon :icon="['fal',
              isDatePickerExpanded
                ? 'chevron-up'
                : 'chevron-down']
              " class="primary--text d-inline" size="2x"></font-awesome-icon>
          </v-btn>
        </div>
      </div>

      <!-- add expanded content -->
      <v-expand-transition>
        <v-row v-if="isDatePickerExpanded" class="white mt-3 mx-0 radius-sm pa-2">
          <v-col cols="12" v-if="isTimeSlotsVisible" style="max-height: 280px; overflow-y: auto">

            <TimeSlotPicker v-if="isAnySlotLoaded && isTimeSlotsVisible" :slots="slots" v-model="selectedTimeSlot">
            </TimeSlotPicker>
            <ArrivalTimePicker v-else-if="isAnyArrivalTimeLoaded && isTicketHasArrivalTimes" :times="arrivalTimes"
              v-model="arrival_time"></ArrivalTimePicker>
            <v-col cols="12" class="text-right white my-0" style="position: sticky; bottom: -20px">
              <v-btn color="primary" depressed block @click="togglePrices()"
                :disabled="!isTimePickerSubmitButtonEnabled">{{ $t('generic.lang_next') }}
              </v-btn>
            </v-col>
          </v-col>

          <v-col v-if="isTicketOpenAllDay && !isTimeSlotsVisible" cols="12" class="text-right">
            <v-btn color="primary" depressed block @click="togglePrices()" class="mt-4">{{ $t('generic.lang_next') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-container>

    <!-- prices -->

    <v-container fluid>
      <div role="button" @click="togglePrices" class="d-flex justify-space-between white pa-2 radius-sm">
        <div class="d-flex align-center">
          <font-awesome-icon size="2x" :icon="['fal', 'user-friends']"
            class="primary--text d-inline"></font-awesome-icon>
          <span class="font-weight-bold ml-4 d-inline">{{
            $t('generic.lang_please_choose')
            }}</span>
        </div>
        <div class="d-flex flex-column">
          <v-btn rounded text>
            <font-awesome-icon :icon="['fal',
              isPricesExpanded
                ? 'chevron-up'
                : 'chevron-down']
              " class="primary--text d-inline" size="2x"></font-awesome-icon>
          </v-btn>
        </div>
      </div>

      <!-- @start: prices -->
      <v-expand-transition>
        <div v-if="isPricesExpanded" class="w-100">
          <div class="d-flex justify-center" v-if="hasSeatCategories">
            <v-btn-toggle class="mt-2" v-model="seatSelectionMode" mandatory borderless color="primary"
              background-color="white" style="width: 90% !important;">
              <v-btn value="best-seat" width="50%" class="ma-0">
                <v-col class="pa-0">
                  <v-icon size="20">mdi-sofa-single-outline</v-icon>
                  <div class="caption font-weight-bold">{{ $t('eventbee.lang_bestSeat') }}</div>
                </v-col>
              </v-btn>
              <v-btn value="choose-seat" width="50%" class="ma-0" @click="$emit('showSeatingMap')">
                <v-col class="pa-0">
                  <v-icon size="20">mdi-map-legend</v-icon>
                  <div class="caption font-weight-bold">{{ $t('eventbee.lang_chooseSeat') }}</div>
                </v-col>
              </v-btn>
            </v-btn-toggle>
          </div>
          <SeatCategoriesPicker v-if="hasSeatCategories && seatSelectionMode === 'best-seat'"
            :selectedCategories="selected_categories" :seatCategories="ticket.used_seat_categories"
            @setSelectedCategories="selected_categories = $event">
            <v-text-field v-if="askValidFrom" class="my-2" type="date" dense outlined
              :min="$moment.utc().add(1, 'day').format('YYYY-MM-DD')" v-model="validFrom"
              :label="$t('generic.lang_validFrom')" />
          </SeatCategoriesPicker>
          <SelectedSeatsData v-if="hasSeatCategories && seatSelectionMode === 'choose-seat'" :selected-seats="selectedSeats"
            :seatCategories="ticket.used_seat_categories" >
            <v-text-field v-if="askValidFrom" class="my-2" type="date" dense
              :min="$moment.utc().add(1, 'day').format('YYYY-MM-DD')" outlined v-model="validFrom"
              :label="$t('generic.lang_validFrom')" />
          </SelectedSeatsData>
          <TicketsPickerComponent v-else-if="!hasSeatCategories" :prices="prices" v-model="selected_prices"
            :max-places="available_places">
            <v-text-field v-if="askValidFrom" class="my-2" type="date" dense outlined
              :min="$moment.utc().add(1, 'day').format('YYYY-MM-DD')" v-model="validFrom" :rules="[(v)=>!!v || $t('generic.lang_required')]"
              :label="$t('generic.lang_validFrom')" />
          </TicketsPickerComponent>
        </div>
      </v-expand-transition>
      <!-- @end: prices -->
    </v-container>

    <!-- event attribute -->
    <CustomAttributesForm v-if="attribute && attribute.length > 0" v-model="selectedAttributes"
      :attributes="attribute || []" :expanded="isAttributeExpanded" @toggle="toggleAttribute" />
  </div>
</template>

<script>
import TicketsPickerComponent from "./TicketsPickerComponent";
import TimeSlotPicker from "./TimeSlotPicker";
import ArrivalTimePicker from "./ArrivalTimePicker";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import CustomAttributesForm from "./CustomAttributesForm";
import SeatCategoriesPicker from "./SeatCategoriesPicker.vue";
import SelectedSeatsData from "./SelectedSeatsData.vue";

export default {
  name: "EntryTicketComponent",
  components: { CustomAttributesForm, ArrivalTimePicker, TimeSlotPicker, TicketsPickerComponent, FontAwesomeIcon, SeatCategoriesPicker, SelectedSeatsData },
  props: {
    ticket: {
      type: Object,
      default: () => ({})
    },
    selectedCategories: {
      type: Array,
      default: () => []
    },
    selectedSeats: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      isDatePickerExpanded: false,
      date: '',
      seatSelectionMode: 'best-seat',
      selectedAttributes: [],
      isPricesExpanded: false,
      isAttributeExpanded: false,
      dates: [],
      isActionLoading: false,
      selected_prices: [],
      ticket_date: null,
      selected_categories: this.selectedCategories,
      customer: null,
      selectedTimeSlot: null,
      ticketTypeInBooking: null, // 0 - entry ticket, 1 - event ticket
      arrival_time: null,
      validFrom: null
    };
  },
  inject: ['event'],
  computed: {
    hasSeatCategories() {
      return this.ticket?.used_seat_categories?.length > 0 || false
    },
    validTime() {
      return this.ticket?.validTime;
    },
    askValidFrom() {
      return this.ticket?.askValidFrom === 1;
    },
    attribute() {
      return this.ticket?.attributes;
    },
    available_places() {
      if (this.isTicketOpenAllDay && !this.isTimeSlotsVisible)
        return this.ticket.available_places;

      return (this.selectedTimeSlot ?? this.arrival_time)?.available_places;
    },
    isTicketOpenAllDay() {
      return this.ticket.is_open_day === 1;
    },
    isTicketHasArrivalTimes() {
      return Number(this.ticket.has_arrival_time) && this.isTicketOpenAllDay;
    },
    /**
     * Ticket prices
     *
     * @returns {Array}
     */
    prices() {
      return this.ticket?.prices ?? [];
    },
    datePickerTitle() {
      if (this.ticket_date) {
        let title = this.$moment
          .utc(this.ticket_date, 'YYYY-MM-DD')
          .format('DD.MM.YYYY');

        if (this.selectedTimeSlot) {
          title +=
            ' ( ' +
            this.selectedTimeSlot?.from +
            ' - ' +
            this.selectedTimeSlot?.to +
            ' )';
        } else if (this.arrival_time) {
          title += ' ( ' + this.arrival_time?.arrival_time + ' )';
        }

        return title;
      }

      return (
        this.$t('generic.lang_date') + ' / ' + this.$t('generic.lang_time')
      ); //'Datum - Uhrzeit';
    },
    selectedTimeString() {
      if (this.selectedTimeSlot) {
        return (
          this.selectedTimeSlot?.from +
          ' - ' +
          this.selectedTimeSlot?.to +
          ' Uhr'
        );
      } else if (this.arrival_time) {
        return this.arrival_time?.arrival_time + ' Uhr';
      }
      return undefined;
    },
    isTimeSlotsVisible() {
      if (this.isTicketOpenAllDay && this.isTicketHasArrivalTimes) {
        return true;
      }

      return !this.isTicketOpenAllDay;
    },
    emptySlotsMessage() {
      if (!this.isAnySlotLoaded) {
        return this.$t('generic.lang_no_time_slots');
      }
      return this.$t('generic.lang_select_date_first');
    },
    isTimePickerSubmitButtonEnabled() {
      if (this.selectedTimeSlot) return true;
      if (this.arrival_time) return true;
      return false;
    },
    isAnySlotLoaded() {
      return this.slots?.length > 0;
    },
    isAnyArrivalTimeLoaded() {
      return this.arrivalTimes?.length > 0;
    },
    slots() {
      return this.ticket.slots;
    },
    arrivalTimes() {
      return this.ticket.arrival_times;
    },
    today() {
      return this.$moment.utc().format('YYYY-MM-DD');
    },
    canContinue() {
      if (!this.isAnyAttributeSelected) return false;
      if (!this.isBookedRequiredPrices && !this.hasSeatCategories) return false;
      if (this.hasSeatCategories) {
        if (this.seatSelectionMode === 'best-seat' && this.selected_categories.length < 1) return false;
      }
      if (this.askValidFrom) return this.validFrom;
      return true;
    },
    isAnyAttributeSelected() {
      if (this.attribute && this.attribute.length > 0)
        if (this.selectedAttributes.length > 0)
          return (
            this.selectedAttributes.filter(attr => attr.required && !attr.value)
              .length === 0
          );
        else return false;
      return true;
    },
    isBookedRequiredPrices() {

      if (this.hasSeatCategories) {
        return true;
      }

      const pricesWithRequiresBookingIds =
        this.prices
          ?.filter(price => price.requiresBooking)
          ?.map(price => price.id) ?? [];

      return pricesWithRequiresBookingIds.every(priceId => {
        const selectedPrice = this.selected_prices.find(
          price => price.id === priceId
        );
        return selectedPrice?.selected_quantity > 0;
      }) && this.selected_prices.length > 0;
    },
  },
  watch: {
    isDatePickerExpanded(val) {
      if (val) {
        this.isPricesExpanded = false;
      }
    },
    isPricesExpanded(val) {
      if (val) {
        this.isDatePickerExpanded = false;
      }
    },
    selectedTimeSlot: {
      handler(v) {
        this.emitData(true);
      },
      deep: true,
    },
    arrival_time: {
      handler(v) {
        this.emitData(true);
      },
      deep: true,
    },
    selectedAttributes: {
      handler(v) {
        this.emitData();
      },
      deep: true,
    },
    selected_prices: {
      handler(v) {
        this.emitData();
      },
      deep: true,
    },
    selected_categories: {
      handler(v) {
        this.emitData();
      },
      deep: true,
    },
    validFrom(v) {
      this.emitData();
    },
    seatSelectionMode(v) {
      this.emitData();
    },
  },
  methods: {
    emitData(updateTime = false) {
      this.$emit('bookTicket', {
        updateTime: updateTime,
        selectedTimeSlot: this.selectedTimeSlot,
        selectedArrivalTime: this.arrival_time,
        selectedPrices: this.selected_prices,
        ticket_uuid: this.ticket.uuid,
        attribute: this.selectedAttributes,
        canBook: this.canContinue,
        validFrom: this.validFrom,
        askValidFrom: this.askValidFrom,
        validTime: this.validTime,
        start_time: this.ticket.start_time,
        end_time: this.ticket.end_time,
        selected_categories: this.hasSeatCategories && this.seatSelectionMode === 'best-seat' ? this.selected_categories : undefined,
        seatSelectionMode: this.seatSelectionMode
      })
    },
    toggleAttribute() {
      // should validate that the user has selected a date and time
      const requireTimeOrSlotSelection = this.selectedTimeSlot || this.arrival_time

      const openTheWholeDay = this.isTicketOpenAllDay && !this.isTimeSlotsVisible;
      if (!(requireTimeOrSlotSelection || openTheWholeDay)) {
        this.isDatePickerExpanded = true;
        return;
      }
      // should validate that the user has selected a price
      if (!this.isBookedRequiredPrices) {
        this.isPricesExpanded = true;
        return;
      }
      this.isAttributeExpanded = !this.isAttributeExpanded;
      this.isDatePickerExpanded = false;
      this.isPricesExpanded = false;
    },
    /**
     * Close/Expand date picker
     */
    toggleDatePicker() {
      this.isDatePickerExpanded = !this.isDatePickerExpanded;
    },
    /**
     * Close/Expand prices
     */
    togglePrices() {

      const requireTimeOrSlotSelection = (this.selectedTimeSlot || this.arrival_time);

      const openTheWholeDay = this.isTicketOpenAllDay && !this.isTimeSlotsVisible;
      if (requireTimeOrSlotSelection || openTheWholeDay) {
        this.isPricesExpanded = !this.isPricesExpanded;
      } else this.isDatePickerExpanded = true;
    },
    setToday() {
      //"2023-02-15"
      this.ticket_date = this.today;
    },
    submitSelectedTicket() {
      if (this.totalPlacesToBook > 0) {
        // re-assign values to the store
        this.saveToCard(); // see bookingMixin
        this.showAddToCartSuccess(); // see bookingMixin
        this.isPricesExpanded = false;
        this.isDatePickerExpanded = false;
        this.resetState(); // see bookingMixin
      }
    },
    async fakeLoading() {
      return new Promise(resolve => {
        this.isActionLoading = true;
        setTimeout(() => {
          this.isActionLoading = false;
          resolve();
        }, 800);
      });
    },
  },
  mounted() {
    this.isDatePickerExpanded = true;
  }
};
</script>

<style scoped>
#day {
  font-size: 44px;
}

#month_and_year,
#time_range {
  font-size: 22px;
}

#ticket-time {
  max-width: 180px;
  position: relative;
  font-weight: 300 !important;
}

#ticket-time::after {
  position: absolute;
  content: ' ';
  background-color: #cfcfcf;
  height: 100%;
  width: 2px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

#event-title {
  font-size: 32px;
  line-height: 40px !important;
}

#seat,
#location {
  font-size: 22px;
  font-weight: 500;
}

#location {
  color: #929292;
}

#price {
  font-size: 18px;
}

.disabled {
  color: #929292 !important;
}

.availability {
  font-size: 20px;
}
</style>