<template>
  <button :class='["btn-icon-vertical" ,"btn-square"  ,"br-tr" ,"btn-transition" ,"btn" ,"btn-warning",]' @click="showDialog=true">
    <i class="btn-icon-wrapper" style="display:block !important;">
      <font-awesome-icon v-if="!serviceTime" :icon="['fal','clock']" />
      <span v-else class="my-auto" style="font-size: 0.7em;font-style: normal">{{serviceTime}}</span>
    </i>
    <v-dialog
        ref="dialog"
        v-model="showDialog"
        persistent :return-value.sync="time"
        width="290px"
    >
      <v-time-picker
          v-model="time" :min="now"
          full-width format="24hr"
      >
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="showDialog = false"
        >
          {{ $t('generic.lang_cancel') }}
        </v-btn>
        <v-btn
            text
            color="primary"
            @click="saveTime" :disabled="!isValidTime"
        >
          {{ $t('generic.lang_ok') }}
        </v-btn>
      </v-time-picker>
    </v-dialog>
  </button>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {library} from "@fortawesome/fontawesome-svg-core"
import {
  faClock
} from "@fortawesome/pro-duotone-svg-icons";
import {mapState,mapGetters} from "vuex"
import randomString from "randomstring";
import moment from "moment";

library.add(faClock,)


export default {
  name: "ButtonsDeliveryServiceTime",
  components:{
    'font-awesome-icon':FontAwesomeIcon,
  },
  props:{
    posType: String,
  },
  data(){
    return{
      showDialog:false,
      time:'',
      now:'',
    }
  },
  watch:{
    showDialog(val){
      if(val && this.serviceTime){
        this.time = moment(this.serviceTime,'HH:mm').format('HH:mm');
      }else if(val){
        this.now = moment().format('HH:mm')
      }
    },
  },
  computed:{
    ...mapState([
      'pos',
    ]),
    ...mapGetters({
      delivery:'pos/gastro/getDelivery',
      serviceTime:'pos/gastro/getServiceTime',
    }),
    isValidTime(){
      return moment(this.time,'HH:mm').isValid()
    }
  },
  methods:{
    saveTime(){
      if(moment(this.time,'HH:mm').isValid()){
        this.$store.dispatch('pos/gastro/setServiceTime',this.time);
        this.$store.commit('pos/gastro/setForcedVOR',true);// set vor whenever the user sets the service time
      }
      this.showDialog=false;
    },
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>

