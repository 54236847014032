<template>
    <div  class="d-flex">
      <v-card height="100%" width="150" v-for="(user,key) in allUsers" :key="user.userID" :class="{'bg-success':(activeUser.userID===user.userID),'bg-danger':!(activeUser.userID===user.userID),'mx-1':true,'user-block':true}" elevation="1">
        <swipe-list
            v-if="isUser(key)"
            ref="list"
            :items="[user]"
            item-key="userID"
            class="card"
        >
          <template v-slot="{item}">
            <!-- item is the corresponding object from the array -->
            <!-- index is clearly the index -->
            <!-- revealLeft is method which toggles the left side -->
            <!-- revealRight is method which toggles the right side -->
            <!-- close is method which closes an opened side -->
            <v-card @click="setUser(item)" elevation="0">
              <div class="py-2 px-1 d-flex align-center">
                <v-avatar>
                  <v-img
                      @error="item.avatarURI = '@/assets/images/no_avatar.png'"
                      :src="item.avatarURI"
                  />
                </v-avatar>
                <div class="avatar-name ma-0 pl-1">{{ item.name }} {{ item.last_name }}</div>
              </div>
            </v-card>
          </template>

          <template v-slot:right="{item}">
            <v-card style="height: 100%;width:100px;" class="d-flex justify-center bg-danger" elevation="0" tile @click="removeUser(item.userID)">
              <!-- place icon here or what ever you want -->
              <v-icon size="25" class="align-center text-center white--text">
                mdi-minus
              </v-icon>
            </v-card>
          </template>

        </swipe-list>
      </v-card>
      <v-card height="100%" width="100" v-if="allUsers.length<max" class="bg-info" @click="openDialog">
        <v-icon size="25" class="white--text ma-auto" style="position: absolute; margin: auto; inset: 0;">
          mdi-account-plus
        </v-icon>
      </v-card>
        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
                                :layout="touchKeyboard.layout" :options="touchKeyboard.options"
                                id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
        </div>
        <v-dialog persistent v-model="dialog" min-width="300" width="500" max-width="100%">
            <v-card>
                <v-card-title>
                    {{ $t('generic.lang_addUser') }}
                </v-card-title>
                <v-card-text>
                    <v-form ref="loginForm" v-model="valid">
                        <v-container>
                            <v-row style="margin-top: -20px;">
                                <v-col cols="12" >
                                    <v-text-field
                                            :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                                            :disabled="loading"
                                            :label="$t('settings.lang_userid')"
                                            :loading="loading"
                                            @focus="showTouchKeyboard"
                                            type="number"
                                            min="0"
                                            v-model="userID"
                                    />
                                </v-col>

                                <v-col cols="12" >
                                    <v-text-field
                                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                            :disabled="loading"
                                            :error="errors.pw.error"
                                            :error-messages="errors.pw.msg"
                                            :label="$t('generic.lang_password')"
                                            :loading="loading"
                                            @focus="showTouchKeyboard"
                                            type="password"
                                            v-model="pw"
                                    />
                                </v-col>
                                <v-col cols="12" >
                                    <v-text-field
                                            :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                                            :disabled="loading"
                                            :label="$t('settings.lang_userToken') + 'ID'"
                                            :loading="loading"
                                            @focus="showTouchKeyboard"
                                            type="password"
                                            v-model="token"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions >
                    <v-row justify="center" no-gutters>
                        <v-btn large color="red" class="white--text" @click="closeDialog" :loading="loading">
                            {{ $t('generic.lang_cancel') }}
                        </v-btn>
                        <v-btn large color="primary" :disabled="loading || userID === '' || pw.length<3 " :loading="loading" @click="addUser">
                            {{ $t('generic.lang_login') }}
                        </v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="loadingLogin" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <p class="white--text text-center" style="margin-bottom: 5px;">{{this.$t('datatables.lang_Processing')}}</p>

                                <v-progress-linear
                                        indeterminate
                                        color="white"
                                        class="mb-0"
                                > </v-progress-linear>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>


    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex"
    import { SwipeList, SwipeOut } from 'vue-swipe-actions';
    import Template from "../settings/customerdisplay/Template";
    import {Events} from '../../plugins/events';
    import sha512 from "js-sha512";
    import mixin from "../../mixins/KeyboardMixIns";
    import "vue-swipe-actions/dist/vue-swipe-actions.min.css"
    export default {
        name: "MultiUserComponent",
        components:{Template,SwipeList,SwipeOut},
        mixins:[
            mixin
        ],
        data(){
            return{
                dialog:false,
                max:7,
                apiAuthToken:"",
                valid:false,
                loadingLogin:false,
                loading:false,
                timer:null,
                userID:"",
                pw:"",
                errors:{
                    user:"",
                    pw:{
                        error:false,
                        msg:"",
                    },
                },
                token:"",
            }
        },
        watch:{

        },
        computed:{
          ...mapState([
             'pos'
          ]),
            ...mapGetters({
                activeUser:'multiUser/activeUser',
                allUsers:'multiUser/allUsers',
                parentUser:'user/user',
            }),
        },
        methods:{
            addToToken(e) {
                this.token += String.fromCharCode(e.keyCode);

                if (e.which === 13) {
                    if (this.dialog) {
                        if(!!this.password&&!!this.userID){
                            e.preventDefault();
                            this.login(2);
                        }
                    }
                }
            },
            getInitialGastroState(){
              const state = _.cloneDeep(this.pos.gastro);
              state.additionalStatisticUUIDs = [];
              state.takeAway = 0;
              state.pickup = null;
              state.pagerNo = null;
              state.openItems = { "Partei 1": [] };
              state.customer = null;
              return state;
            },
            setUser(selected){
              if(selected.userID === this.activeUser.userID)
                return;
              let state = null;
              if(this.activeUser.userID){
                this.$store.commit('multiUser/setUserGastroState', _.cloneDeep(this.pos.gastro))
              }


              if(selected.gastro){
                this.$store.commit('pos/gastro/updateState', selected.gastro);
              }else if(!this.activeUser.userID){
                this.$store.commit('pos/gastro/updateState', _.cloneDeep(this.pos.gastro));
              }else {
                this.$store.commit('pos/gastro/updateState', this.getInitialGastroState());
              }

              this.$store.commit('multiUser/setActiveUser',{
                userID: selected.userID,
                name: selected.name,
                last_name: selected.last_name,
                tokenColor: selected.tokenColor,
                avatarURI: selected.avatarURI
              });
            },
            userExist(id){
                return !!this.allUsers.find(el => el.userID === parseInt(id))
            },
            isUser(index){
                return (index<this.allUsers.length);
            },
            login(mode) {
                this.loading = true;
                this.loadingLogin=true;
                let data={};
                let key="";
                key=sha512(this.pw);
                if(mode===1){
                    data={
                        user_id: parseInt(this.userID),
                        password:key,
                    }
                }else{
                    data={
                        tokenID:this.token,
                    }
                }


                this.axios.post('get/checkCredentials/', data).then((res) => {
                    this.loading = false;
                    this.loadingLogin=false;
                        if(res.data.status==="SUCCESS"){
                            let id = parseInt(this.userID);
                            if(!this.userExist(id)){
                                this.$store.commit('multiUser/addUser',{
                                    userID: id,
                                    priority: Date.now(),
                                    name:res.data.preName,
                                    last_name: res.data.lastName,
                                    avatarURI: res.data.avatar,
                                    tokenColor: res.data.tokenColor,
                                    gastro: null
                                });
                            }
                        }else if(res.data.status==="ERROR"){
                            this.errors.pw.error=true;
                            this.errors.pw.msg=res.data.msg;
                          Events.$emit("showSnackbar", {
                            color: "error",
                            message: this.$t('generic.lang_errorOccurred')
                          });
                        }else{
                            Events.$emit("showSnackbar", {
                                color: "error",
                                message: this.$t('generic.lang_errorOccurred')
                            });
                        }
                    this.closeDialog();
                    this.clearDialog();

                }).catch((err) => {

                    Events.$emit("showSnackbar", {
                        color: "error",
                        message: this.$t('generic.lang_errorOccurred')
                    });

                    this.loadingLogin=false;
                    this.loading = false;
                })

            },
            openDialog(){
                this.dialog=true;
                const self = this;
                window.addEventListener("keypress", self.addToToken);
                this.timer=setInterval(()=>{
                    self.token = ""
                },5000);
            },
            closeDialog(){
                this.dialog=false;
                window.removeEventListener('keypress', this.addToToken);
                clearInterval(this.timer);
            },
            addUser(){
                if(!this.$refs.loginForm.validate()){
                    return;
                }

                if(this.userExist(this.userID)){
                    Events.$emit("showSnackbar", {
                        color: "warning",
                        message: this.$t('generic.lang_userAlreadyLoggedIn')
                    });
                    this.clearDialog()
                    return;
                }
                this.login(1)

            },
            clearDialog(){
                this.userID="";
                this.pw="";
                this.token="";
                this.errors.pw.error=false;
                this.errors.pw.msg="";
            },
            removeUser(id){
                if(this.userExist(id)){
                    if(this.activeUser.userID===id){
                        this.$store.commit('multiUser/clearActiveUser');
                    }
                    this.$store.commit('multiUser/removeUser',id);
                }
            },
            itemClick(){
                this.$refs.list.closeActions();
            }
        },
        mounted() {
            //window.addEventListener("keypress", this.addToToken);

        },
        destroyed() {
            //window.removeEventListener('keypress', this.addToToken);
        }
    }
</script>

<style scoped>
    .user-block{
        width: 20%;
    }
    .avatar-name{
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-transform: capitalize;
      word-break: normal;
      font-weight: bold;
      text-align: left;
    }
</style>