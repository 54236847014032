<template>
  <div>
    <v-layout>
      <v-flex width="100px">
        <v-menu
          ref="dateValue"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="200px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              outlined
              v-model="dateTimeValue"
              prepend-inner-icon="event"
              :label="label"
              readonly
              @click="toggleToDate"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="de"
            v-if="dateMenu"
            v-model="dateValue"
            @input="toggleToTime"
          ></v-date-picker>
          <v-time-picker
            v-if="timeMenu"
            v-model="timeValue"
            format="24hr"
            min-width="200px"
          ></v-time-picker>
        </v-menu>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: ["label", "value"],
  data() {
    return {
      dateValue: this.value && this.value.date ? this.value.date : null,
      dateMenu: false,
      timeValue: this.value && this.value.time ? this.value.time : null,
      timeMenu: false,
      dateTimeValue:
        this.value.date && this.value.time
          ? this.value.date + " " + this.value.time
          : null,
    };
  },
  methods: {
    emitVal: function () {
      let self = this;
      let currentDate = new Date();
      if (
        (this.timeValue == null || this.timeValue.length <= 0) &&
        (this.dateValue == null || this.dateValue.length <= 0)
      ) {
        this.$emit("input", null);
        this.dateTimeValue = null;
      } else if (this.timeValue == null || this.timeValue.length <= 0) {
        this.$emit("input", { date: self.dateValue, time: "00:00" });
        this.dateTimeValue = this.dateValue + " 00:00";
      } else if (this.dateValue == null || this.dateValue.length <= 0) {
        this.$emit("input", {
          date: currentDate.format("YYYY-MM-DD"),
          time: this.timeValue,
        });
        this.dateTimeValue =
          currentDate.format("YYYY-MM-DD") + " " + this.timeValue;
      } else {
        this.$emit("input", { date: this.dateValue, time: this.timeValue });
        this.dateTimeValue = this.dateValue + " " + this.timeValue;
      }
    },
    toggleToDate: function () {
      this.dateMenu = true;
      this.timeMenu = false;
    },
    toggleToTime: function () {
      this.dateMenu = false;
      this.timeMenu = true;
    },
  },
  watch: {
    dateValue: function (val) {
      this.emitVal();
    },
    timeValue: function (val) {
      this.emitVal();
    },
  },
};
</script>
