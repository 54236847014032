<template>
  <v-container fluid>
    <div
      role="button"
      @click="toggle()"
      class="d-flex justify-space-between white pa-2 radius-sm"
    >
      <div class="d-flex align-center">
        <font-awesome-icon
            :icon="['fal','tags']"
            class="primary--text d-inline"
            size="2x"
        ></font-awesome-icon>
        <span class="font-weight-bold ml-4 d-inline">{{ $t('generic.lang_oneMoreStep') }}</span>
      </div>
      <div class="d-flex flex-column">
        <v-btn rounded text>
          <font-awesome-icon
              :icon="['fal',
                      expanded
                        ? 'chevron-up'
                        : 'chevron-down']
                    "
              class="primary--text d-inline"
              size="2x"
          ></font-awesome-icon>
        </v-btn>
      </div>
    </div>

    <v-expand-transition>
      <div class="white radius-sm" v-if="expanded">
        <v-card
          v-for="(attribute, index) in sortedAttributes"
          :key="attribute.uuid"
          flat
          class="white mx-0 pa-2 mx-0 px-4"
        >
          <v-card-title>
            <font-awesome-icon
                :icon="['fal','tag']"
                class="primary--text d-inline"
            ></font-awesome-icon>
            <span class="font-weight-bold ml-4 d-inline"
              >{{ attribute.name }}
            </span>
            <span v-if="attribute.required" class="error--text pl-3"
              >({{ $t('generic.lang_required') }})</span
            >
            <span v-else class="pl-3"
              >({{ $t('generic.lang_optional') }})</span
            >
          </v-card-title>

          <v-card-text v-if="attribute.type === 'options'">
            <v-radio-group v-model="filledAttributes[attribute.uuid]">
              <v-row class="mx-0">
                <v-col
                  v-for="(option, index) in attribute.attributes"
                  :key="option + '-' + index"
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <v-radio
                    :label="option"
                    :value="option"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-card-text>
          <v-card-text v-else>
            <v-text-field
              v-model="filledAttributes[attribute.uuid]"
              outlined
              dense
              class="pt-0"
              :rules="[
                v =>
                  (attribute.required ? !!v : true) ||
                  $t('generic.lang_requiredField')
              ]"
              :type="attribute.type"
            />
          </v-card-text>
          <v-divider v-if="attributes.length - 1 !== index" class="ma-0" />
        </v-card>
      </div>
    </v-expand-transition>
  </v-container>
</template>

<script>
import { orderBy } from 'lodash';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
export default {
  name: 'CustomAttributesForm',
  components:{
    FontAwesomeIcon
  },
  props: {
    value: {
      type: Array,
      default: rawProps => {
        return [];
      }
    },
    attributes: {
      type: Array,
      required: true
    },
    expanded: {
      type: Boolean
    }
  },
  data() {
    return {
      filledAttributes: {}
    };
  },
  watch: {
    filledAttributes: {
      handler(v) {
        let tmp = [];
        for (let obj of this.attributes) {
          tmp.push({
            uuid: obj.uuid,
            value: v[obj.uuid],
            required: obj.required,
            name: obj.name
          });
        }
        this.$emit('input', tmp);
      },
      deep: true
    }
  },
  computed: {
    sortedAttributes() {
      return orderBy(this.attributes, 'position', 'asc');
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle');
    }
  },
  beforeMount() {}
};
</script>

<style scoped></style>
