<template>
    <div>
        <button @click="showDialog = true" class="btn-icon-vertical btn-square br-tr btn-transition btn btn-outline-primary pa-0">
            <i class="btn-icon-wrapper" style="display:block !important;">
                <font-awesome-icon :icon="['fal', 'calculator']"/>
            </i>
            <span v-if="showButtonText">{{ $t('generic.lang_pager') }}</span>
        </button>
        <ButtonsPagerDialog :pos-type="posType" :show-dialog="showDialog" @closeDialog="showDialog = false" />
    </div>
</template>

<script>
    import {library} from '@fortawesome/fontawesome-svg-core'
    import {
        faCalculator
    } from '@fortawesome/pro-light-svg-icons';
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
    library.add(
        faCalculator,
    );
    import ButtonsPagerDialog from "./ButtonsPagerDialog";


    export default {
        name: "ButtonsPager",
        components: {
            ButtonsPagerDialog,
            'font-awesome-icon':FontAwesomeIcon,
        },
        props: {
            posType: String,
            showButtonText: {
                type:Boolean,
                default:false,
            },
        },
        data(){
            return{
                showDialog:false,
            }
        }
    }
</script>

<style scoped>
.btn-icon-vertical {
  padding: 0 !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>