var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto pa-0 pb-2 transparent",attrs:{"elevation":"0","id":"event-card"},on:{"click":_vm.setEvent}},[_c('v-card-title',{staticClass:"pa-0"},[_c('div',{staticClass:"pa-0 rounded-md",style:({
        position: 'relative',
        width: '100%',
        height: '160px'
      })},[_c('div',{staticClass:"pa-0 rounded-md v-image__image--cover overflow-hidden",style:({
          position: 'relative',
          height: '100%',
          width: '100%',
          backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.95) 100%), url('${_vm.event.image}')`
        }),attrs:{"id":"event-card-image-bg","title":_vm.event.title}},[(this.event.group)?_c('strong',{attrs:{"id":"event-card-group-name"}},[_vm._v(" "+_vm._s(this.event.group.name)+" ")]):_vm._e()])])]),_c('v-card-text',{staticClass:"pa-0"},[_c('div',{staticClass:"pa-0 pt-0 pb-1",attrs:{"id":"event-title"}},[_vm._v(" "+_vm._s(_vm.event.alias ? _vm.event.alias : _vm.event.title)+" ")]),(this.$vuetify.breakpoint.mdAndUp)?_c('v-card-subtitle',{staticClass:"text-left text-justify pa-0 pt-1",attrs:{"id":"event-subtitle"},domProps:{"innerHTML":_vm._s(_vm.event.short_description)}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }