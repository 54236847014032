<template>
  <v-dialog width="900" persistent v-model="showDialog">
    <v-card :disabled="loading">
      <v-card-title>{{ $t('generic.lang_editPhoto') }}</v-card-title>

      <v-card-text v-if="croppedImage !== null">
        <Imagecropper ref="cropper" v-model="croppedImage" :show-delete-button="false" :show-upload-button="false" :aspect-ratio="9 / 8"></Imagecropper>
      </v-card-text>

      <v-card-actions>
        <v-btn text color="error" @click="closeDialog">{{$t('generic.lang_prev')}}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" :loading="loading" @click="saveImage">{{$t('generic.lang_save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Imagecropper from "@/components/common/imagecropper";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "CustomerImageCropDialog",

  components: {Imagecropper},

  props: {
    showDialog: Boolean,
    capturedImage: String,
    customerID: Number
  },

  watch: {
    showDialog(val) {
      if(val)
        this.croppedImage = this.capturedImage;
    }
  },

  data() {
    return {
      croppedImage: null,
      loading: false
    }
  },

  methods: {
    closeDialog() {
      this.croppedImage = null;
      this.$emit("close");
    },
    saveImage() {
      this.loading = true;

      this.$refs.cropper.cropImage();

      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.IMAGE.UPDATE, {
        customerId: this.customerID,
        image: this.croppedImage
      }).then((res) => {
        if(res.data.success) {
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_photoHasBeenSaved')
          });

          this.$emit("finished", this.croppedImage);
        }
        else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_photoCouldNotBeUploaded')
        });
      }).finally(() => this.loading = false)
    }
  }
}
</script>