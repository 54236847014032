<template>
  <button @click="showDialog = true" class="ma-auto btn-icon-vertical text-muted btn-square btn-transition bg-white btn" style="height: 110px !important;">
      <img class="btn-icon-wrapper ma-auto btn-icon-md icons mb-2" width="90%" src="@/assets/images/oracle2.png">

    <ButtonsSuite8SearchDialog @roomSelected="roomSelected" :show-dialog="showDialog" @closeDialog="showDialog = false"></ButtonsSuite8SearchDialog>
  </button>
</template>

<script>
import ButtonsSuite8SearchDialog from "@/components/pos/buttons/ButtonsSuite8SearchDialog";

export default {
  name: "ButtonsSuite8Search",

  components: {
    ButtonsSuite8SearchDialog,
  },

  props: {
    posType: String
  },

  data() {
    return {
      showDialog: false
    }
  },

  methods: {
    roomSelected(payload) {
      this.showDialog = false;

      this.$emit("roomSelected", payload);
    }
  }
}
</script>