import { render, staticRenderFns } from "./ButtonsDeliveryPickup.vue?vue&type=template&id=fbafc888&scoped=true"
import script from "./ButtonsDeliveryPickup.vue?vue&type=script&lang=js"
export * from "./ButtonsDeliveryPickup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbafc888",
  null
  
)

export default component.exports