<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="600">
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('generic.lang_selectCourse') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0"/>
      <v-card-text class="pa-0">
        <div class="grid-menu grid-menu-3col">
          <div class="no-gutters row">
            <template v-for="course in getCourses">
              <div class="col-sm-6 col-xl-4" :key="course.id">
                <button @click="setCourse(course)"
                        :class="'btn-icon-vertical btn-square btn-transition btn btn-outline-primary '+isActiveCourse(course)">
                  <i class="btn-icon-wrapper" style="font-size: 50px;">
                    <font-awesome-icon
                        :icon="['fal',(course.courseIcon===null||course.courseIcon==='')?'utensils-alt':course.courseIcon]"/>
                  </i>
                  {{ course.name }}
                </button>
              </div>
            </template>
            <div class="col-sm-6 col-xl-4 align-self-center">

              <button @click="signalKitchen()"
                      :class="'btn-icon-vertical btn-square btn-transition btn btn-outline-primary block'">
                <template v-if="!loadingPrinting">
                  <i class="btn-icon-wrapper" style="font-size: 50px;">
                    <font-awesome-icon :icon="['fal','long-arrow-right']"/>
                  </i>
                  {{$t('generic.lang_prepareNext')}}
                </template>

                <v-progress-circular
                    v-else indeterminate
                />

              </button>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="errorDialog" persistent width="500">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="text-center">
                <p style="font-size: 17px;">{{ errorDialogMessage }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" v-if="errorDialogShowCancelButton" :disabled="errorDialogLoading" text
                 @click="closeErrorDialog">{{ $t('generic.lang_cancel') }}
          </v-btn>
          <v-spacer/>
          <v-btn color="success" :disabled="errorDialogLoading" :loading="errorDialogLoading" @click="closeErrorDialog">
            {{ errorDialogConfirmButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faBarcode,
  faCheeseburger,
  faCocktail,
  faConciergeBell,
  faCroissant,
  faFish,
  faFrenchFries,
  faGifts,
  faHamburger,
  faHotdog,
  faMeat,
  faPersonCarry,
  faPizzaSlice,
  faSalad,
  faSandwich,
  faShishKebab,
  faSoup,
  faTaco,
  faUtensilsAlt,
  faLongArrowRight,
} from '@fortawesome/pro-light-svg-icons'
import {ENDPOINTS} from "../../../config";
import {Events} from "../../../plugins/events";
import {printDataFromPrinter} from "../../../plugins/printing/printerController";
import {geol} from "@/main";

library.add(
    faHotdog,
    faBarcode,
    faCocktail,
    faPersonCarry,
    faMeat,
    faGifts,
    faConciergeBell,
    faHamburger,
    faCheeseburger,
    faFrenchFries,
    faPizzaSlice,
    faShishKebab,
    faFish,
    faCroissant,
    faSalad,
    faTaco,
    faSandwich,
    faSoup,
    faUtensilsAlt,
    faLongArrowRight,
);

export default {
  name: "ButtonsCourseDialog",
  components: {
    'font-awesome-icon': FontAwesomeIcon,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    posType: String,
    itemCourse:{
      type:Object,
      default:null,
    }
  },
  data() {
    return {
      errorDialog: false,
      dialog: false,
      errorDialogMessage: "",
      loadingPrinting: false,
      errorDialogShowCancelButton: false,
      errorDialogLoading: false,
      errorDialogConfirmButtonText: ""
    }
  },
  watch: {
    showDialog(val) {
      this.dialog = val;
    }
  },
  computed: {
    ...mapState(['courses', 'pos', 'api']),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    ...mapGetters({
      user: 'user/user'
    }),
    invoicePrinter() {

      let defaultPrinter = this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 2) {
            return true;
          }
        }
      });

      let coursePrinter = this.printers.find((printer) => {
        if (this.pos.gastro.selectedCourse && printer.id === this.pos.gastro.selectedCourse.orderbonPrinterID) {
          return true;
        }
      });

      if (coursePrinter) {
        return coursePrinter;
      }else if(defaultPrinter){
        return defaultPrinter;
      }

      return coursePrinter;

    },
    getCourses() {
      return this.courses.courses;
    },
    activeCourse(){
      if (this.itemCourse) {
        return this.itemCourse;
      }

      if (this.pos.gastro.selectedCourse ) {
        return this.pos.gastro.selectedCourse
      }
      return null;
    }
  },
  mounted() {
    // since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it
    // we re gonna use this work arround for now until we find a better way
    if (this.$store.getters['settings/getSettingValue']('geoloc_systemcountry')) {
        geol.locale = this.$store.getters['settings/getSettingValue']('geoloc_systemcountry');
    }
  },
  methods: {
    showErrorDialog(message, showCancelButton = false, closeAction = null, confirmButtonText = null) {
      this.errorDialogLoading = false;
      this.errorDialogShowCancelButton = false;

      this.errorDialogMessage = message;
      this.errorDialogShowCancelButton = showCancelButton;

      if (closeAction !== null)
        this.errorDialogCloseAction = closeAction;
      else
        this.errorDialogCloseAction = "";

      if (confirmButtonText !== null)
        this.errorDialogConfirmButtonText = confirmButtonText;
      else
        this.errorDialogConfirmButtonText = this.$t('generic.lang_next');

      //OPEN ERROR DIALOG
      this.errorDialog = true;
    },
    closeErrorDialog() {
      this.errorDialog = false;

      if (this.errorDialogCloseAction === "close_only")
        return
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    isActiveCourse(course) {

      if (this.itemCourse && this.itemCourse.id === course.id) {
        return 'active';
      }

      if (this.pos.gastro.selectedCourse !== null) {
        if (this.pos.gastro.selectedCourse.id === course.id && !this.itemCourse) {
          return 'active';
        }
      }
      return '';
    },
    setCourse(course) {
      if (this.pos.gastro.selectedCourse !== null && this.pos.gastro.selectedCourse.id === course.id) {
        this.$store.dispatch("pos/setCourse", {
          posType: this.posType,
          course: null,
        }).then(() => {
          this.closeDialog();
        });
      } else {
        this.$store.dispatch("pos/setCourse", {
          posType: this.posType,
          course: course,
        }).then(() => {
          this.closeDialog();
        });
      }
    },
    signalKitchen() {

      if(!this.invoicePrinter){
        this.showErrorDialog(this.$t('generic.lang_printReceiptError'), false, "close_only");
        return;
      }

      this.loadingPrinting = true

      // GET NEXT COURSE
      let course = (this.pos.gastro.selectedCourse === null) ? '' : ':' + this.pos.gastro.selectedCourse.name.toUpperCase()

      // EMIT TO WEBSOCKET SERVER
      this.$socket.emit("kitchenmonitor.nextCourse", {
        course: course,
        table: this.pos.gastro.table.name,
        party: this.pos.gastro.party.name
      });

      // PREPARE PRINT DATA
      let printData = [];
      if (this.invoicePrinter.options && this.invoicePrinter.options.hasOwnProperty("playBuzzer")) {
        if (parseInt(this.invoicePrinter.options.playBuzzer) === 1) {
          printData.push({cashDrawer: true});
        }
      }
      printData.push({
        feed: 1
      });
      printData.push({
        font: "A"
      });
      printData.push({
        align: "center"
      });

      printData.push({
        fontSize: 2
      });

      printData.push({
        text: geol.t('generic.lang_nextCourse')+" :"
      });

      printData.push({
        text: course
      });

      let table = (this.pos.gastro.table.name == 0) ? '' : 'TABLE ' + this.pos.gastro.table.name.toString().toUpperCase()
      let party = this.pos.gastro.party.name.toString().toUpperCase()
      printData.push({
        reverse: "on"
      });

      printData.push({
        text: table + '-' + party
      });

      printData.push({
        reverse: "off"
      });

      printData.push({
        feed: 4
      });

      let date = new Date();

      printData.push({
        text: date.getHours() + ':' + date.getMinutes()
      });
      printData.push({
        text: geol.t('generic.lang_seller') + ': ' + this.user.fullname,
      });
      printData.push({
        feed: 4
      });
      printData.push({
        cut: true
      });

      try {

        // EPSON EPOS PRINT
        printDataFromPrinter(this.invoicePrinter,printData).then(()=>{
          this.closeDialog();
        }).catch((err)=>{
          this.showErrorDialog(this.$t('generic.lang_printReceiptError'), false, "close_only");
        }).finally(()=>{
          this.loadingPrinting = false;
        })

      } catch (err) {
        console.log(err);
        this.loadingPrinting = false;
        this.showErrorDialog(this.$t('generic.lang_printErrorNextCourse'), false, "close_only");
      }


    }
  },
}
</script>
