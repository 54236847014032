<template>
  <v-dialog persistent width="900" v-model="showDialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
      >{{ $t('generic.lang_take_picture') }}</v-btn>
    </template>

    <v-card>
      <v-card-title>{{ $t('generic.lang_take_picture') }}</v-card-title>

      <v-card-text style="margin-top: 20px;">
        <v-select v-model="deviceId" outlined dense :label="$t('generic.lang_selectCamera')" :items="devices" item-value="deviceId" item-text="label"></v-select>
      </v-card-text>

      <v-card-text v-if="showDialog">
        <div class="text-center" v-if="loading">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>

        <vue-web-cam
            ref="webcam"
            :device-id="deviceId"
            width="100%"
            @error="onError"
            @cameras="onCameras"
            @camera-change="onCameraChange"
            @started="onStarted"
        />
      </v-card-text>

      <v-card-actions>
        <v-btn text color="error" @click="showDialog = false">{{$t("generic.lang_cancel")}}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="onCapture">{{ $t('generic.lang_take_picture') }}</v-btn>
      </v-card-actions>
    </v-card>

    <CustomerImageCropDialog @close="showCropDialog = false" @finished="uploadFinished" :customer-i-d="customerID" :show-dialog="showCropDialog" :captured-image="img"></CustomerImageCropDialog>
  </v-dialog>
</template>

<script>
import {WebCam} from "vue-web-cam";
import CustomerImageCropDialog from "@/components/customers/customerimage/CustomerImageCropDialog";
import {Events} from "@/plugins/events";

export default {
  name: "CustomerImageCaptureDialog",

  components: {
    CustomerImageCropDialog,
    "vue-web-cam": WebCam
  },

  props: {
    customerID: Number
  },

  data() {
    return {
      showDialog: false,
      showCropDialog: false,
      loading: false,

      img: null,
      camera: null,
      deviceId: null,
      devices: []
    }
  },

  computed: {
    device: function() {
      return this.devices.find(n => n.deviceId === this.deviceId);
    }
  },

  watch: {
    showDialog(val) {
      if(!val) {
        this.devices = [];
        this.deviceId = null;
        this.camera = null;
        this.img = null;

        this.showCropDialog = false;
      }
      else {
        this.loading = true;
      }
    },
    camera: function(id) {
      this.deviceId = id;
    },
    devices: function() {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    }
  },

  methods: {
    onCapture() {
      // CUSTOMER IMAGE SIZE:
      // WIDTH x HEIGHT
      // 27    x 24
      this.img = this.$refs.webcam.capture();

      this.showCropDialog = true;
    },
    onError() {
      Events.$emit("showSnackbar", {
        color: "error",
        message: this.$t('generic.lang_errorWithTheCamera')
      });
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
    },
    onStarted() {
      this.loading = false;
    },
    uploadFinished(image) {
      this.showDialog = false;

      this.$emit("finished", image);
    }
  }
}
</script>