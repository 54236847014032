<template>
    <div>
        <button @click="openDialog" class="btn-icon-vertical btn-square  br-tr btn-transition btn btn-outline-focus">
            <i class="btn-icon-wrapper">
                <font-awesome-icon :icon="['fad', 'tablet-android']"/>
            </i><span v-if="showButtonText">{{ $t('erp.lang_save_order') }}</span>
        </button>

        <ButtonsPreordersDialog :pos-type="posType" :show-dialog="showDialog" @closeDialog="showDialog = false"/>
    </div>
</template>

<script>
    import {library} from '@fortawesome/fontawesome-svg-core'
    import {
        faTabletAndroid
    } from '@fortawesome/pro-duotone-svg-icons';
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
    library.add(
        faTabletAndroid
    );

    import {mapState} from "vuex";
    import ButtonsPreordersDialog from './ButtonsPreordersDialog.vue';

    export default {
        name: "ButtonsPreorders",

        props: {
            posType: String,
            showButtonText: Boolean
        },

        components: {
            ButtonsPreordersDialog,
            'font-awesome-icon': FontAwesomeIcon
        },

        data() {
            return {
                showDialog: false
            }
        },

        computed: {
            ...mapState([
                'pos'
            ])
        },

        methods: {
            openDialog() {
                this.showDialog = true;
            }
        }
    }
</script>

<style scoped>
    .btn-icon-vertical {
        padding: 0 !important;
    }

    @media only screen and (max-height: 850px) {
        .btn-icon-vertical .btn-icon-wrapper {
            font-size: 150% !important;
        }
    }
</style>