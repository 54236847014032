import lodash from "lodash";

export function mergeInvoiceArray(sourceArray, destinationArray) {
    let resultArray = lodash.cloneDeep(destinationArray);

    // WE HAVE TO COMPARE BOTH ARRAYS AND CHECK IF WE CAN INCREASE ITEM AMOUNT OR
    // IF WE HAVE TO PUSH NEW ITEM TO RESULT ARRAY
    // FEEL FREE TO EXTEND THIS !!!BIG IMPRESSIVE!!! FUNCTION |x.O|
    sourceArray.forEach((sourceArrayItem, index) => {
        const destinationArrayItem = resultArray.find(destinationArrayItem => {
            if (destinationArrayItem.id === sourceArrayItem.id) {
                if (destinationArrayItem.originalSellPrice === destinationArrayItem.sellPrice) {
                    if (destinationArrayItem.discount === 0) {
                        if (!destinationArrayItem.isVoid) {
                            if (!sourceArrayItem.course) {
                                if (destinationArrayItem.selectedExtras.length === 0 && sourceArrayItem.selectedExtras.length === 0) {
                                    if (destinationArrayItem.mealSizeID === sourceArrayItem.mealSizeID) {
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            return false;
        });


        if (destinationArrayItem) {
            destinationArrayItem.amount += sourceArrayItem.amount;
        } else {
            resultArray.push(sourceArrayItem);
        }
    });

    return resultArray;
}
