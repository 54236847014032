<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">TSE-Daten werden exportiert...</span>
      </v-card-title>

      <v-card-text style="min-height: 250px;">
        <v-alert color="info" dark class="text-center" style="margin-top: 40px;">Deine TSE-Daten werden exportiert.
          Dieser Vorgang kann je nach Größe der Dateien einen Moment dauern.
        </v-alert>

        <div class="text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <v-progress-linear style="margin-top: 20px;" color="primary" :value="getProgress"></v-progress-linear>
        </div>
      </v-card-text>
    </v-card>

    <!-- TSE ERROR DIALOG -->
    <v-dialog :value="tseErrorMessage.length > 0" persistent width="400">
      <v-card>
        <v-card-title>Fehler</v-card-title>
        <v-card-text class="text-center">Beim Hochladen des Exports ist ein Fehler aufgetreten. Evtl. ist keine Internetverbindung vorhanden. Keine Sorge! Der Export wird morgen erneut hochgeladen.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click='$emit("exportDone", "")'>Verstanden</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {mapGetters, mapState} from "vuex";

export default {
  name: "FiscalDeviceExportData",

  props: {
    showDialog: Boolean
  },

  watch: {
    showDialog(val) {
      if (!val) {
        this.loading = false;

        //DISABLE LISTENER
        Events.$off("eposDevice", this.eposDeviceCallback);
        Events.$off("GFE_OnReceive", this.GFE_OnReceiveCallback)
      } else {
        Events.$on("eposDevice", this.eposDeviceCallback);
        Events.$on("GFE_OnReceive", this.GFE_OnReceiveCallback)
      }
    }
  },

  data() {
    return {
      loading: false,

      //TSE
      tseDevice: null,
      tseErrorMessage: "",
      tseErrorDialogButton: "",
      exportData: [],

      shiftUUID: "",

      currentStep: 0
    }
  },

  computed: {
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient'
    }),
    getProgress() {
      return ((this.currentStep / 5) * 100);
    }
  },

  beforeDestroy() {
    Events.$off("eposDevice", this.eposDeviceCallback);
    Events.$off("GFE_OnReceive", this.GFE_OnReceiveCallback)
  },

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    bulkExport(shiftsUUIDS){

      console.log(shiftsUUIDS,this.fiscalClient)
      for(let shiftUUID of shiftsUUIDS){
        console.log(shiftUUID,this.fiscalClient)
        this.checkTSE(shiftUUID);
      }
    },
    checkTSE(shiftUUID) {
      this.currentStep = 1;
      this.shiftUUID = shiftUUID;
      this.loading = true;

      //CHECK IF TSE CLIENT IS SETUP
      if (this.fiscalClient === null) {
        return;
      }

      if (this.fiscalClient.device.type === null) {
        this.fiscalClient.device.type = "epsonTSE";
      }

      //FISKALY CLOUD TSE
      if (this.fiscalClient.device.type === "fiskaly") {
        this.uploadExport();
        return;
      }

      //ADD NEW TSE PRINTER
      let tseDevices = this.$eposClass.getTSEPrinters();

      //CHECK IF TSE PRINTER EXISTS
      if (!tseDevices.hasOwnProperty(this.fiscalClient.id)) {
        //ADD TSE
        this.$eposClass.addTSEPrinter(new this.$epson.ePOSDevice(), {
          id: this.fiscalClient.id,
          ip: this.fiscalClient.device.ip,
          TSEProxyIPAdress: this.fiscalClient.device.TSEProxyIPAdress,
          port: this.fiscalClient.device.port,
          deviceID: this.fiscalClient.device.deviceID,
          adminID: 'Administrator',
          clientID: this.fiscalClient.clientID,
        }, false, false, this.fiscalClient.device.useTSEProxy === 1);
      }

      this.tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

      if (this.tseDevice?.tseReady) {
        this.loginAsAdmin();
      } else {
        if (this.tseDevice.connected) {
          //TSE WAS ADDED BUT ISNT READY YET
          this.loading = false;
          this.tseErrorMessage = "TSE ist nicht einsatzbereit...probiere es erneut.";
        } else {
          //TRY TO RE-CONNECT!!!
          this.tseDevice.connect();
        }
      }
    },
    loginAsAdmin() {
      // JUST TRIGGER RUN AS ADMIN
      this.tseDevice.runAsAdmin = true;
      this.tseDevice.GFE_GetChallenge(this.tseDevice.settings.adminID);

      //CREATE ENTRY
      // this.archiveExport();
    },
    archiveExport() {
      this.currentStep = 2;
      this.tseDevice.GFE_ArchiveExport();

      this.tseDevice.events.$once("GFE_OnReceive.ArchiveExport", (payload) => {
        if (payload.result.function === "ArchiveExport") {
          if (payload.result.result === "EXECUTION_OK") {
            this.getExportData();
          } else {
            this.loading = false;
            this.tseErrorMessage = "Konnte Export-Datei nicht erstellen: " + payload.result.result;
          }
        }
      });
    },
    getExportData() {
      this.currentStep = 3;
      this.tseDevice.GFE_GetExportData();

      this.tseDevice.events.$once("GFE_OnReceive.GetExportData", (payload) => {
        if (payload.result.function === "GetExportData") {
          if (payload.result.result === "EXECUTION_OK") {
            if (payload.result.output.exportStatus === "EXPORT_INCOMPLETE") {
              this.exportData.push(payload.result.output.exportData);

              this.getExportData();
            } else if (payload.result.output.exportStatus === "EXPORT_COMPLETE") {
              this.exportData.push(payload.result.output.exportData);
              this.uploadExport();
            }
          } else {
            this.loading = false;
            this.tseErrorMessage = "Konnte Export-Datei nicht herunterladen: " + payload.result.result;

            //CLEAR EXPORT DATA
            this.exportData = [];
          }
        }
      });
    },
    uploadExport() {
      this.currentStep = 4;

      this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALDEVICE.EXPORTDATA.CREATE, {
        exportData: this.exportData,
        shiftUUID: this.shiftUUID
      }).then((res) => {
        if (res.data.success) {
          this.finishExport();
        } else {
          this.loading = false;
          this.tseErrorMessage = res.data.msg;
        }
      }).catch((err) => {
        this.loading = false;
        this.tseErrorMessage = err.message;
      });
    },
    finishExport() {
      this.currentStep = 5;
      this.tseDevice.GFE_FinalizeExport(true);

      this.tseDevice.events.$once("GFE_OnReceive.FinalizeExport", (payload) => {
        if (payload.result.function === "FinalizeExport") {
          if (payload.result.result === "EXECUTION_OK") {
            this.$emit("exportDone", this.shiftUUID);
          } else {
            this.loading = false;
            this.tseErrorMessage = "Konnte Export-Vorgang nicht abschließen.: " + payload.result.result;
          }
        }
      });
    },

    // -----------------------------------------------
    // ------------------ GFE / TSE ------------------
    // ------------------ CALLBACKS ------------------
    // -----------------------------------------------
    eposDeviceCallback(payload) {
      if (payload.action === "createDeviceCallback.error") {
        this.tseErrorMessage = `Einrichtung der TSE konnte nicht gestartet werden.<br>(${payload.code})`;
      }

      if (payload.action === "connectCallback.error") {
        //CONNECTION ERROR
        this.tseErrorMessage = "Verbindung mit der TSE konnte nicht aufgebaut werden. Bitte überprüfe die IP-Adresse und stelle sicher, dass das Gerät eingeschaltet ist."
      }

      if (payload.action === "disconnect") {
        this.tseDevice = null;
      }
    },
    GFE_OnReceiveCallback(payload) {
      if (payload.result.function !== "StartTransaction") {
        if (payload.result.function === "GetStorageInfo") {
          this.archiveExport();
        } else {
          if (payload.result.result !== "EXECUTION_OK") {
            //OTHER ERROR OCCURED -> ABORTING
            this.tseErrorMessage = `Es ist ein Fehler aufgetreten<br>(${payload.result.result})`;
          }
        }
      }
    }
  }
}
</script>
