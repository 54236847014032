<template>
    <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            scrollable
            persistent
    >
        <v-card elevation="0" tile >
            <v-card-title class="pa-1" style="background: #2a5298">
                <v-row no-gutters>
                    <v-col>
                        <v-btn class="pa-0 ma-0 text-white" text @click="dialog=false" color="warning">
                            <v-icon size="40px">mdi-chevron-left</v-icon>
                            <span>{{$t('generic.lang_prev')}}</span>
                        </v-btn>
                    </v-col>
                    <v-col align="center">
                        <p class="text-center text-white ma-0" style="width:100%;">Hello THERE</p>
                    </v-col>
                    <v-col align="right">
                        <v-btn class="pa-0 ma-0 text-white" text @click="dialog=false">
                            <span>Elena Knoth</span>
                            <v-icon size="20px" color="white">mdi-lock</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-stepper v-model="step" class="h-100">
                <v-stepper-header>
                    <!-- x report -->
                    <v-stepper-step step="1" :complete="step > 1" >{{ $t('generic.lang_xReport') }}</v-stepper-step>

                    <v-divider></v-divider>
                    <!-- cash drop -->
                    <v-stepper-step step="2" :complete="step > 2" >{{ $t('generic.lang_cashDrop') }}</v-stepper-step>

                    <v-divider></v-divider>
                    <!-- deposit / expenditure -->
                    <v-stepper-step step="3" :complete="step > 3" >{{ $t('generic.lang_deposit_withdrawal') }}</v-stepper-step>
                    <v-divider></v-divider>
                    <!-- close the cash register -->
                    <v-stepper-step step="4">{{ $t('generic.lang_closeCashier') }}</v-stepper-step>
                </v-stepper-header>
                <v-divider class="pa-0 ma-0"/>
                <v-stepper-items class="pa-0" >
                    <v-stepper-content step="1" class="pa-0" >
                        <v-card elevation="0" tile class="pa-0">
                            <v-card-text class="pa-0">
                                <v-container class="pa-0">
                                    <v-row>
                                        <v-col cols="12" sm="6" md="6" lg="7">
                                            <v-row>
                                                <v-col cols="4" sm="6" md="6" lg="4" xl="4" v-for="(coin) in currency.euro.muenzen" :key="coin.value">
                                                    <v-row no-gutters align="end">
                                                        <v-col cols="6"  align="end" align-self="center" class="pr-1">
                                                            <img :src="coin.image" width="40px"/>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-text-field min="0"
                                                                          v-model="coin.inValue" height="50px" type="number"
                                                                          @focus="remember($event,coin)"
                                                            >
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="4"  sm="6" md="6" lg="4" xl="4" v-for="(paper)  in currency.euro.scheine" :key="paper.value">
                                                    <v-row no-gutters >
                                                        <v-col class="pr-1" cols="6" align="end" align-self="center">
                                                            <img :src="paper.image" width="70px"/>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-text-field min="0" @focus="remember($event,paper)"
                                                                          v-model="paper.inValue"
                                                                          height="50px"
                                                                          type="number"
                                                            >
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6" lg="5">
                                            <v-card elevation="0" height="70%" width="100%">
                                                <span class="pl-1">ist-bestand</span>
                                                <v-card-title class="fsize-4">
                                                    {{total|currency}}
                                                </v-card-title>
                                                <v-card-text class="pa-0 ma-0">
                                                    <NumpadComponent ref="numpad" :numpad-only="true" @updateValue="update" />
                                                </v-card-text>
                                                <v-row align="end">
                                                    <v-col large cols="12" align="end">
                                                        <v-btn label="">
                                                            {{ $t('generic.lang_skip') }}
                                                        </v-btn>
                                                        <v-btn large color="primary" label="">
                                                            {{ $t('generic.lang_save') }}
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>
    </v-dialog>
</template>

<script>
    import NumpadComponent from "./NumpadComponent";
    export default {
        name: "CountingProtocolDialogComponent",
        components: {NumpadComponent},
        props:{
          showDialog:{
              type:Boolean,
              default:false,
          },
        },
        data(){
            return{
                step:1,
                dialog:this.showDialog,
                earnings:0,
                lastFocus:null,
                currency: {
                    euro: {
                        muenzen: [
                            {
                                value: 0.01,
                                image: require("@/assets/images/currency/euro/1cents.png"),
                                inValue:"",
                            },
                            {
                                value: 0.02,
                                image: require("@/assets/images/currency/euro/2cents.png"),
                                inValue:"",
                            },
                            {
                                value: 0.05,
                                image: require("@/assets/images/currency/euro/5cents.png"),
                                inValue:"",
                            },
                            {
                                value: 0.1,
                                image: require("@/assets/images/currency/euro/10cents.png"),
                                inValue:"",
                            },
                            {
                                value: 0.2,
                                image: require("@/assets/images/currency/euro/20cents.png"),
                                inValue:"",
                            },
                            {
                                value: 0.5,
                                image: require("@/assets/images/currency/euro/50cents.png"),
                                inValue:"",
                            },
                            {
                                value: 1,
                                image: require("@/assets/images/currency/euro/1.png"),
                                inValue:"",
                            },
                            {
                                value: 2,
                                image: require("@/assets/images/currency/euro/2.png"),
                                inValue:"",
                            },
                        ],
                        scheine: [
                            {
                                value: 5,
                                image: require("@/assets/images/currency/euro/5.png"),
                                inValue:"",
                            },
                            {
                                value: 10,
                                image: require("@/assets/images/currency/euro/10.png"),
                                inValue:"",
                            },
                            {
                                value: 20,
                                image: require("@/assets/images/currency/euro/20.png"),
                                inValue:"",
                            },
                            {
                                value: 50,
                                image: require("@/assets/images/currency/euro/50.png"),
                                inValue:"",
                            },
                            {
                                value: 100,
                                image: require("@/assets/images/currency/euro/100.png"),
                                inValue:"",
                            }
                        ]
                    }
                },
            }
        },
        computed:{
            total(){
                let somme=0;
                this.currency.euro.muenzen.forEach(coin=>{
                    somme+=coin.value*Number(coin.inValue);
                });
                this.currency.euro.scheine.forEach(paper=>{
                    somme+=paper.value*Number(paper.inValue);
                });
                return somme;
            }
        },
        methods:{
            update(value){
                if(this.lastFocus===null){
                    return;
                }
                this.lastFocus.inValue=value;
            },
            remember(event,currency){
                this.lastFocus=null;
                this.$refs.numpad.resetField();
                this.$refs.numpad.setValue(currency.inValue);

                this.lastFocus=currency;
            },
          closeDialog(){
            this.dialog=false
          }
        },

    }
</script>

<style scoped>

</style>
