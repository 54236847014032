<template>
  <v-row>
    <v-col
      v-for="(timeSlot, i) in slots"
      :key="i"
      cols="3"
    >
      <div
        role="button"
        class="d-flex time-slot-hover justify-space-between radius-sm pa-2 border"
        @click="toggleSelectedTimeSlot(timeSlot)"
        :class="{
          'border-primary': isSelectedTimeSlot(timeSlot),
          background: timeSlot.available_places === 0
        }"
      >
        <div class="d-flex align-center">
          <!-- <i
                            class="fal fa-2x primary--text d-inline fa-clock"
                          ></i> -->
          <span class="font-weight-bold ml-4 d-inline"
            >{{ timeSlot.from }} -
            {{ timeSlot.to }}
          </span>
          <span class="mx-2"
            >( {{ timeSlot.available_places }}
            {{ $t('generic.lang_places') }})</span
          >
        </div>
        <div class="d-flex border-left flex-column pl-2">
          <font-awesome-icon
            :icon="['fal',
              isSelectedTimeSlot(timeSlot)
                ? 'check-circle'
                : 'circle']
            "
            class="fa-2x d-inline"
            :class="isSelectedTimeSlot(timeSlot)?'primary--text':'grey--text'"
          ></font-awesome-icon>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {
  faCheckCircle,faCircle
} from "@fortawesome/pro-light-svg-icons"
import {library} from '@fortawesome/fontawesome-svg-core';

library.add(faCheckCircle,faCircle)

export default {
  name: 'TimeSlotPicker',
  props: {
    slots: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    },
    loadingSlots: {
      type: Boolean,
      default: false
    }
  },
  components:{
    FontAwesomeIcon,
  },
  computed: {
    selectedTimeSlot: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    isSelectedTimeSlot() {
      return slot => {
        if (this.selectedTimeSlot === null) return false;
        if (slot === null) return false;
        if (this.loadingSlots) return false;
        return (
          slot.from === this.selectedTimeSlot?.from &&
          slot.to === this.selectedTimeSlot?.to
        );
      };
    }
  },
  methods: {
    toggleSelectedTimeSlot(timeSlot) {
      if (
        timeSlot.from === this.selectedTimeSlot?.from &&
        timeSlot.to === this.selectedTimeSlot?.to
      ) {
        this.selectedTimeSlot = null;
        return;
      }

      this.selectedTimeSlot = timeSlot;
    }
  }
};
</script>

<style scoped>
.time-slot-hover:hover {
  background-color: #f5f5f5;
  user-select: none;
}
</style>
