<template>
  <div>

    <button
        class="btn-icon-vertical btn-square btn-transition btn btn-outline-primary pa-0"
        @click="showDialog = true">
      <i class="btn-icon-wrapper">
        <font-awesome-icon :icon="['fal','ticket-alt']"/>
      </i>

    </button>
    <ButtonsEventbeeDialog v-if="showDialog" :pos-type="posType" :show-dialog="showDialog" @closeDialog="showDialog = false"/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import ButtonsEventbeeDialog from "./ButtonsEventbeeDialog";

export default {
  name: "ButtonsEventbee",
  props: {
    posType: {
      type: String,
      default: 'gastro',
      validator: (value) => value === 'gastro' || value === 'retail'
    }
  },
  components: {
    ButtonsEventbeeDialog,
    'font-awesome-icon': FontAwesomeIcon,
  },
  data(){
    return{
      showDialog:false,
    }
  },
  computed: {
    ...mapState([
      'pos'
    ])
  },
  methods: {
  }
}
</script>

<style scoped>
.btn-icon-vertical {
  padding: 0 !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>
