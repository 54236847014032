<template>
    <v-dialog max-width="700px" scrollable persistent v-model="showDialog">
        <v-card>
            <v-img
                height="70"
                src="@/assets/images/hs3_large.jpeg"
                contain
                style="margin-top: 10px; margin-bottom: 15px;"
            ></v-img>

            <v-toolbar color="#424242" dark dense flat>
                <v-toolbar-title>HS3 {{ $t('datatables.lang_search') }}</v-toolbar-title>

                <v-spacer/>

                <v-btn @click="closeDialog" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                <!-- Search Input -->
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            :items="occupations"
                            :loading="loading"
                            :search-input.sync="searchInput"
                            class="mx-0"
                            clearable
                            item-value="id"
                            item-text="objectnr"
                            no-filter
                            flat
                            :cache-items="false"
                            :label="$t('generic.lang_customer') +', '+ $t('accounting.lang_zimmer')+', ...'"
                            ref="customerSearch"
                            solo-inverted
                            style="margin-top: 20px;"
                            v-model="selectedOccupationID"
                            :no-data-text="$t('generic.lang_noEntriesFound')"
                        >
                            <template v-slot:item="data">
                                <v-list-item-content>
                                    <v-list-item-title>{{ data.item.guestname }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ data.item.date_from }} - {{ data.item.date_to }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{ $t('accounting.lang_zimmer') }} {{ data.item.objectnr }} // {{ data.item.guest_quantity }} {{ $t('generic.lang_guests') }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text style="padding: 0;" v-if="selectedOccupationID !== null">
                <div v-if="loading" class="text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>

                <div v-else>
                    <v-tabs background-color="#424242" dark centered>
                        <v-tab>{{ $t('generic.lang_booking') }}</v-tab>
                        <v-tab>{{ $t('generic.lang_guests') }}</v-tab>

                        <!-- Occupation Details -->
                        <v-tab-item>
                            <v-simple-table class="occupationTable" v-if="occupation !== null">
                                <template v-slot:default>
                                    <tbody>
                                        <tr>
                                            <td>{{ $t('generic.lang_bookingNo') }}</td>
                                            <td>{{ occupation.occupationid }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('accounting.lang_zimmer') }}</td>
                                            <td>{{ occupation.objectnr }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('generic.lang_from') }}</td>
                                            <td>{{ occupation.date_from }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('generic.lang_to') }}</td>
                                            <td>{{ occupation.date_to }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('generic.lang_amountOfGuests') }}</td>
                                            <td>{{ occupation.guest_quantity.toFixed(0) }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t('generic.lang_guest') }}</td>
                                            <td>{{ occupation.guestname }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-tab-item>

                        <!-- Arriving Guests -->
                        <v-tab-item>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">{{ $t('generic.lang_guestNo') }}</th>
                                            <th class="text-left">{{ $t('generic.lang_name') }}</th>
                                            <th class="text-right"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="guest in arrivingGuests" :key="guest.id">
                                            <td>{{ guest.guestid }}</td>
                                            <td>{{ guest.salutation }} {{ guest.firstname }} {{ guest.lastname }}</td>
                                            <td>
                                                <v-btn @click="selectGuest(guest)" style="float: right;" small color="primary">{{ $t('generic.lang_bookOnGuest') }}</v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-tab-item>
                    </v-tabs>
                </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn @click="closeDialog" color="error" text>{{$t("generic.lang_cancel")}}</v-btn>
                <v-spacer></v-spacer>
                <v-btn :disabled="loading || selectedOccupationID === null" :loading="loading" color="success" @click="selectOccupation">{{ $t('generic.lang_bookToRoom') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>
    .occupationTable tr td:first-child {
        font-weight: bold;
    }
</style>

<script>
import {ENDPOINTS} from "@/config";

export default {
    name: "ButtonsHS3SearchDialog",

    props: {
        showDialog: Boolean
    },

    data() {
        return {
            loading: false,
            selectedOccupationID: null,
            arrivingGuests: [],
            occupation: null,
            searchInput: "",
            occupations: []
        }
    },

    watch: {
        showDialog(val) {
            if(!val) {
                this.selectedOccupationID = null;
                this.occupation = null;
                this.occupations = [];
                this.loading = false;
                this.arrivingGuests = [];
                this.searchInput = "";
            }
        },
        searchInput(val) {
            if(val !== null) {
                if(val.length > 0) {
                    this.searchForItem(val);
                }
            }
        },
        selectedOccupationID(val) {
            if(val !== null)
                this.getOccupation();
        }
    },

    methods: {
        closeDialog() {
            this.$emit("closeDialog");
        },
        //THIS FUNCTION IS FOR GETTING ITEMS FROM AVAILABLE ITEMS
        searchForItem(searchValue) {
            this.loading = true;

            //AXIOS CALL
            this.axios.get(ENDPOINTS.HS3.SEARCH, {
                params: {
                    search: searchValue
                }
            }).then((res) => {
                this.loading = false;

                if (res.status === 200) {
                    if (res.data.results.length > 0) {
                        this.occupations = res.data.results;
                    } else {
                        this.occupations = [];
                    }
                }
            }).catch(() => {
                this.loading = false;
            });
        },
        getOccupation() {
            this.loading = true;

            //AXIOS CALL
            this.axios.post(ENDPOINTS.HS3.OCCUPATION, {
                occupationID: this.selectedOccupationID
            }).then((res) => {
                if(res.data.success) {
                    this.occupation = res.data.occupation;
                    this.arrivingGuests = res.data.arriving_guests;
                }

                this.loading = false;
            }).catch(() => {
                this.loading = false;
            });
        },
        selectOccupation() {
          this.$emit("occupationSelected", this.occupation);
        },
        selectGuest(guest) {
            this.$emit("occupationGuestSelected", {
                occupation: this.occupation,
                guest: guest
            });
        }
    }
}
</script>