<template>
  <div>
    <button v-if="!loading && this.$vuetify.breakpoint.mdAndUp" @click="print"
            class="btn-icon-vertical btn-square  br-tr btn-transition btn btn-outline-focus">
      <i class="btn-icon-wrapper">
        <font-awesome-icon :icon="['fal', 'file-invoice-dollar']"/>
      </i><span v-if="showButtonText">{{ $t('accounting.lang_Zwischenrechnung') }}</span>
    </button>
    <button v-if="!loading && this.$vuetify.breakpoint.smAndDown" @click="print"
            :class="[this.$vuetify.theme.dark? 'dark-bg' : 'bg-white']" class="ma-auto btn-icon-vertical text-muted btn-square btn-transition font-size-sm">
      <i class="btn-icon-wrapper">
        <font-awesome-icon :icon="['fal', 'file-invoice-dollar']"
                           class="btn-icon-wrapper ma-auto btn-icon-md icons mb-2"
                           v-bind:style="{color: this.colors.primary}"/>
      </i>
      <span v-if="showButtonText">{{ $t('accounting.lang_Zwischenrechnung') }}</span>
    </button>


    <div v-else-if="loading" class="text-center">
      <v-progress-circular width="2" style="margin-top: 11px;" indeterminate color="primary"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import {createInvoicePrintingData} from "@/plugins/printing/printInvoice";
import {mapGetters, mapState} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {printDataFromPrinter} from "../../../plugins/printing/printerController";

export default {
  name: "ButtonsPrintInterimBill",

  props: {
    posType: String,
    showButtonText: Boolean,
    colors: Object
  },

  components: {
    'font-awesome-icon': FontAwesomeIcon,
  },

  data() {
    return {
      loading: false
    }
  },

  computed: {
    ...mapState([
      'pos',
      'api',
      'user',
      'settings',
      'posLayoutTemplates'
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    ...mapGetters({
      activeUser: 'multiUser/activeUser',
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
    multiUser() {
      if (this.settings.settings.hasOwnProperty("posGastroLayoutTemplate")) {
        //FIND TEMPLATE
        let templateID = 0;
        templateID = parseInt(this.settings.settings.posGastroLayoutTemplate);

        if (templateID > 0) {
          return this.posLayoutTemplates.templates.some(template => {
            if (templateID === template.id) {
              return (template.multiUser === 1 || template.multiUser === true)
            }
          });
        }
      }

      return false;
    },
    activeUserID() {
      if (this.multiUser)
        if (this.activeUser.userID !== null)
          return parseInt(this.activeUser.userID);

      return this.user.userID;
    },
    realUser() {
      if (this.multiUser)
        if (this.activeUser.userID !== null)
          return this.activeUser;

      return this.user;
    },
    invoiceItems() {
      let invoiceItems = [];

      if (this.posType === "retail") {
        invoiceItems = this.pos.retail.orderedItems;
      }
      
      if (this.posType === "wholesaleRetail") {
        invoiceItems = this.pos.wholesaleRetail.orderedItems;
      }

      if (this.posType === "gastro") {
        if (!this.pos.gastro.hasOwnProperty("table"))
          return invoiceItems;

        if (!this.pos.gastro.table.hasOwnProperty("name"))
          return invoiceItems;

        if (!this.pos.gastro.hasOwnProperty("party"))
          return invoiceItems;

        if (!this.pos.gastro.party)
          return invoiceItems;

        if (!this.pos.gastro.party.hasOwnProperty("name"))
          return invoiceItems;

        if (this.pos.gastro.table.name !== 0) {
          if (this.pos.gastro.orderedItems.hasOwnProperty(this.pos.gastro.party.name)) {
            if (this.pos.gastro.orderedItems[this.pos.gastro.party.name].length > 0) {
              invoiceItems = this.pos.gastro.orderedItems[this.pos.gastro.party.name];
            }
          }
        } else {
          if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
            if (this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
              invoiceItems = this.pos.gastro.openItems[this.pos.gastro.party.name];
            }
          }
        }
      }

      return invoiceItems.map((invoiceItem) => {
        return invoiceItem;
      });
    },
    invoiceItemsToPrint() {
      let items = this._.cloneDeep(this.invoiceItems);

      items.forEach((invoiceItem) => {
        //CHECK IF WE HAVE CHOOSEN TAKE AWAY
        if (this.pos.gastro.takeAway > 0) {
          if (invoiceItem.takeAwayTaxing > 0) {
            invoiceItem.taxValue = this.offSiteTax;
          }
        }

        //DISCOUNT
        if (invoiceItem.discount > 0) {
          invoiceItem.sellPrice = parseFloat((((100 - invoiceItem.discount) / 100) * invoiceItem.sellPrice).toFixed(2));
        }
      });

      return items;
    },
  },

  methods: {
    print() {
      this.loading = true;

      //TRY TO CREATE PRINT DATA
      let printData;

      try {
        //GENERATE INVOICE DATA
        let data = {
          saleID: 0,
          cashierID: this.api.auth.cashierID,
          userID: this.realUser.userID,
          ts: 0,
          moneyGiven: 0,
          paymentType: 1
        };

        if (this.posType === "gastro") {
          data["tableNo"] = this.pos.gastro.table.name;
        }

        if (this.posType === "retail" || this.posType === "wholesaleRetail") {
          data["tableNo"] = "";
        }

        printData = createInvoicePrintingData(this.invoiceItemsToPrint, data, this.invoicePrinter.options, this.realUser, true);

        //CHECK IF LAST ELEMENT IS CUT
        let lastElememt = printData[(printData.length - 1)];

        if (!lastElememt.hasOwnProperty("cut"))
          printData.push({cut: true});
      } catch (err) {
        this.loading = false;
      }

      printDataFromPrinter(this.invoicePrinter,printData).then(()=>{
        this.$emit('printed')
      }).catch((err)=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{
        this.loading = false;
      })



    }
  }
}
</script>

<style scoped>
.btn-icon-vertical {
  padding: 0 !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>
