<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="700"
      permanent
      persistent
      :fullscreen="this.$vuetify.breakpoint.smAndDown"
    >
      <v-card
        outlined
        class="rounded-lg"
        :class="{
          'mx-0': this.$vuetify.breakpoint.smAndDown,
          'px-0': this.$vuetify.breakpoint.smAndDown,
        }"
      >
        <v-card-title class="text-center rounded">
          <h1 class="text-center mx-auto">
            {{ this.dialogContent.total | currency }}
          </h1>
        </v-card-title>
        <v-card-subtitle class="text-center text-muted border-bottom pb-1">
          {{ this.dialogContent.paymentMethod }}
        </v-card-subtitle>
        <v-card-text
          :class="{
            'mx-0': this.$vuetify.breakpoint.smAndDown,
            'px-0': this.$vuetify.breakpoint.smAndDown,
          }"
        >
          <v-row class="mx-0 rounded my-0 mt-5">
            <v-col
              cols="12"
              class="text-center font-weight-bold"
            >
              {{ this.settings.settings.dynamicTipText }}
            </v-col>
            <v-col
              cols="4"
              v-for="tipKey in Object.keys(tips)"
              :key="tipKey"
            >
              <v-card
                class="elevation-0 rounded-lg text-center"
                color="primary"
                @click="setTip(tips[tipKey])"
              >
                <v-card-title class="text-center">
                  <h2 class="text-center mx-auto text-bolder white--text">
                    {{ tips[tipKey] }}%
                  </h2>
                </v-card-title>
                <v-card-subtitle
                  class="text-center text-muted border-bottom pb-1"
                >
                  <h6 class="white--text">
                    {{ percentToAmount(tips[tipKey]) | currency }}
                  </h6>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col col="12">
              <v-text-field
                v-model.number="tip"
                value="0.00"
                type="number"
                step="0.01"
                :rules="[(v) => !!v && isValueValidTip]"
                @input="formatCurrency"
                @keypress="keyboardValidation"
                @keydown.enter="save"
                @focus="showTouchKeyboard"
                :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                :suffix="this.settings.settings.currencySign"
                :label="this.$t('generic.lang_amount_of_the_tip')"
                outlined
                hide-details
                class="rounded-lg"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              class="text-center py-0"
            >
              <v-btn
                color="primary"
                class="rounded-lg mx-auto"
                @click="save"
                block
                depressed
                :disabled="!isValueValidTip"
                large
              >
                {{ this.$t('generic.lang_pay') }}
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              class="px-0"
            >
              <v-row class="px-0 mx-0">
                <v-col
                  cols="12"
                  sm="6"
                  class="text-center py-0"
                  :order="this.$vuetify.breakpoint.xsOnly ? 2 : 1"
                >
                  <v-btn
                    color="error"
                    class="rounded-lg mx-auto"
                    @click="cancel"
                    block
                    depressed
                    outlined
                    large
                  >
                    {{ this.$t('generic.lang_cancel') }}
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  class="text-center py-0"
                  :order="this.$vuetify.breakpoint.xsOnly ? 1 : 2"
                >
                  <v-btn
                    color="primary"
                    class="rounded-lg mx-auto"
                    @click="ignore"
                    block
                    depressed
                    outlined
                    large
                  >
                    {{ this.$t('generic.lang_pay_without_tip') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div
      id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard"
      style="z-index: 299 !important"
    >
      <vue-touch-keyboard
        v-if="touchKeyboard.visible"
        id="onScreenKeyboard"
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidthExpanded"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { roundNumber } from '../../plugins/round';
import KeyboardMixIns from "../../mixins/KeyboardMixIns";

export default {
  name: 'TheTipDialog',
  mixins: [KeyboardMixIns],
  data() {
    return {
      dialogPromise: null,
      dialog: false,
      tip: 0.00,
      dialogContent: {
        total: '',
        paymentMethod: '',
      },
    };
  },
  computed: {
    ...mapState(['settings']),
    tips() {
      if(this.settings.settings.tipPaymentDialogMatrix){
        return JSON.parse(this.settings.settings.tipPaymentDialogMatrix);
      }
      return {};
    },
    percentToAmount() {
      return (percentage) => {
        return roundNumber(
          (Number(this.dialogContent.total) * Number(percentage)) / 100,
          2
        );
      };
    },
    isValueValidTip() {
      return this.tip > 0 && this.tip <= this.dialogContent.total;
    },
  },
  methods: {
    formatCurrency(value) {
      let tmp = value.replace(".", "");
      let tmpWithoutZero = tmp.replace(/^0+/, "");

      if (tmpWithoutZero && tmpWithoutZero.length > 2) {
        tmp = tmpWithoutZero;
        tmp = [tmp.substring(0, tmp.length - 2), '.', tmp.substring(tmp.length - 2, tmp.length)].join('');
        this.tip = tmp;
      } else if (!tmpWithoutZero && tmp && tmp.length > 2) {
        tmp = [tmp.substring(0, tmp.length - 2), '.', tmp.substring(tmp.length - 2, tmp.length)].join('');
        this.tip = tmp;
      } else {
        this.tip = Number(tmp) / 100;
      }
    },
    keyboardValidation(event) {
      let charCode = event.which ? event.which : event.keyCode;
      
      if (charCode == 43 || charCode == 45 || charCode == 46) {
        event.preventDefault();
      }
    },
    clear() {
      this.tip = null;
      this.dialogContent.total = '';
      this.dialogContent.paymentMethod = '';
      this.$socket.emit("customerdisplay.toggleTip", {
        cashierID: this.$store.state.api.auth.cashierID,
        toggle: false,
      });
      this.hideTouchKeyboard();
    },
    setTip(percent) {
      const theAmount = this.percentToAmount(percent);
      const hasDecimalPoint =
        theAmount?.toString()?.includes('.') ||
        theAmount?.toString()?.includes(',');
      this.tip = hasDecimalPoint ? theAmount : `${theAmount}.00`;
    },
    cancel() {
      if (this.dialogPromise)
        this.dialogPromise({
          reason: 'cancel',
          success: false,
        });
      this.dialog = false;
      this.clear();
    },
    ignore() {
      if (this.dialogPromise)
        this.dialogPromise({
          reason: 'ignore',
          success: false,
        });
      this.dialog = false;
      this.clear();
    },
    save() {
      if (this.dialogPromise)
        this.dialogPromise({
          tip: this.tip,
          success: true,
          reason: undefined,
        });
      this.dialog = false;
      this.clear();
    },
    open({ total, paymentMethod }) {
      return new Promise((resolve) => {
        this.dialogContent.total = total;
        this.dialogContent.paymentMethod = paymentMethod;
        this.dialogPromise = resolve;
        this.dialog = true;
      });
    },
  },
};
</script>
