<template>
  <button @click="showDialog = true" class="ma-auto btn-icon-vertical text-muted btn btn-square btn-transition bg-white" style="height: 110px !important;">
    <img class="btn-icon-wrapper ma-auto btn-icon-md icons mb-2" width="90%" src="@/assets/images/sihot.png">

    <ButtonsSIHOTSearchDialog :invoice-total="invoiceTotal" :showDialog="showDialog" @closeDialog="showDialog = false" @payAsGuest="payAsGuest" @roomSelected="roomSelected"></ButtonsSIHOTSearchDialog>
  </button>
</template>

<script>
import ButtonsSIHOTSearchDialog from "@/components/pos/buttons/ButtonsSIHOTSearchDialog";

export default {
  name: "ButtonsSIHOTSearch",

  components: {
    ButtonsSIHOTSearchDialog
  },

  props: {
    posType: String,
    invoiceTotal: Number
  },

  data() {
    return {
      showDialog: false
    }
  },

  methods: {
    roomSelected(payload) {
      this.showDialog = false;

      this.$emit("roomSelected", payload);
    },
    payAsGuest() {
      this.showDialog = false;

      this.$emit("payAsGuest");
    }
  }
}
</script>