var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',_vm._l((_vm.times),function(time,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('div',{staticClass:"d-flex time-slot-hover justify-space-between radius-sm pa-2 border",class:{
        'border-primary': _vm.isSelectedArrivalTime(time),
        background: time.available_places === 0
      },attrs:{"role":"button"},on:{"click":function($event){return _vm.toggleSelectedArrivalTime(time)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-bold ml-4 d-inline"},[_vm._v(_vm._s(time.arrival_time)+" ")])]),_c('div',{staticClass:"d-flex border-left flex-column pl-2"},[_c('font-awesome-icon',{staticClass:"fa-2x d-inline",class:_vm.isSelectedArrivalTime(time)?'primary--text':'grey--text',attrs:{"icon":['fal',
            _vm.isSelectedArrivalTime(time)
              ? 'check-circle'
              : 'circle']}})],1)])])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }