<template>
  <div>
    <button @click="showDialog = true"
            class="btn-icon-vertical btn-square  br-tr btn-transition btn btn-outline-primary">
      <i class="btn-icon-wrapper" style="display:block !important;">
        <font-awesome-icon :icon="['fal', 'gift-card']"/>
      </i>
      <span v-if="showButtonText">{{ $t('analytics.lang_giftCard') }}</span>
    </button>

    <ButtonsGiftCardDialog v-if="!$store.getters['permissions/checkModule'](46)" :pos-type="posType"
                           :show-dialog="showDialog" @closeDialog="showDialog = false"></ButtonsGiftCardDialog>


    <ButtonsSIHOTVoucherDialog v-if="$store.getters['permissions/checkModule'](46)"
                               :pos-type="posType"
                               @closeDialog="showDialog = false"
                               :show-dialog="showDialog"></ButtonsSIHOTVoucherDialog>
  </div>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faGiftCard
} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import ButtonsGiftCardDialog from "./ButtonsGiftCardDialog";
import ButtonsSIHOTVoucherDialog from "@/components/pos/buttons/ButtonsSIHOTVoucherDialog";

library.add(
    faGiftCard
);

export default {
  name: "ButtonsGiftCard",

  props: {
    posType: String,
    showButtonText: Boolean
  },

  components: {
    ButtonsSIHOTVoucherDialog,
    ButtonsGiftCardDialog,
    'font-awesome-icon': FontAwesomeIcon
  },

  data() {
    return {
      showDialog: false
    }
  }
}
</script>

<style scoped>
@media (min-width: 900px) {
  .btn-icon-vertical {
    padding: 0 !important;
  }
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>
