<template>
  <div>
    <v-card
        color="white"
        class="mt-5 pa-2"
        elevation="0"
        width="100%"
    >
      <v-card-text class="pa-0 px-0">
        <v-row no-gutters>
          <!-- start: selected event time -->
          <v-col
              id="ticket-time"
              cols="4"
              sm="2"
              class="text-center py-2"
          >
            <h2
                :class="[!isAvailable ? 'disabled' : '']"
                id="day"
                class="ma-auto primary--text"
            >
              {{ ticketDateFormater(ticket.date, 'DD') }}
            </h2>
            <h4
                :class="[
                      !isAvailable ? 'disabled' : '',
                      $vuetify.breakpoint.smAndDown ? 'pt-1 pb-0' : 'pt-5'
                    ]"
                class="ma-auto"
                id="month_and_year"
            >
              {{ ticketDateFormater(ticket.date, 'MMM') }}
              {{ ticketDateFormater(ticket.date, 'YYYY') }}
            </h4>

            <h4
                class="ma-auto pt-2"
                id="time_range"
            >
              {{ ticket.start_time.substring(0,5) }} - {{ ticket.end_time.substring(0,5) }}
            </h4>
          </v-col>
          <!-- end: selected event time -->
          <v-col
              style="min-width: 300px; max-width: 100%"
              cols="6"
              class="flex-grow-1 flex-shrink-0 px-5 black--text"
          >
            <h2
                :class="[!isAvailable ? 'disabled' : '']"
                id="event-title"
            >
              {{ eventAlias }}
            </h2>

            <h4
                :class="[!isAvailable ? 'disabled' : '']"
                id="seat"
                class="py-1"
            >
              {{ event().seat }}
            </h4>

            <h4 id="location" class="pt-0">
              {{ event().location }}
            </h4>
          </v-col>
          <!-- start: selected event button desktop version only -->
          <v-col
              md="4"
              lg="2"
              cols="2"
              class="text-center"
          >
                  <span
                      v-if="!isAvailable"
                      class="error--text availability ma-auto"
                  >{{ $t('generic.lang_sold_out') }}</span
                  >
            <span v-else class="error--text availability ma-auto text-center">
                    {{$t('generic.lang_tickets_left')}}
              <br/>
              <strong>{{ ticket.available_places }}</strong>
            </span>
          </v-col>
          <!-- call to action -->
          <v-col
              cols="2"
              md="12"
              lg="2"
              class="text-center py-2"
          >
            <div>
              <strong
                  :class="[!isAvailable ? 'disabled' : '']"
                  id="price"
                  class="primary--text mb-3"
              >{{ $t('generic.lang_from') }}
                {{ ticket.price | currency }}</strong
              >
            </div>
          </v-col>
          <!-- end: selected event button desktop version only -->
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "EventTicketHeaderComponent",
  props:{
    ticket: {
      type: Object,
      required:true,
      default: () => ({})
    }
  },
  data(){
    return{

    }
  },
  inject:['event'],
  computed:{
    eventAlias(){
      return this.ticket.alias?this.ticket.alias:this.event().title;
    },
    isAvailable() {
      return this.ticket.available_places > 0;
    },
    ticketDateFormater() {
      return (date_str, part) => {
          return this.$moment(date_str, 'DD.MM.YYYY').format(part);
      };
    }
  },
  methods:{

  }
}
</script>

<style scoped>
#ticket-time {
  max-width: 180px;
  position: relative;
}

#ticket-time::after {
  position: absolute;
  content: '';
  background-color: #cfcfcf;
  height: 100%;
  width: 2px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
#event-title {
  font-size: 28px;
  line-height: 30px !important;
}
#day {
  font-size: 35px;
}

#month_and_year,
#time_range {
  font-size: 16px;
}

#seat,
#location {
  font-size: 16px;
}

#location {
  color: #929292;
}

#price {
  font-size: 22px;
}

.disabled {
  color: #929292 !important;
}
.availability {
  font-size: 18px;
}
</style>