<template>
  <v-dialog v-model="dialogOpen" max-width="600px" scrollable>
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('customers.lang_create_NewCust') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider class="ma-0"/>

      <v-tabs dark>
        <v-tab :disabled="step === 2">{{ $t('customers.lang_cust_create') }}</v-tab>
        <v-tab :disabled="step === 1">{{ $t('generic.lang_take_picture') }}</v-tab>
      </v-tabs>

      <v-card-text v-if="step === 1" style="margin-top: 30px;">
        <v-form ref="createCustomerForm" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <!-- Vorname -->
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="firstName"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('customers.lang_cust_prename')"
                              :loading="loading"
                              :rules="firstNameRules"
                              dense
                              outlined
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>

              <!-- Nachname -->
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="lastName"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('customers.lang_cust_name')"
                              :loading="loading"
                              :rules="lastNameRules"
                              dense
                              outlined
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>

              <!-- Company -->
              <v-col cols="12" sm="12" md="6" >
                <v-text-field v-model="company"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('generic.lang_company')"
                              :loading="loading"
                              dense
                              outlined
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>

              <v-col cols="12" sm="12" md="6" >
                <v-text-field v-model="phone"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('generic.lang_phoneNumber')"
                              :loading="loading"
                              dense
                              outlined
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="street"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('generic.lang_street')"
                              :loading="loading"
                              dense
                              outlined
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="streetno"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('customers.lang_number')"
                              :loading="loading"
                              dense
                              outlined
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="zip"
                              :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                              :disabled="loading"
                              :label="$t('customers.lang_cust_zip')"
                              :loading="loading"
                              dense
                              outlined
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="city"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('settings.lang_city')"
                              :loading="loading"
                              dense
                              outlined
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>
              <!-- E-Mail -->
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="email"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('generic.lang_emailAdresse')"
                              :loading="loading"
                              dense
                              outlined
                              required
                              type="email"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-select v-model="customerGroupID"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :disabled="loading"
                          :label="$t('customers.lang_custGroup')" item-text="name" item-value="id"
                          :loading="loading" :items="customerGroups"
                          dense
                          outlined
                          required
                          @focus="showTouchKeyboard"
                          @keyup.enter="createCustomer"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <!-- Upload Customer Image -->
      <v-card-text v-if="step === 2" style="margin-top: 30px;">
        <CustomerImageCaptureDialog :customer-i-d="createdCustomerID" @finished="imageUploaded"></CustomerImageCaptureDialog>
      </v-card-text>

      <v-divider class="ma-0"/>

      <v-card-actions>
        <v-btn color="error" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer/>
        <v-btn :disabled="loading || !valid" :loading="loading" :class="$vuetify.breakpoint.smAndDown ? 'text-white mx-auto' : ''" :color="($vuetify.breakpoint.smAndDown ? colors.primary : 'success')" :rounded="$vuetify.breakpoint.smAndDown" @click="proceed">
          {{ $t('generic.lang_next') }}
        </v-btn>
      </v-card-actions>

      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard" style="z-index: 299 !important;">
        <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                            :accept="hideTouchKeyboard"
                            :cancel="hideTouchKeyboard"
                            :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidthExpanded"/>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
import {ENDPOINTS} from "../../config";
import KeyboardMixIns from "../../mixins/KeyboardMixIns";
import CustomerImageCaptureDialog from "@/components/customers/customerimage/CustomerImageCaptureDialog";

export default {
  name: "CustomerCreateDialog",

  components: {CustomerImageCaptureDialog},
  mixins: [KeyboardMixIns],
  props: {
    showDialog: Boolean,
    posType: String,
    colors: Object
  },

  data() {
    return {
      dialogOpen: false,
      loading: false,
      step: 1,
      valid: true,
      firstName: "",
      firstNameRules: [
        v => !!v || this.$t('generic.lang_requiredField'),
      ],
      lastName: "",
      lastNameRules: [
        v => !!v || this.$t('generic.lang_requiredField'),
      ],
      email: "",
      company: "",
      phone: "",
      street: "",
      streetno: "",
      zip: "",
      city: "",
      createdCustomerID: 0,
      customerGroups:[],
      customerGroupID:0,
    }
  },

  watch: {
    showDialog(val) {
      this.dialogOpen = val;
    },
    dialogOpen(val) {
      if (!val) {
        this.closeDialog();
      }
    }
  },

  mounted() {
    this.dialogOpen = this.showDialog;
    this.getCustomerGroups();
  },

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
      this.resetData();
    },
    resetData() {
      this.loading = false;
      this.$refs.createCustomerForm.reset();

      this.step = 1;
    },
    createCustomer() {
      if (!this.$refs.createCustomerForm.validate()) {
        return;
      }

      this.loading = true;

      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.CREATE, {
        first_name: this.firstName,
        last_name: this.lastName,
        customerGroup: this.customerGroupID,
        email: this.email,
        street: this.street,
        street_no: this.streetno,
        zip: this.zip,
        city: this.city,
        company: this.company,
        tel_private: this.phone,
        mobil_private: this.phone,
      }).then((res) => {
        this.loading = false;

        if (res.data.success) {
          this.createdCustomerID = res.data.customerID;
          this.step = 2;
        } else {

        }
      }).catch(() => {
        this.loading = false;
      });
    },
    getCustomerGroups(){
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMERGROUPS.GETALL,{

      }).then(res=>{
        if(res.data.success){
          this.customerGroups=res.data.data
        }
      }).catch(err=>{

      }).finally(()=>{

      })
    },
    proceed() {
      if(this.step === 1)
        this.createCustomer();

      // SKIP CUSTOMER IMAGE UPLOADING
      if(this.step === 2)
        this.imageUploaded();
    },
    imageUploaded() {
      this.$emit("customerCreated", this.createdCustomerID);
    }
  }
}
</script>