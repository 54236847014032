<template>
  <v-dialog v-model="showDialog" persistent max-width="600">
    <v-card>
      <v-toolbar flat tile>
        <v-toolbar-title>{{ $t('generic.lang_tip') }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-text-field outlined dense label="Betrag" :disabled="loading" v-model="amount"></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-btn text color="error" @click="closeDialog" :disabled="loading">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" :disabled="loading || amount === 0" :loading="loading" @click="saveTipp">{{ $t('generic.lang_save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ENDPOINTS} from "@/config";

export default {
  name: "AfterSaleDialogTipp",

  props: {
    showDialog: Boolean,
    invoiceData: Object
  },

  data() {
    return {
      loading: false,
      amount: 0
    }
  },

  computed: {
    invoiceUUID() {
      if(this.invoiceData !== null)
        if(this.invoiceData.hasOwnProperty("invoiceUUID"))
          return this.invoiceData.invoiceUUID;

      return this.invoiceData.invoiceUUID;
    }
  },

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },

    saveTipp() {
      this.loading = true;

      this.axios.post(ENDPOINTS.POS.SALE.TIPP, {
        invoiceUUID: this.invoiceUUID,
        value: this.amount
      }).then((res) => {
        if(res.data.success)
          this.closeDialog();
      }).finally(() => this.loading = false)
    }
  }
}
</script>