<template>
  <v-container class="ma-0 bg-light-grey" fluid style="max-height: calc(100% - 128px);overflow-y: auto;">
    <EventDetailsLoaderComponent
        v-if="loading && !this.event()"
    />

    <v-fade-transition
        v-else-if="this.event() && !loading"
        class="pt-0 px-0 ma-0"
    >
      <v-row no-gutters
             class="text-left fade-transition mt-4"
      >
        <!-- start: header -->
        <v-col cols="12">
          <span id="tickets" class="d-inline">{{ $t('generic.lang_tickets') }}</span>
        </v-col>
        <v-col cols="12" class="pb-8">
          <EventTicketComponent v-for="(ticket, index) in tickets" :ticket="ticket" :key="index"/>
        </v-col>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "../../../../config";
import EventTicketComponent from "./EventTicketComponent";
import EventDetailsLoaderComponent from "./EventDetailsLoaderComponent";
import {Events} from "../../../../plugins/events";

export default {
  name: "EventDetailsComponent",
  components: {EventDetailsLoaderComponent, EventTicketComponent},
  data() {
    return {
      tickets: [],
      loading: false,
    }
  },
  inject:['date','event'],
  computed: {},
  methods: {
    getEventTickets() {
      this.loading = true;
      this.axios.post(ENDPOINTS.POS.EVENTBEE.EVENTS.GET, {
        date: this.$moment.utc(this.date()).unix(),
        eventUUID: this.event().id,
        eventPlanUUID: this.event().plan_id,
      }).then(res => {
        if (res.data.status) {
          this.tickets = res.data.data;
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
        });
      }).finally(() => {
        this.loading = false;
      })

    }
  },
  mounted() {
    this.getEventTickets();
  },
  beforeDestroy(){
    this.tickets = [];
  }
}
</script>

<style scoped>
#tickets {
  margin-left: 20px;
  color: #1976bc;
  font-size: 32px;
  font-weight: bold;
  position: relative;
}

#tickets::after {
  position: absolute;
  content: '';
  bottom: -3px;
  width: 37px;
  height: 3px;
  background-color: #1976bc;
  left: 3px;
}

.bg-light-grey {
  background-color: #f9fafb;
}
</style>