<template>
  <v-card
      v-if="this.item.productImage && this.settings.settings.disableImageDisplay != 1 && this.item.productImage.length > 7"
      class="pa-0" @click="selectItem"
      elevation="1" height="100%" width="100%" :color="backgroundColor" v-ripple="{class: 'primary--text'}">
    <v-img
        cover
        :src="this.imageUrl"
        height="100%"
    >
      <v-card-text class="pa-1 fill-height text-center"
                   :style="{color: (checkColorLightOrDark(item.productImage) === 'light' ? 'black' : 'white'),backdropFilter: 'brightness(0.8)'}">
        <p class="itemName ma-0" :style="[ItemNameStyle]">
          <span v-if="Number(settings.settings.layoutSettings_showItemPLU)>0" style="font-weight: bold;">{{
              item.ean
            }}<br></span>
          {{
            (settings.settings.layoutSettings_itemNameLength > 0) ? itemName.substring(0, settings.settings.layoutSettings_itemNameLength) : itemName
          }}
        </p>
        <p class="itemPrice ma-0" :style="[ItemPriceStyle]">{{ itemPrice | currency }}</p>
      </v-card-text>

      <BookItem v-if="showItemBookingMixin" ref="bookItem" :pos-type="posType"></BookItem>
    </v-img>
  </v-card>

  <v-card v-else class="pa-0" @click="selectItem" elevation="1" height="100%" width="100%" :color="backgroundColor"
          v-ripple="{class: 'primary--text'}">
    <v-card-text class="pa-1 fill-height text-center"
                 :style="{color: (checkColorLightOrDark(backgroundColor) === 'light' ? 'black' : 'white')}">
      <p class="itemName ma-0" :style="[ItemNameStyle]">
        <span v-if="Number(settings.settings.layoutSettings_showItemPLU)" style="font-weight: bold;">{{ item.ean }}<br></span>
        {{
          (settings.settings.layoutSettings_itemNameLength > 0) ? itemName.substring(0, settings.settings.layoutSettings_itemNameLength) : itemName
        }}
      </p>
      <p class="itemPrice mb-0" :style="[ItemPriceStyle]">{{ itemPrice | currency }}</p>
    </v-card-text>


    <BookItem v-if="showItemBookingMixin" ref="bookItem" :pos-type="posType"></BookItem>
  </v-card>

</template>

<style scoped>

.itemPrice {
  font-family: Anton;
  font-weight: normal;
  letter-spacing: 2px;
  position: absolute !important;
  bottom: 2px;
  left: 0;
  width: 100%;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

.itemName {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.v-responsive__sizer {
  padding-bottom: 0% !important;
}
</style>

<script>
import BookItem from "../../mixins/pos/BookItem";
import {checkColorLightOrDark} from "../../plugins/checkColorLightOrDark";
import {mapGetters, mapState} from "vuex";
import {ENDPOINTS} from "../../config";
import { getNetPrice } from "../../plugins/tax";

export default {
  name: "Item",

  components: {
    BookItem
  },

  props: {
    posType: String,
    item: Object,
    gridHeight: Number,
    gridWidth: Number,
    ItemNameStyle: Object,
    ItemPriceStyle: Object,
  },
  data() {
    return {
      imageUrl: "",

      showItemBookingMixin: false
    }
  },
  computed: {
    ...mapState([
      'api',
      'app',
      'pos',
      'settings'
    ]),
    ...mapGetters({
      isDelivery: 'pos/gastro/getDelivery',
    }),
    itemName() {
      if (this.posType === "retail" || this.posType === "wholesaleRetail") {
        if (this.item.isVariation) {
          return this.item.name + " " + this.item.variationSize + " " + this.item.variationColor;
        }
      }
      return this.item.name;
    },
    backgroundColor() {
      if (this.item.backgroundColor === "") {
        return "#FFFFFF";
      }
      return this.item.backgroundColor;
    },
    itemPrice() {
      let price = this.item.sellPrice;
      if(this.posType === "wholesaleRetail" && this.item.taxValue > 0){
        price = getNetPrice(this.item.sellPrice, this.item.taxValue, 2);
      }
      if (this.pos.gastro.isDeliveryService) {
        if (this.isDelivery) {
          if (this.item.deliveryPrice > 0) {
            price = this.item.deliveryPrice;
          }
        } else {
          if (this.item.pickupPrice > 0) {
            price = this.item.pickupPrice;
          }
        }
      }
      return price;
    }
  },
  methods: {
    checkColorLightOrDark,
    selectItem() {
      this.showItemBookingMixin = true;
      // ITS A DIRTY WORKAROUND
      // DO NOT MOUNT THIS MIXIN BEFORE WE USE IT
      this.$nextTick(async () => {
        if(this.app.useAPIForPOSActions) {
          this.axios.post(ENDPOINTS.POS.RETAIL.SCAN, {
            query: this.item.ean
          }).then((res) => {
            if(res.data.success)
              this.$refs.bookItem.selectItemByData(res.data.itemgroup, res.data.item, res.data.overwrite.weight);
          });
        }else {
          await this.$refs['bookItem'].selectItemEAN(this.item.ean);
        }
      });
      //window.eventBus.$emit("booking", false);
    }
  },
  mounted() {
    this.imageUrl = "https://img.3pos.de/unsafe/" + Math.round(this.gridWidth) + "x" + Math.round(this.gridHeight) + "/billsys_" + this.api.auth.posID + "/original/" + this.item.productImage;
  },
  beforeDestroy() {
    this.imageUrl = "";
  },
  destroyed() {
    this.imageUrl = "";
  }
}
</script>
