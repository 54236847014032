<template>
    <div>
        <button @click="openDialog" class="btn-icon-vertical btn-square btn-transition btn btn-outline-warning">
            <i class="btn-icon-wrapper">
                <font-awesome-icon :icon="['fal', 'search']"/>
            </i><span v-if="showButtonText">{{ $t('datatables.lang_search') }}</span>
        </button>

        <ButtonsSearchItemsDialog @closeDialog="showSearchDialog = false" :show-dialog="showSearchDialog" :pos-type="posType"/>
    </div>
</template>

<style scoped>
    .btn-icon-vertical {
        padding: 0 !important;
    }

    @media only screen and (max-height: 850px) {
        .btn-icon-vertical .btn-icon-wrapper {
            font-size: 150% !important;
        }
    }
</style>

<script>
    import ButtonsSearchItemsDialog from "./ButtonsSearchItemsDialog";

    import {library} from '@fortawesome/fontawesome-svg-core'
    import {
        faSearch
    } from '@fortawesome/pro-light-svg-icons';

    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

    library.add(
        faSearch
    );

    export default {
        name: "ButtonsSearchItems",

        components: {
            'font-awesome-icon': FontAwesomeIcon,
            ButtonsSearchItemsDialog
        },

        props: {
            posType: String,
            showButtonText: Boolean
        },

        data() {
            return {
                showSearchDialog: false
            }
        },

        methods: {
            openDialog() {
                this.showSearchDialog = true;
            }
        }
    }
</script>