<template>
  <v-dialog v-model="showDialog" persistent width="500">
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-progress-circular
                  indeterminate
                  color="primary"
                  width="3"
                  size="50"
              />

              <p style="margin-top: 10px; font-weight: bold; margin-bottom: 0;">{{ $t('generic.lang_waitingForEcPayment_adyen') }}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" :disabled="!showProceedButton" @click="cancelDialog()">{{ $t('generic.lang_cancelPayment') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" :disabled="!showProceedButton" @click="cancelPayment()">{{ $t('generic.lang_paymentSuccessful') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {Events} from "@/plugins/events";

export default {
  name: "CashoutAdyenDialog",

  data() {
    return {
      showDialog: false,
      cancelTimeout: null,
      showProceedButton: false,
      cancelSource: null,
      amount: 0
    }
  },

  methods: {
    makePayment(amount) {
      this.amount = amount;

      this.showDialog = true;

      //CREATE CANCEL TIMEOUT
      this.cancelTimeout = setTimeout(() => {
        this.showProceedButton = true;
      }, 10000);

      const CancelToken = this.axios.CancelToken;
      this.cancelSource = CancelToken.source();

      //MAKE REQUEST
      this.axios.post("3rdPartyIntegrations/adyen/create/", {
        amountEC: this.amount,
      }, {
        cancelToken: this.cancelSource.token
      }).then((res) => {
        if (res.status === 200) {
          this.showDialog = false;

          if (res.data.status === "SUCCESS") {
            this.finishPayment(res.data);
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg,
              color: "error"
            });

            this.resetDialog();
            this.$emit("cancel");
          }
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_ecPaymentError') + " (" + err.message + ")",
          color: "error"
        });

        this.resetDialog();
        this.$emit("cancel");
      });
    },
    cancelPayment() {
      this.cancelSource.cancel();

      this.finishPayment({msg: 'skipped'});
    },
    cancelDialog(data) {
      Events.$emit("showSnackbar", {
        message: this.$t('generic.lang_paymentError') + "!",
        color: "error"
      });

      this.resetDialog();
      this.$emit("cancel", data);
    },
    finishPayment(data) {
      Events.$emit("showSnackbar", {
        message: this.$t('generic.lang_paymentMade'),
        color: "success"
      });

      this.resetDialog();
      this.$emit("finished", data);
    },
    resetDialog() {
      this.showDialog = false;
      this.showProceedButton = false;

      clearTimeout(this.cancelTimeout);
    }
  }
}
</script>