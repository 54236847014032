<template>
  <div>
  <v-dialog v-model="showDialog" persistent scrollable max-width="500">
    <v-card :disabled="loading" :loading="loading">
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('erp.lang_save_order') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog" :loading="loading">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="mt-0"/>      
      <v-card-text>
        <v-alert v-if="isCartEmpty" text color="primary">{{ $t('generic.lang_saveOrderTextEmptyCart') }}</v-alert>
        <p v-else class="text-center mb-0" style="font-size: 17px;"> {{ $t('generic.lang_saveOrderText') }}</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" @click="saveOrder(true)" v-if="!isCartEmpty" :loading="loading">{{ $t('erp.lang_saveOrderPrintInterim') }}</v-btn>
      </v-card-actions>
      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog" :loading="loading">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="saveOrder(false)" v-if="!isCartEmpty" :loading="loading">{{ $t('erp.lang_save_order') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <!-- TSE Error Dialog -->
  <v-dialog :value="tseErrorMessage.length > 0" max-width="290" persistent>
    <v-card>
      <v-card-title>{{ $t('generic.lang_tseError') }}</v-card-title>
      <v-card-text class="text-center">{{ tseErrorMessage }}</v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" text @click="closeTSEErrorDialog">{{ $t('generic.lang_understand') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {ENDPOINTS} from "../../../config";
import {Events} from "@/plugins/events";
import {createInvoicePrintingData} from "@/plugins/printing/printInvoice";
import {printDataFromPrinter} from "@/plugins/printing/printerController";

export default {
  name: "ButtonsSaveOrderDialog",

  props: {
    showDialog: Boolean,
    posType: String,
    item: Object
  },

  data() {
    return {
      loading: false,
      orderUUID: null,
      tseErrorMessage: ""
    }
  },

  computed: {
    ...mapState([
      'pos',
      'user',
      'settings',
      'posLayoutTemplates',
      'api'
    ]),
    ...mapGetters({
      gastroTotalPrice: 'pos/gastro/totalPrice',
      retailTotalPrice: 'pos/retail/totalPrice',
      wholesaleRetailTotalPrice: 'pos/wholesaleRetail/totalPrice',
      activeUser: 'multiUser/activeUser',
      fiscalClient: 'tse/fiscalClient'
    }),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
    invoiceItems() {
      let invoiceItems = [];

      if (this.posType === "retail") {
        invoiceItems = this.pos.retail.orderedItems;
      }
      
      if (this.posType === "wholesaleRetail") {
        invoiceItems = this.pos.wholesaleRetail.orderedItems;
      }

      return invoiceItems;
    },
    invoiceItemsToPrint() {
      let items = this._.cloneDeep(this.invoiceItems);

      items.forEach((invoiceItem) => {
        //CHECK IF WE HAVE CHOOSEN TAKE AWAY
        if (this.pos.gastro.takeAway > 0) {
          if (invoiceItem.takeAwayTaxing > 0 && invoiceItem.isMenuItem < 1) {
            invoiceItem.taxValue = this.offSiteTax;
          }
        }

        //DISCOUNT
        if (invoiceItem.discount > 0) {
          invoiceItem.sellPrice = parseFloat((((100 - invoiceItem.discount) / 100) * invoiceItem.sellPrice).toFixed(2));
        }
      });

      return items;
    },
    multiUser() {
      if (this.settings.settings.hasOwnProperty("posGastroLayoutTemplate")) {
        //FIND TEMPLATE
        let templateID = 0;
        templateID = parseInt(this.settings.settings.posGastroLayoutTemplate);

        if (templateID > 0) {
          return this.posLayoutTemplates.templates.some(template => {
            if (templateID === template.id) {
              return (template.multiUser === 1 || template.multiUser === true)
            }
          });
        }
      }

      return false;
    },
    realUser() {
      if (this.multiUser)
        if (this.activeUser.userID !== null)
          return this.activeUser;

      return this.user;
    },
    isCartEmpty() {
      if (this.posType === "retail") {
        if (this.pos.retail.orderedItems.length > 0) {
          return false;
        }
      }
      
      if (this.posType === "wholesaleRetail") {
        if (this.pos.wholesaleRetail.orderedItems.length > 0) {
          return false;
        }
      }

      return true;
    },
    customerID() {
      if (this.posType === "retail") {
        if (this.pos.retail.customer !== null) {
          return this.pos.retail.customer.id;
        }
      }

      if (this.posType === "wholesaleRetail") {
        if (this.pos.wholesaleRetail.customer !== null) {
          return this.pos.wholesaleRetail.customer.id;
        }
      }

      return 0;
    }
  },

  watch: {
    showDialog(val) {
      if (val) {
        this.loading = false;
        this.orderUUID = null;
      }
    }
  },

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    print() {
      this.loading = true;

      let printData = null;

      try {
        //GENERATE INVOICE DATA
        let data = {
          saleID: 0,
          cashierID: this.api.auth.cashierID,
          userID: this.realUser.userID,
          ts: 0,
          moneyGiven: 0,
          savedOrderUUID: this.orderUUID,
          paymentType: 1
        };

        if (this.posType === "gastro") {
          data["tableNo"] = this.pos.gastro.table.name;
        }

        if (this.posType === "retail" || this.posType === "wholesaleRetail") {
          data["tableNo"] = "";
        }

        printData = createInvoicePrintingData(this.invoiceItemsToPrint, data, this.invoicePrinter.options, this.realUser, true);

        //CHECK IF LAST ELEMENT IS CUT
        let lastElememt = printData[(printData.length - 1)];

        if (!lastElememt.hasOwnProperty("cut"))
          printData.push({cut: true});
      } catch (err) {
        this.loading = false;
      }

      printDataFromPrinter(this.invoicePrinter,printData).catch((err)=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+': '+ err.message,
          color: "error"
        });
      }).finally(()=>{
        this.loading = false;
      })
    },
    saveOrder(print = false) {
      if (this.posType === "retail") {
        this.loading = true;

        //SET FISKALY POST DATA:
        let fiskalyData = "";
        if (this.pos.retail.transactionPayload){
           fiskalyData = this.pos.retail.transactionPayload;
        }

        this.axios.post(ENDPOINTS.POS.RETAIL.ORDER.CREATE, {
          items: this.pos.retail.orderedItems.map(item=> {
            return {
              ...item
            }
          }),
          transactionData: fiskalyData,
          customerID: this.customerID,
          orderID: this.pos.retail.savedOrderID,
          totalPrice: this.retailTotalPrice
        }).then((res) => {
          if (res.data.success) {
            //CHECK FISCAL CLIENT
            this.orderUUID = res.data.uuid;
            if (this.fiscalClient !== null && this.fiscalClient.device.type !== "fiskaly") {
              this.finishTransaction(print);
            }else {
              this.finishSavingOrder(print);
              //RESET FISKALY TRANSACTION
              this.$store.commit('pos/retail/setTransactionPayload', {});
            }
          }
        }).catch((e) => {
          this.loading = false;
        })
      } else if (this.posType === "wholesaleRetail") {
        this.loading = true;

        //SET FISKALY POST DATA:
        let fiskalyData = "";
        if (this.pos.wholesaleRetail.transactionPayload){
           fiskalyData = this.pos.wholesaleRetail.transactionPayload;
        }

        this.axios.post(ENDPOINTS.POS.RETAIL.ORDER.CREATE, {
          items: this.pos.wholesaleRetail.orderedItems.map(item=> {
            return {
              ...item
            }
          }),
          transactionData: fiskalyData,
          customerID: this.customerID,
          orderID: this.pos.wholesaleRetail.savedOrderID,
          totalPrice: this.wholesaleRetailTotalPrice
        }).then((res) => {
          if (res.data.success) {
            //CHECK FISCAL CLIENT
            this.orderUUID = res.data.uuid;
            if (this.fiscalClient !== null && this.fiscalClient.device.type !== "fiskaly") {
              this.finishTransaction(print);
            }else {
              this.finishSavingOrder(print);
              //RESET FISKALY TRANSACTION
              this.$store.commit('pos/wholesaleRetail/setTransactionPayload', {});
            }
          }
        }).catch((e) => {
          this.loading = false;
        })
      }
    },

    // TSE FUNCTIONS
    finishTransaction(print = false) {
      let tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

      if (tseDevice === null)
        this.tseErrorMessage = this.$t('generic.lang_unknownError') + "...";

      if (!tseDevice.tseReady && tseDevice.connected)
        this.tseErrorMessage = this.$t('generic.lang_tseIsNotOperational');

      if (!tseDevice.tseReady && !tseDevice.connected)
        this.tseErrorMessage = this.$t('generic.lang_tseIsNotOperational');


      tseDevice.finishOrderbonTransaction(this.fiscalClient.clientID, this.pos.retail.transactionPayload, this.pos.retail.orderedItems);

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "FinishTransaction") {
          if (!payload.result.result === "EXECUTION_OK") {
            this.tseErrorMessage = payload.result.result;
          }
          else {
            this.finishSavingOrder(print);
          }
        }
      });
    },
    closeTSEErrorDialog() {
      this.tseErrorMessage = "";
      this.finishSavingOrder();
    },

    finishSavingOrder(print = false) {

      if(print){
        this.print();
      }

      if(this.posType === "retail")
        this.$store.commit("pos/retail/reset");
      
      if(this.posType === "wholesaleRetail")
        this.$store.commit("pos/wholesaleRetail/reset");

      if(this.posType === "gastro")
        this.$store.commit('pos/gastro/setTransactionPayload', {}); // RESET TSE PAYLOAD

      this.closeDialog();
    }
  }
}
</script>