<template>
  <v-row>
    <v-col v-for="(time, i) in times" :key="i" cols="12" sm="6" md="4" lg="3">
      <div
        role="button"
        class="d-flex time-slot-hover justify-space-between radius-sm pa-2 border"
        @click="toggleSelectedArrivalTime(time)"
        :class="{
          'border-primary': isSelectedArrivalTime(time),
          background: time.available_places === 0
        }"
      >
        <div class="d-flex align-center">
          <!-- <i
                            class="fal fa-2x primary--text d-inline fa-clock"
                          ></i> -->
          <span class="font-weight-bold ml-4 d-inline"
            >{{ time.arrival_time }}
          </span>
          <!-- <span class="mx-2"
                            >( {{ time.available_places }} Plätze)</span
                          > -->
        </div>
        <div class="d-flex border-left flex-column pl-2">
          <font-awesome-icon
              :icon="['fal',
              isSelectedArrivalTime(time)
                ? 'check-circle'
                : 'circle']
            "
              class="fa-2x d-inline"
              :class="isSelectedArrivalTime(time)?'primary--text':'grey--text'"
          ></font-awesome-icon>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {
  faCheckCircle,faCircle
} from "@fortawesome/pro-light-svg-icons"
import {library} from '@fortawesome/fontawesome-svg-core';

library.add(faCheckCircle,faCircle)

export default {
  name: 'ArrivalTimePicker',
  props: {
    times: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    },
    loadingArrivalTimes: {
      type: Boolean,
      default: false
    }
  },
  components:{
    FontAwesomeIcon
  },
  computed: {
    selectedArrivalTime: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    isSelectedArrivalTime() {
      return time => {
        if (this.selectedArrivalTime === null) return false;
        if (time === null) return false;
        if (this.loadingArrivalTimes) return false;
        return time.arrival_time === this.selectedArrivalTime?.arrival_time;
      };
    }
  },
  methods: {
    toggleSelectedArrivalTime(time) {
      if (time.arrival_time === this.selectedArrivalTime?.arrival_time) {
        this.selectedArrivalTime = null;
        return;
      }

      this.selectedArrivalTime = time;
    }
  }
};
</script>

<style scoped>
.time-slot-hover:hover {
  background-color: #f5f5f5;
  user-select: none;
}
</style>
