<template>
  <v-row align="center" class="transparent ma-0 mx-0 border" no-gutters>
    <v-col v-for="i in 9" :key="i" class="text-center border pa-0" cols="4">
      <v-btn :id="'btn_' + i" block class="rounded-0 ma-0 mx-0" :color="$vuetify.theme.dark? '' : 'white'" elevation="0" height="40"
             @click="button(i)">
        <span class="text-muted">
          {{ i }}
        </span>
      </v-btn>
    </v-col>

    <v-col class="text-center border" cols="4">
      <v-btn block class="rounded-0 ma-0 mx-0" color="white"
             elevation="0"
             height="40" text
      >
        <font-awesome-icon
            :icon="['fal', 'comment-alt-dollar']"
            class="text-muted font-size-xlg"
        />
      </v-btn>
    </v-col>

    <v-col class="text-center pa-0 border" cols="4">
      <v-btn block class="rounded-0 ma-0 mx-0" :color="$vuetify.theme.dark? '' : 'white'" elevation="0" height="40" @click="button(0)">
        <span class="text-muted">
          0
        </span>
      </v-btn>
    </v-col>

    <v-col class="text-center border" cols="4">
      <v-btn block class="rounded-0 ma-0 mx-0" color="white" elevation="0" height="40" text
             width="87%" @click="del">
        <font-awesome-icon
            :icon="['fal', 'backspace']"
            class="text-muted font-size-xlg"
        />
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCommentAltDollar, faHeart} from '@fortawesome/pro-light-svg-icons'


library.add(faCommentAltDollar, faHeart)
export default {
  name: "CashoutKeyboard",
  components: {
    'font-awesome-icon': FontAwesomeIcon,
  },
  props: {},
  methods: {
    button(number) {
      this.$emit('change', number)
    },
    del() {
      this.$emit('del')
    },

  }
}
</script>

<style scoped>

</style>