<template>
    <button @click="showDialog = true" class="ma-auto btn-icon-vertical btn text-muted btn-square btn-transition bg-white" style="height: 110px !important;">


        <img class="btn-icon-wrapper ma-auto btn-icon-md icons mb-2"  src="@/assets/images/ibelsa.png" width="90%">

        <ButtonsIbelsaSearchDialog :showDialog="showDialog" @closeDialog="showDialog = false" @roomSelected="roomSelected"></ButtonsIbelsaSearchDialog>
    </button>
</template>

<script>
import ButtonsIbelsaSearchDialog from "@/components/pos/buttons/ButtonsIbelsaSearchDialog";

export default {
    name: "ButtonsIbelsaSearch",

    components: {
        ButtonsIbelsaSearchDialog
    },

    props: {
        posType: String
    },

    data() {
        return {
            showDialog: false
        }
    },

    methods: {
        roomSelected(room) {
            this.showDialog = false;

            this.$emit("roomSelected", room);
        }
    }
}
</script>
