<template>
    <button @click="showDialog = true" class=" btn ma-auto btn-icon-vertical text-muted btn-square btn-transition bg-white" style="height: 110px !important;">
        <img class="btn-icon-wrapper ma-auto btn-icon-md icons mb-2" src="@/assets/images/hs3.png" width="90%">

        <ButtonsHS3SearchDialog :showDialog="showDialog" @closeDialog="showDialog = false" @occupationSelected="occupationSelected" @occupationGuestSelected="occupationGuestSelected"></ButtonsHS3SearchDialog>
    </button>
</template>

<script>
import ButtonsHS3SearchDialog from "@/components/pos/buttons/ButtonsHS3SearchDialog";

export default {
    name: "ButtonsHS3Search",

    components: {
        ButtonsHS3SearchDialog
    },

    props: {
        posType: String
    },

    data() {
        return {
            showDialog: false
        }
    },

    methods: {
        occupationSelected(occupation) {
            this.showDialog = false;

            this.$emit("occupationSelected", occupation);
        },
        occupationGuestSelected(payload) {
            this.showDialog = false;

            this.$emit("occupationGuestSelected", payload);
        }
    }
}
</script>