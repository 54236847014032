import store from "@/store";
import {geol} from "../../main.js";
import {formatCurrency} from "../currencyFormater";

// since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it

if(store.getters['settings/getSettingValue']('geoloc_systemcountry')){
    geol.locale = store.getters['settings/getSettingValue']('geoloc_systemcountry');
}
export function createTicketPrintingData(ticket) {
    let printObj = [];

    //GET SETTINGS
    let settings = store.getters["settings/settings"];

    printObj.push({"align": "center"});
    printObj.push({"printerLogo": "true"});
    printObj.push({"text": settings.system_name});
    printObj.push({"text": settings.systemStreetNo});
    printObj.push({"text": settings.systemZip+" "+settings.systemCity});
    printObj.push({"text": geol.t('erp.lang_TaxID')+": "+settings.taxID});
    printObj.push({"feed": 2});
    printObj.push({"fontSize": 2});
    printObj.push({"text": geol.t('eventbee.lang_ticket')});
    if(ticket.name)
        printObj.push({"text": ticket.name});

    printObj.push({"text": ticket.date});
    printObj.push({"text": ticket.from + ' - ' + ticket.to});
    printObj.push({"fontSize": 1});
    printObj.push({"feed": 2});
    printObj.push({"qrCodeSize": 4});
    printObj.push({"qrCode": ticket.uuid});
    printObj.push({"text": ticket.uuid});
    printObj.push({"feed": 3});
    printObj.push({"cut": "true"});

    return printObj;
}