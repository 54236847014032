<template>
  <div>

    <button v-if="this.$vuetify.breakpoint.smAndDown"
            class="btn-square btn-transition border-0 btn bg-success"
            style="height: 59px !important;width: 59px !important;"
            @click="openDialog">
      <i class="btn-icon-wrapper">
        <font-awesome-icon :icon="['fal', 'comment-alt-lines']" class="text-light" style="font-size: 25px"/>
      </i>
    </button>
    <button v-else class="btn-icon-vertical btn-square  br-tr btn-transition btn btn-outline-alternate"
            @click="openDialog">
      <i class="btn-icon-wrapper">
        <font-awesome-icon :icon="['fal', 'comment-alt-lines']"/>
      </i>
      <span v-if="showButtonText">{{ $t('generic.lang_note') }}</span>
    </button>

    <ButtonsFreetextDialog :item="selectedItem" :pos-type="posType" :show-dialog="showDialog"
                           @closeDialog="showDialog = false"/>
  </div>
</template>

<style scoped>
.btn-icon-vertical {
  padding: 0 !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>

<script>
import ButtonsFreetextDialog from "./ButtonsFreetextDialog";

import {library} from '@fortawesome/fontawesome-svg-core'
import {faCommentAltLines} from '@fortawesome/pro-light-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {mapState} from "vuex";

library.add(
    faCommentAltLines
);

export default {
  name: "ButtonsFreetext",

  components: {
    'font-awesome-icon': FontAwesomeIcon,
    ButtonsFreetextDialog
  },

  props: {
    posType: String,
    showButtonText: Boolean
  },

  data() {
    return {
      showDialog: false
    }
  },

  computed: {
    ...mapState([
      'pos'
    ]),
    selectedItem() {
      if (this.posType === "retail") {
        return this.pos.retail.selectedItem;
      }
      
      if (this.posType === "wholesaleRetail") {
        return this.pos.wholesaleRetail.selectedItem;
      }

      if (this.posType === "gastro") {
        if (this.pos.gastro.selectedOpenItem !== null) {
          return this.pos.gastro.selectedOpenItem;
        } else if (this.pos.gastro.selectedOrderedItem !== null) {
          return this.pos.gastro.selectedOrderedItem;
        }
      }

      return null;
    }
  },

  methods: {
    openDialog() {
      /*if (this.selectedItem === null && this.posType === "retail")
        return;*/

      if (this.selectedItem) {
        if (this.selectedItem.ean.includes("V$") || this.selectedItem.ean.includes("GF$") || this.selectedItem.ean.includes("ZMYLE$") || this.selectedItem.ean.includes("MYWORLD$"))
          return;

        if (this.selectedItem.isVoid)
          return;
      }

      this.showDialog = true;
    }
  }
}
</script>