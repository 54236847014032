var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',_vm._l((_vm.slots),function(timeSlot,i){return _c('v-col',{key:i,attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex time-slot-hover justify-space-between radius-sm pa-2 border",class:{
        'border-primary': _vm.isSelectedTimeSlot(timeSlot),
        background: timeSlot.available_places === 0
      },attrs:{"role":"button"},on:{"click":function($event){return _vm.toggleSelectedTimeSlot(timeSlot)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-bold ml-4 d-inline"},[_vm._v(_vm._s(timeSlot.from)+" - "+_vm._s(timeSlot.to)+" ")]),_c('span',{staticClass:"mx-2"},[_vm._v("( "+_vm._s(timeSlot.available_places)+" "+_vm._s(_vm.$t('generic.lang_places'))+")")])]),_c('div',{staticClass:"d-flex border-left flex-column pl-2"},[_c('font-awesome-icon',{staticClass:"fa-2x d-inline",class:_vm.isSelectedTimeSlot(timeSlot)?'primary--text':'grey--text',attrs:{"icon":['fal',
            _vm.isSelectedTimeSlot(timeSlot)
              ? 'check-circle'
              : 'circle']}})],1)])])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }