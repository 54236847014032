<template>
  <div>
    <v-dialog v-model="showDialog" max-width="500px" scrollable persistent>
      <v-card>
        <v-toolbar :color="this.$vuetify.breakpoint.smAndDown ? '#FF6969' : 'transparent'" dense flat>
          <v-toolbar-title :class="this.$vuetify.breakpoint.smAndDown ? 'text-white' : 'text-black'">
            {{ $t('generic.lang_gastro_tischsplitTisch') }}
          </v-toolbar-title>

          <v-spacer />

          <v-btn :class="this.$vuetify.breakpoint.smAndDown ? 'text-white' : 'text-black'" icon @click="closeDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider class="ma-0" />
        <v-card-text v-if="loading" class="text-center">
          <v-progress-circular color="primary" indeterminate size="40" style="margin-top: 20px;"></v-progress-circular>
        </v-card-text>

        <v-card-text v-else style="max-height: 500px; padding: 0;">
          <v-text-field v-model="searchTable" outlined dense hide-details class="ma-2"
            :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" :label="$t('tablebee.lang_search_table')" />
          <v-expansion-panels>
            <v-expansion-panel v-for="table in filteredTables" :key="table.name" :disabled="isTableLocked(table)">
              <v-expansion-panel-header :class="isTableLocked(table) ? 'error lighten-4' : ''">{{ table.name
              }}
                <span class="grey--text" style="margin-left: 5px;">({{ table.room.name }})</span>
                <span v-if="currentTable === table.name" class="font-weight-bold">{{ $t('generic.lang_currentTable') }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- Show Party Add Dialog -->
                <v-btn :color="$vuetify.breakpoint.smAndDown ? '#FF6969' : 'primary'" style="margin-bottom: 20px;" text
                  @click="openPartyCreateDialog(table)">
                  <v-icon>add</v-icon>
                  {{ $t('generic.lang_create') }}
                </v-btn>

                <!-- No Parties -> CREATE NEW -->
                <v-card v-if="table.parties.length === 0" @click="selectTableParty(table, 'Partei 1')">
                  <v-card-text>Partei 1</v-card-text>
                </v-card>

                <!-- Show all parties -->
                <v-card v-for="party in table.parties" :key="party.name" @click="selectTableParty(table, party.name)"
                  :disabled="isPartyDisabled(table, party)">
                  <v-card-text class="pa-2">{{ party.name }}</v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-card-actions v-if="!loading" :class="this.$vuetify.breakpoint.smAndDown ? 'card-footer pt-1 pb-1' : ''">
          <v-btn color="error" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
        </v-card-actions>
      </v-card>

      <!-- Party Add Dialog -->
      <v-dialog v-model="partyAddDialog" max-width="300" persistent
        @keydown.enter="!newPartyName && newPartyName.toString().length < 1 ? null : createParty()">
        <v-card v-if="selectedTable != null">
          <v-card-title v-if="this.$vuetify.breakpoint.smAndDown"
            class="text-black text-left card-header-tab card-header pt-1 pl-4 pr-1 pb-1" elevation="0">
            <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
              {{ $t('generic.lang_createParty') }}
              ({{ selectedTable.name }})
            </div>
          </v-card-title>
          <v-card-title v-else>{{ $t('generic.lang_createParty') }}
            ({{ selectedTable.name }})
          </v-card-title>
          <v-card-text class="pb-0">
            <v-form v-model="validPartyName">
              <v-text-field v-model="newPartyName" :class="this.$vuetify.breakpoint.smAndDown ? 'mt-5' : ''"
              :dense="this.$vuetify.breakpoint.smAndDown" :data-layout="KEYBOARD.KEYSETS.NORMAL"
              @focus="showTouchKeyboard" :filled="!this.$vuetify.breakpoint.smAndDown" :label="$t('generic.lang_party')"
              :rules="[rules.required, uniquePartyName]" outlined></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions :class="this.$vuetify.breakpoint.smAndDown ? 'card-footer pt-1 pb-1 pl-0 pr-0' : ''">
            <v-btn color="error" text @click="partyAddDialog = false">{{ $t('generic.lang_cancel') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn :class="this.$vuetify.breakpoint.smAndDown ? 'text-white' : 'text-white'"
              :color="this.$vuetify.breakpoint.smAndDown ? '#FF6969' : 'success'"
              :elevation="this.$vuetify.breakpoint.smAndDown ? '0' : '3'" :rounded="this.$vuetify.breakpoint.smAndDown"
              :disabled="!validPartyName"
              :small="this.$vuetify.breakpoint.smAndDown" @click="createParty">
              {{ $t('generic.lang_create') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard" style="z-index: 299 !important;">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
        :layout="touchKeyboard.layout" :options="touchKeyboard.options" class="internalWidthExpanded" />
    </div>
  </div>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { mapGetters, mapState } from "vuex";
import KeyboardMixIns from "@/mixins/KeyboardMixIns";
import validation from "@/mixins/validation/validation";
export default {
  name: "ButtonsTableSplitTablesDialog",

  props: {
    showDialog: Boolean
  },

  mixins: [KeyboardMixIns, validation],

  data() {
    return {
      loading: false,
      tables: [],
      expanded: [],
      partyAddDialog: false,
      selectedTable: null,
      searchTable: null,
      validPartyName: false,
      newPartyName: ""
    }
  },

  computed: {
    ...mapState([
      'pos'
    ]),
    ...mapGetters({
      lockedTables: "pos/gastro/lockedTables",
      currentParty: "pos/gastro/currentParty",
      currentTable: "pos/gastro/tableName",
    }),
    filteredTables() {
      return this.tables.filter(table => {
        if (this.searchTable) {
          return table.name.includes(this.searchTable);
        }

        return table;
      });
    },
    isTableLocked() {
      return (table)=>{
        return this.lockedTables.includes(table.name) && table.name !== this.currentTable
      }
    },
    isPartyDisabled() {
      return (table, party)=>{
        return this.isTableLocked(table) ||  (this.currentTable.toLowerCase() === table.name.toLowerCase() && this.currentParty.name.toLowerCase() === party.name.toLowerCase())
      }
    }
  },
  watch: {
    showDialog(val) {
      if (val) {
        //GET TABLES
        this.getTables();
      }
    },
    partyAddDialog(val) {
      if (!val) {
        this.selectedTable = null;
        this.newPartyName = "";
      }
    }
  },

  methods: {
    uniquePartyName() {
      if (this.newPartyName && Number(this.newPartyName) ) {
          return !this.selectedTable.parties.map(el => el.name.toLowerCase()).includes('partei ' + this.newPartyName) || this.$t('generic.lang_partyAlreadyExists')
        } else {
          return !this.selectedTable.parties.map(el => el.name.toLowerCase()).includes(this.newPartyName?.toLowerCase()) || this.$t('generic.lang_partyAlreadyExists')
        }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    getTables() {
      this.loading = true;

      this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.GET).then((res) => {
        if (res.data.success) {
          this.tables = res.data.tables;
        }
        this.$socket.emit("tableplan.getLockedTables");
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    selectTableParty(table, partyName) {
      this.$emit("tablePartySelected", {
        table: table,
        partyName: partyName
      });
    },
    openPartyCreateDialog(table) {
      this.selectedTable = table;
      this.partyAddDialog = true;
    },
    createParty() {
      this.selectTableParty(this.selectedTable, this.formatPartei(this.newPartyName));

      this.partyAddDialog = false;
    }
  }
}
</script>
