<template>
  <v-dialog v-model="dialog" light
            max-width="90%" :width="($vuetify.breakpoint.smAndDown)?'90%':'50%'"
            class="ma-0 pa-0 rounded-forced"
  >
    <v-card elevation="0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="rounded-xl overflow-hidden pa-1 py-4" >
      <v-container v-if="cashierOptions" class="pa-0 py-1">
        <v-row v-if="parseInt(cashierOptions.inHouseTakeAwayRequired)===1 && posType==='gastro'" justify="center" align="center" no-gutters >
          <v-col cols="6" sm="6" md="6" lg="6">
            <v-card class="pa-3 rounded-pill overflow-auto" align="center" color="transparent" elevation="0" @click="selectInHouse">
                                <span class="mx-auto">
                                    <font-awesome-icon
                                        :size="($vuetify.breakpoint.smAndDown)?'4x':'6x'" style="color: #d92550;"
                                        :icon="['fal','home']"/>
                                </span>
              <v-card-text>
                <h4>{{$t('generic.lang_inHouseSale')}}</h4>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" sm="6" md="6" lg="6">
            <v-card class="pa-3 rounded-pill overflow-auto" align="center" color="transparent" elevation="0"  @click="selectOutHouse">
                                <span class="mx-auto">
                                    <font-awesome-icon
                                        :size="($vuetify.breakpoint.smAndDown)?'4x':'6x'" style="color: #3f6ad8;"
                                        :icon="['fal','burger-soda']"/>
                                </span>
              <v-card-text>
                <h4>{{$t('generic.lang_offSiteSale')}}</h4>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-divider v-show="parseInt(cashierOptions.inHouseTakeAwayRequired)===1 && parseInt(cashierOptions.askForAdditionalStatistics)===1 && posType==='gastro'"/>
        <template v-if="parseInt(cashierOptions.askForAdditionalStatistics)===1">
          <v-row v-if="cashierOptions.additional_statistics&&cashierOptions.additional_statistics.length>0" no-gutters >
            <v-col v-for="addits in cashierOptions.additional_statistics" :key="addits.uuid" cols="6" sm="6" md="4" lg="4">
              <v-card @click="selectAddits(addits.uuid)" elevation="0" tile class="btn-icon-vertical w-100 text-center h-100" :color="(!selectedAddits.includes(addits.uuid))?'white':addits.color"
                      :style="{borderColor:addits.color+' !important',borderStyle:'solid',
                        color:(selectedAddits.includes(addits.uuid))?'white':addits.color,borderWidth:'2px !important'}">
                <i class="btn-icon-wrapper" >
                  <font-awesome-icon :style="{fontSize: '50px',color:(selectedAddits.includes(addits.uuid))?'white':addits.color}" :icon="['fal',addits.icon]"/>
                </i>
                {{addits.name}}
              </v-card>
            </v-col>
            <v-col  v-if="!cashierOptions.inHouseTakeAwayRequired||posType==='retail'|| posType==='wholesaleRetail'" cols="12" align="right">
              <v-btn  @click="selectectAdditionals" color="primary" >{{$t('generic.lang_next')}}<v-icon>mdi-chevron-right</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-row v-else no-gutters >
            <v-col cols="12">
              <v-card elevation="0" tile class="btn-icon-vertical w-100 text-center h-100" color="white">
                <i class="btn-icon-wrapper" >
                  <font-awesome-icon :style="{fontSize: '50px',color:'var(--warning)'}" :icon="['fal','info-circle']"/>
                </i>
                {{$t('generic.lang_noAdditionalStatsAssigned')}}
              </v-card>
            </v-col>
            <v-col cols="12" align="right">
              <v-btn  @click="selectectAdditionals" color="primary" >{{$t('generic.lang_next')}}<v-icon>mdi-chevron-right</v-icon></v-btn>
            </v-col>
          </v-row>
        </template>


      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {library} from "@fortawesome/fontawesome-svg-core"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {fal} from "@fortawesome/pro-light-svg-icons"
import {mapState} from "vuex";

library.add(
    fal
)
export default {
  name: "InHouseTakeAwayChooser",
  components:{
    'font-awesome-icon':FontAwesomeIcon,
  },
  props:{
    posType:{
      default:'gastro',
      type:String,
    },
    showDialog:{
      default:false,
      type:Boolean,
    },
  },
  data(){
    return {
      dialog:this.showDialog,
      selectedAddits:[],
    }
  },
  computed:{
    ...mapState({
      current: 'api',
      cashiers: 'cashierIDs',
    }),
    cashierOptions(){
      let currentCashierOptions = this.cashiers.cashiers.filter(cashier => cashier.id === this.current.auth.cashierID);
      return (currentCashierOptions && currentCashierOptions.length)?currentCashierOptions[0]:null;
    }
  },
  methods:{
    selectInHouse(){
      this.$emit('inHouseTakeAway','in');
      this.selectectAdditionals();
      this.close();
    },
    selectOutHouse(){
      this.$emit('inHouseTakeAway','out');
      this.selectectAdditionals();
      this.close();
    },
    selectectAdditionals(){
      this.$emit('selectAdditionals',this.selectedAddits);
      this.selectedAddits=[];
      this.close();
    },
    selectAddits(uuid){
        if(this.selectedAddits.includes(uuid)){
          let newAddits=[];
          this.selectedAddits.forEach(additUUID=>{
            if(additUUID!==uuid){
              newAddits.push(additUUID)
            }
          });
          this.selectedAddits=newAddits;
        }else{
          this.selectedAddits.push(uuid);
        }
    },
    show(){
      this.dialog=true;
    },
    close(){
      this.dialog=false;
    }
  }
}
</script>

<style scoped>
.rounded-forced{
  border-radius: 9999px !important;
}

</style>
