<template>
  <button
      key="go-pickup"
      :class='["btn-icon-vertical" ,"btn-square"  ,"br-tr" ,"btn-transition" ,"btn" , "btn-block" ,"btn-primary"]'
      @click="goPickup()">
    <i class="btn-icon-wrapper" style="display:block !important;line-height: 0.6">

      <font-awesome-icon style="font-size: 26px;" :icon="['fal','person-carry']"/>
    </i>
  </button>
</template>

<script>
import {mapState} from "vuex";
import randomString from "randomstring";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "ButtonsGoPickup",
  props: {
    posType: {
      type: String,
      default: 'gastro',
      validator: (value) => value === 'gastro' || value === 'retail'
    }
  },
  components: {
    'font-awesome-icon': FontAwesomeIcon,
  },
  computed: {
    ...mapState([
      'pos'
    ])
  },
  methods: {
    redirectToPick() {
      if (this.pos.gastro.table && Number(this.pos.gastro.table.name) !== 0) {
        this.$socket.emit("tableplan.leaveTable", this.pos.gastro.table.name);
      }
      let query = {useDeliveryService: ''}
      this.$store.commit("pos/gastro/changeParty", {
        posType: 'gastro',
        party: {
          name: "Partei 1"
        }
      });
      this.$store.dispatch("pos/gastro/setTable", {
        table: {
          name: "delivery_" + randomString.generate(15),
          parties: [{name: 'Partei 1'}],
        }
      })

      this.$store.commit('pos/gastro/setDeliveryInfo', null);
      this.$store.dispatch("pos/gastro/setDelivery", false);
      this.$store.dispatch("pos/gastro/setTakeAway", 1);
      this.$store.dispatch("pos/gastro/setDeliveryService", true)
      this.$router.replace({name: 'pos.gastro', query: query})
    },
    goPickup() {
      if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
        if (this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
          this.$swal({
            title: this.$t('generic.lang_warning'),
            text: this.$t('generic.lang_warning_unorderedItems'),
            icon: "warning",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            cancelButtonText:this.$t('generic.lang_cancel'),
            confirmButtonText: this.$t('generic.lang_delete'),
            preConfirm: () => {
              this.redirectToPick();
            },
            allowOutsideClick: () => !this.$swal.isLoading,
          });
        } else {
          this.redirectToPick();
        }
      } else {
        this.redirectToPick();
      }
    },
  }
}
</script>

<style scoped>

</style>
