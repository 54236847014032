<template>
  <v-card class="pa-0" height="100%" width="100%" elevation="1" :color="backgroundColor" v-ripple="{class: 'primary--text'}">
    <v-img v-if="this.itemgroup.itemgroupImage && this.itemgroup.itemgroupImage.length > 7 && this.settings.settings.disableImageDisplay != 1" cover :src="this.imageUrl" height="100%">
      <v-card-text class="pa-1 text-center fill-height" :style="{color: (checkColorLightOrDark(backgroundColor) === 'light' ? 'black' : 'white'), backdropFilter: 'brightness(0.8)'}" :id="itemgroup.id">
        <p class="ma-0">
          <font-awesome-icon :style="getItemGroupIconStyle" v-if="isFaIcon" class="itemgroupIcon" :icon="['fal', itemgroup.faIcon]"/>
        </p>
        <p class="itemgroupName ma-0" :style="getItemGroupStyle">{{ (getItemGroupNameLength>0)? itemgroup.name.substring(0,getItemGroupNameLength):itemgroup.name}}</p>
      </v-card-text>
    </v-img>

    <v-card-text v-else class="pa-1 text-center fill-height" :style="{color: (checkColorLightOrDark(backgroundColor) === 'light' ? 'black' : 'white')}" :id="itemgroup.id">
      <p class="ma-0">
        <font-awesome-icon :style="getItemGroupIconStyle" v-if="isFaIcon" class="itemgroupIcon" :icon="['fal', itemgroup.faIcon]"/>
      </p>
      <p class="itemgroupName ma-0" :style="getItemGroupStyle">{{ (getItemGroupNameLength>0)? itemgroup.name.substring(0,getItemGroupNameLength):itemgroup.name}}</p>
    </v-card-text>
  </v-card>
</template>

<script>

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core';
import {mapState} from 'vuex'


import {
  faProjector,
  faTablet,
  faIceCream,
  faGiftCard,
  faHotdog,
  faCocktail,
  faMeat,
  faGifts,
  faHamburger,
  faCheeseburger,
  faFrenchFries,
  faPizzaSlice,
  faShishKebab,
  faFish,
  faCroissant,
  faSalad,
  faTaco,
  faSandwich,
  faSoup,
  faPersonCarry,
  faStopwatch,
  faBiking,
  faUserHeadset,
  faMapMarkerAlt,
  faHatChef,
  faKnifeKitchen,
  faWindowClose,
  faCheck,
  faFlagCheckered,
  faBinoculars,
  faPizza,

  faBeer,

  faMugHot,

  faGlassCheers,

  faGlass,

  faUtensils,

  faFishCooked,

  faTurkey,

  faFlowerDaffodil,

  faFlowerTulip,

  faDumbbell,

  faMouse,

  faPlug,

  faWebcam,

  faUsbDrive,

  faWatchCalculator,

  faSdCard,

  faMobile,

  faGameConsoleHandheld,

  faGamepadAlt,

  faFlashlight,

  faBaseball,

  faToiletPaper,

  faShovel,


  faBackpack,

  faAxe,

  faHeadphones,

  faMp3Player,

  faDroneAlt,

  faCameraPolaroid,

  faAlbum,
  faKeyboard,
    faCompactDisc,

} from '@fortawesome/pro-light-svg-icons';

library.add(
    faCompactDisc,
    faHotdog,
    faCocktail,
    faMeat,
    faGifts,
    faHamburger,
    faCheeseburger,
    faFrenchFries,
    faPizzaSlice,
    faShishKebab,
    faFish,
    faSalad,
    faTaco,
    faSandwich,
    faSoup,
    faPersonCarry,
    faStopwatch,
    faBiking,
    faUserHeadset,
    faMapMarkerAlt,
    faHatChef,
    faKnifeKitchen,
    faWindowClose,
    faCheck,
    faFlagCheckered,
    faProjector,
    faBinoculars,
    faPizza,
    faBeer,
    faMugHot,
    faGlassCheers,
    faGlass,
    faUtensils,
    faCroissant,
    faFishCooked,
    faTurkey,
    faIceCream,
    faFlowerDaffodil,
    faFlowerTulip,
    faDumbbell,
    faMouse,
    faPlug,
    faWebcam,
    faTablet,
    faUsbDrive,
    faWatchCalculator,
    faSdCard,
    faMobile,
    faIceCream,
    faGameConsoleHandheld,
    faGamepadAlt,
    faFlashlight,
    faBaseball,
    faShishKebab,
    faToiletPaper,
    faShovel,
    faFlashlight,
    faBinoculars,
    faBackpack,
    faAxe,
    faHeadphones,
    faMp3Player,
    faDroneAlt,
    faCameraPolaroid,
    faAlbum,
    faKeyboard,
    faTablet,
    faIceCream,
    faGiftCard,
);
import {checkColorLightOrDark} from "../../plugins/checkColorLightOrDark";

export default {
  name: "Itemgroup",

  components: {
    'font-awesome-icon': FontAwesomeIcon,
  },

  props: {
    itemgroup: Object,
    gridHeight: Number,
    gridWidth: Number,
  },

  data(){
    return {
      imageUrl: "",
      height: 0,
      defaultFontSize:12,
      defaultLength:11,
    }
  },
  watch:{
    height(val){
      this.height=val
    }
  },
  computed: {
    ...mapState([
      'api',
      'settings'
    ]),
    backgroundColor() {
      if (this.itemgroup.backgroundColor === "") {
        return "#FFFFFF";
      }
      return this.itemgroup.backgroundColor;
    },
    isFaIcon() {
      return this.itemgroup.faIcon !== "" && this.itemgroup.faIcon !== null ;
    },
    getItemGroupStyle(){
      let size=(Number(this.settings.settings.layoutSettings_fontSize>0))?Number(this.settings.settings.layoutSettings_fontSize):this.defaultFontSize;
      let iconHeight=((size*2)+(size*2*0.2))-4
      let height=(this.isFaIcon)?(this.height-iconHeight)+'px':'100%';
      let style={
        fontSize:size+'px !important',
        lineHeight:(size+size*0.2)+'px !important',
        height:height,
      };
      return style;
    },
    getItemGroupIconStyle(){
      let size=((Number(this.settings.settings.layoutSettings_fontSize>0))?Number(this.settings.settings.layoutSettings_fontSize):this.defaultFontSize)*2;
      let style={
        fontSize:size+'px !important',
        lineHeight:(size+size*0.2)+'px !important',
      };
      return style;
    },
    getItemGroupNameLength(){
      if(Number(this.settings.settings.layoutSettings_itemNameLength)===0){
        return 0
      }else if(Number(this.settings.settings.layoutSettings_itemNameLength)<this.defaultLength){
        return this.defaultLength;
      }
      return Number(this.settings.settings.layoutSettings_itemNameLength);
    }
  },
  mounted(){
    this.$nextTick(this.getBodyHeight);
    this.imageUrl = "https://img.3pos.de/unsafe/" + Math.round(this.gridWidth) + "x" + Math.round(this.gridHeight) + "/billsys_" + this.api.auth.posID + "/original/" + this.itemgroup.itemgroupImage;
  },
  beforeDestroy() {
    this.imageUrl = "";
  },
  destroyed() {
    this.imageUrl = "";
  },
  methods: {
    checkColorLightOrDark,
    getBodyHeight(){
      this.height=this.$el.clientHeight-8;
    }
  }
}
</script>

<style scoped>

.itemgroupIcon {
  font-size: 30px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}
.itemgroupName {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*
@media (max-width: 1024px) {
  .itemgroupName {
    font-size: 14px;
    height: 45px;
  }
}

@media (min-width: 1025px) {
  .itemgroupName {
    font-size: 19px;
    height: 45px;
  }
}*/
</style>
