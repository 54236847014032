<template>
  <div v-if="posType !== 'retail'" class="mx-auto ma-auto">
    <button v-if="$vuetify.breakpoint.smAndDown && enabled" id="send_btn" :disabled="loading || this.is_booking"
            class="btn-square success--text btn mx-auto active"

            style="height: 55px !important;"
            @click="orderItems">
      <span v-if="!loading">
              <font-awesome-icon
                  :icon="['fal', 'paper-plane']"
                  style="font-size: 30px;  "
                  :class="[this.is_booking? 'grey--text' : '']"
              />
            </span>

      <span v-else>
                <v-progress-circular :color="this.loader_color || 'primary'" indeterminate/>
            </span>
    </button>


    <button v-if="$vuetify.breakpoint.mdAndUp && enabled" :disabled="loading || this.is_booking"
            class="btn-icon-vertical btn-square btn-transition btn btn-outline-success active"
            style="text-transform: uppercase;height: 75px;width:100%;"
            @click="orderItems">
            <span v-if="!loading">
                <font-awesome-icon icon="paper-plane" size="2x" :class="[this.is_booking? 'grey--text' : '']"/>
                <br>
            </span>
      <span v-else>
          <v-progress-circular color="primary" indeterminate/>
      </span>
    </button>

    <PrintOrderbon ref="printOrderBon" @reprintFinished="orderbonReprintFinished"
                   @cancelReprint="orderbonCancelReprint"></PrintOrderbon>

    <!-- TSE Error Dialog -->
    <v-dialog :value="tseErrorMessage.length > 0" max-width="290" persistent>
      <v-card>
        <v-card-title>{{ $t('generic.lang_tseError') }}</v-card-title>
        <v-card-text class="text-center">{{ tseErrorMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" text @click="tseErrorMessage = ''">{{ $t('generic.lang_understand') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core'
import {faPaperPlane, faReceipt} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {mapGetters, mapState} from "vuex";
import {Events} from "@/plugins/events";
import PrintOrderbon from "@/mixins/pos/PrintOrderbon";

library.add(
    faReceipt,
    faPaperPlane
);

export default {
  name: "FooterButtonOrder",

  components: {
    PrintOrderbon,
    'font-awesome-icon': FontAwesomeIcon
  },

  props: {
    posType: String,
    enabled: Boolean,
    loader_color: String
  },

  data() {
    return {
      loading: false,
      dialogMessage: "",
      tseErrorMessage: "",
      is_booking: false
    }
  },

  computed: {
    ...mapState([
      'api',
      'pos'
    ]),
    ...mapGetters({
      openItems: 'pos/gastro/openItems',
      orderedItems: 'pos/gastro/orderedItems',
      fiscalClient: 'tse/fiscalClient'
    })
  },

  methods: {
    orderItems() {
      this.loading = true;
      Events.$emit('ordering',true);
      // COLLECT ALL OPEN ITEMS
      let openItems = [];

      for (let party in this.pos.gastro.openItems) {
        if (!this.pos.gastro.openItems.hasOwnProperty(party))
          continue;

        this.pos.gastro.openItems[party].forEach((openItem) => {
          openItems.push(openItem);
        });
      }

      //CALL FUNCTION IN PrintOrderBon.vue Mixin
      this.$refs.printOrderBon.printOrderBons().then(() => {
        //CHECK FISCAL CLIENT
        //CHECK IF WE HAVE OPENITEMS
        if (this.fiscalClient !== null && this.fiscalClient.device.type !== "fiskaly" && openItems.length > 0)
          this.startTransaction(openItems);
        else {
          this.orderingFinish();
        }
      }).catch((err) => {
      }).finally(() => {
        Events.$emit('ordering',false);
        this.loading = false
      });
    },
    startTransaction(openItems) {
      let tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

      if (tseDevice === null)
        this.tseErrorMessage = this.$t('generic.lang_unknownError') + "...";

      if (!tseDevice.tseReady && tseDevice.connected)
        this.tseErrorMessage = this.$t('generic.lang_tseIsNotOperational');

      // SINGLE CASHIER MODE
      tseDevice.GFE_StartTransaction(this.fiscalClient.clientID);

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "StartTransaction") {
          if (payload.result.result === "EXECUTION_OK") {
            this.finishTransaction(tseDevice, payload.result.output, openItems);
          } else {
            this.orderingFinish();

            this.tseErrorMessage = payload.result.result;
          }
        }
      });
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$emit('redirect', 'tables');
      }
    },
    finishTransaction(tseDevice, transaction, openItems) {
      tseDevice.finishOrderbonTransaction(this.fiscalClient.clientID, transaction, openItems);

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "FinishTransaction") {
          if (!payload.result.result === "EXECUTION_OK") {
            this.tseErrorMessage = payload.result.result;
          }
        }

        this.orderingFinish();
      });
    },
    orderingFinish() {
      if (this.$store.getters["permissions/checkModule"](50)) {
        this.$router.replace({
          name: 'pos.rfid'
        });
        return;
      }

      if (this.$vuetify.breakpoint.smAndDown) {
        this.$emit('redirect', 'tables');
      }
      Events.$emit('ordering',false);
      this.loading = false;
    },
    orderbonReprintFinished() {
      if (this.$vuetify.breakpoint.smAndDown)
        this.$emit('redirect', 'tables');
    },
    orderbonCancelReprint() {
      if (this.$vuetify.breakpoint.smAndDown)
        this.$emit('redirect', 'tables');
    }
  },
  mounted() {
    window.eventBus.$on('booking', (status) => {
      this.is_booking = status;
      this.$forceUpdate();
    });

  },
  beforeDestroy() {
    window.eventBus.$off('booking');
  }
}
</script>

<style>

#send_btn {
  color: white;
}

#send_btn:hover, #send_btn:active {
  outline: none !important;
  color: #1F9BCF !important;
}
</style>
