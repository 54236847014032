<template>
  <v-row class="no-gutters row">
    <v-col cols="4" align-self="center" align="center" v-for="(currency, index) in currency[currencyIMG].muenzen" :key="index" style="padding: 4px;">
      <v-img :aria-disabled="disabled" v-if="loadingSchein !== currency.value" ripple @click="selectNote(currency)" width="40px" contain
             :src="currency.image"/>

      <div v-else style="width: 65px;">
        <v-progress-circular style="margin-left: 18px;" indeterminate color="primary" width="2"></v-progress-circular>
      </div>
    </v-col>
    <v-col cols="4" align-self="center" align="center" v-for="(currency, index) in currency[currencyIMG].scheine" :key="index+'-paper'" style="padding: 4px;">
      <v-img :aria-disabled="disabled" v-if="loadingSchein !== currency.value" ripple @click="selectNote(currency)" width="65px" contain
             :src="currency.image"/>

      <div v-else style="width: 65px;">
        <v-progress-circular style="margin-left: 18px;" indeterminate color="primary" width="2"></v-progress-circular>
      </div>
    </v-col>

    <CashoutDialog v-if="paymentActive" @closeDialog="paymentActive = false" :pos-type="posType"
                   ref="cashoutDialog"></CashoutDialog>

    <v-dialog :value="errorDialogMessage.length > 0" persistent max-width="300">
      <v-card>
        <v-card-title>Info</v-card-title>
        <v-card-text class="text-center">{{ errorDialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="errorDialogMessage = ''">{{ $t('generic.lang_understand') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <CheckStockCount ref="checkStockCount" :pos-type="posType"></CheckStockCount>
  </v-row>
</template>

<script>
import CashoutDialog from "../cashout/CashoutDialog";
import {mapState} from "vuex";
import CheckStockCount from "@/components/pos/cashout/CheckStockCount";

export default {
  name: "ButtonsEuroNotes",

  props: {
    posType: String,
    disabled: Boolean,
  },

  components: {CheckStockCount, CashoutDialog},

  data() {
    return {
      paymentActive: false,
      errorDialogMessage: "",
      loadingSchein: 0,
      currency:{
        euro:{
          scheine: [
            {
              value: 5,
              image: require("@/assets/images/currency/euro/5.png")
            },
            {
              value: 10,
              image: require("@/assets/images/currency/euro/10.png")
            },
            {
              value: 20,
              image: require("@/assets/images/currency/euro/20.png")
            }
          ]
        },
        mad: {
          muenzen: [
            {
              value: 5,
              image: require("@/assets/images/currency/dirham/5.png"),
            },
            {
              value: 10,
              image: require("@/assets/images/currency/dirham/10.png"),
            },
          ],
          scheine: [
            {
              value: 20,
              image: require("@/assets/images/currency/dirham/20.png"),
            },
          ]
        }
      }
    }
  },

  computed: {
    ...mapState({
      pos: 'pos'
    }),
    currencyIMG(){
      return this.$store.getters['settings/getSettingValue']('geoloc_systemcountry') === "ma"?"mad":"euro";
    },
    isPayAllowed() {
      if(this.disabled){
        return false;
      }

      if (this.posType === "retail") {
        if (this.pos.retail.orderedItems.length > 0) {
          return true;
        }
      }
      
      if (this.posType === "wholesaleRetail") {
        if (this.pos.wholesaleRetail.orderedItems.length > 0) {
          return true;
        }
      }

      if (this.posType === "gastro") {
        if (this.pos.gastro.orderedItems.length > 0) {
          return true;
        }

        if (this.pos.gastro.table !== null) {
          if (this.pos.gastro.table.name === 0) {
            return true;
          }
        }
      }

      return false;
    },
  },

  methods: {
    async selectNote(note) {
      if (!this.isPayAllowed) {
        this.errorDialogMessage = this.$t('generic.lang_nothingHasBeenSoldYet');

        return;
      }

      //CHECK STOCK COUNT
      try {
        this.loadingSchein = note.value;

        await this.$refs.checkStockCount.checkSaleStock();
      } catch (err) {
        //STOCK NOT SUFFICIENT
        return;
      } finally {
        this.loadingSchein = 0;
      }

      this.paymentActive = true;

      this.$nextTick(() => {
        this.$refs.cashoutDialog.giveMoneyAndPay(note.value);
      });
    }
  }
}
</script>