<template>
  <v-dialog
    v-model="showDialog"
    persistent
    scrollable
    max-width="400"
  >
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title
          ><img
            class="ma-auto"
            height="40"
            src="@/assets/images/pos-buttons/kiel-gutschein.svg"
        /></v-toolbar-title>
        <v-spacer />

        <v-btn
          icon
          @click="closeDialog"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0" />

      <!-- body -->
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-if="this.isBooked"
              cols="12"
            >
              <v-alert
                text
                dense
                type="error"
              >
                {{ this.$t('generic.lang_voucherUsed') }}
              </v-alert>
            </v-col>
            <v-col
              v-else-if="!this.isAnyItemBooked"
              cols="12"
            >
              <v-alert
                text
                dense
                type="warning"
              >
                {{ this.$t('generic.lang_noItemBooked') }}
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-text-field
                @focus="showTouchKeyboard"
                autofocus
                @keypress.enter="getVoucherDetails"
                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                v-if="giftCardID === undefined"
                v-model="scannedGiftCardID"
                outlined
                :label="$t('erp.lang_GiftCardNo')"
              ></v-text-field>

              <!--              <v-text-field prepend-icon="euro" v-model="voucherValue" outlined filled
                                          :label="$t('erp.lang_GiftCardValue')"></v-text-field>

                            <NumpadOnly ref="numpad" @updateValue="numpadUpdateValue" @clickCustomButton="numpadClickedCustomButton"
                                        @resetField="numpadResetField" :custom-buttons="customButtons"></NumpadOnly>-->
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <span
                v-if="this.availableBalance !== null"
                class="availableBalance"
                >{{ this.voucherBalance | currency }}</span
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="card-footer">
        <v-btn
          color="blue darken-1"
          text
          @click="closeDialog"
          >{{ $t('generic.lang_cancel') }}</v-btn
        >
        <v-spacer></v-spacer>
        <!--        <v-btn depressed :loading="this.loading" color="success" @click="activate"
                       :disabled="!canActivateVoucher" v-if="canActivateVoucher">{{ $t('activate') }}
                </v-btn>-->

        <v-btn
          depressed
          :loading="this.loading"
          color="primary"
          @click="redeem"
          :disabled="!canRedeemVoucher"
          >{{ $t('generic.lang_redeem') }}
        </v-btn>
      </v-card-actions>

      <div
        id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard"
        style="z-index: 299 !important"
      >
        <vue-touch-keyboard
          id="onScreenKeyboard"
          class="internalWidthExpanded"
          :options="touchKeyboard.options"
          :defaultKeySet="touchKeyboard.keySet"
          v-if="touchKeyboard.visible"
          :layout="touchKeyboard.layout"
          :cancel="touchKeyboard"
          :accept="hideTouchKeyboard"
          :input="touchKeyboard.input"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { ENDPOINTS } from '@/config';
import NumpadOnly from '../NumpadOnly';
import { mapState } from 'vuex';
import { Events } from '@/plugins/events';
import { roundNumber } from '@/plugins/round';
import KeyboardMixIns from "../../../mixins/KeyboardMixIns";

export default {
  name: 'ButtonsZmyleDialog',

  components: {
    NumpadOnly,
  },
  mixins: [KeyboardMixIns],
  props: {
    showDialog: Boolean,
    posType: String,
    giftCardID: String,
  },

  data() {
    return {
      loading: false,
      authorizedActions: null,
      expectedAction: null,
      availableBalance: null,
      voucherValue: 0,
      scannedGiftCardID: '',
      returnedGiftCardID: '',
    };
  },
  computed: {
    ...mapState(['pos']),
    voucherBalance() {
      return roundNumber(this.availableBalance / 100, 2);
    },
    /**
     * check if the voucher is already booked
     * @return {boolean}
     */
    isBooked() {
      let items = [];

      if (this.posType === 'gastro') {
        let openGastroItems =
            this.pos.gastro.openItems[this.pos.gastro?.party?.name],
          orderedGastroItems =
            this.pos.gastro.orderedItems[this.pos.gastro?.party?.name];
        if (Array.isArray(openGastroItems)) items = [...openGastroItems];

        if (Array.isArray(orderedGastroItems))
          items = items.concat(orderedGastroItems);
      } else if (this.posType === 'retail') {
        let orderedRetailItems = this.pos.retail.orderedItems;

        if (Array.isArray(orderedRetailItems))
          items = items.concat(orderedRetailItems);
      } else if (this.posType === 'wholesaleRetail') {
        let orderedRetailItems = this.pos.wholesaleRetail.orderedItems;

        if (Array.isArray(orderedRetailItems))
          items = items.concat(orderedRetailItems);
      }

      if (Array.isArray(items)) {
        let search = items.find((elt) => {
          if (elt)
            return (
              elt.ean === 'ZMYLE$' + this.returnedGiftCardID &&
              elt.isVoid !== true
            );
          return false;
        });
        return search !== undefined && search !== null;
      } else return true;
    },
    isAlreadyBookedAnyVoucher() {
      let items = [];

      if (this.posType === 'gastro') {
        let openGastroItems =
            this.pos.gastro.openItems[this.pos.gastro?.party?.name],
          orderedGastroItems =
            this.pos.gastro.orderedItems[this.pos.gastro?.party?.name];
        if (Array.isArray(openGastroItems)) items = [...openGastroItems];

        if (Array.isArray(orderedGastroItems))
          items = items.concat(orderedGastroItems);
      } else if (this.posType === 'retail') {
        let orderedRetailItems = this.pos.retail.orderedItems;

        if (Array.isArray(orderedRetailItems))
          items = items.concat(orderedRetailItems);
      } else if (this.posType === 'wholesaleRetail') {
        let orderedRetailItems = this.pos.wholesaleRetail.orderedItems;

        if (Array.isArray(orderedRetailItems))
          items = items.concat(orderedRetailItems);
      }

      if (Array.isArray(items)) {
        let search = items.find((elt) => {
          if (elt) return elt.ean.includes('ZMYLE$') && elt.isVoid !== true;
          return false;
        });
        return search !== undefined && search !== null;
      } else return true;
    },
    isAnyItemBooked() {
      let items = [];

      if (this.posType === 'gastro') {
        let openGastroItems =
            this.pos.gastro.openItems[this.pos.gastro?.party?.name],
          orderedGastroItems =
            this.pos.gastro.orderedItems[this.pos.gastro?.party?.name];
        if (Array.isArray(openGastroItems)) items = [...openGastroItems];

        if (Array.isArray(orderedGastroItems))
          items = items.concat(orderedGastroItems);
      } else if (this.posType === 'retail') {
        let orderedRetailItems = this.pos.retail.orderedItems;

        if (Array.isArray(orderedRetailItems))
          items = items.concat(orderedRetailItems);
      } else if (this.posType === 'wholesaleRetail') {
        let orderedRetailItems = this.pos.wholesaleRetail.orderedItems;

        if (Array.isArray(orderedRetailItems))
          items = items.concat(orderedRetailItems);
      }

      return items.length > 0;
    },
    canActivateVoucher() {
      return (
        Array.isArray(this.authorizedActions) &&
        this.authorizedActions.includes('activate') &&
        this.expectedAction === 'activate'
      );
    },
    canRedeemVoucher() {
      return (
        Array.isArray(this.authorizedActions) &&
        this.authorizedActions.includes('redeem') &&
        this.expectedAction === 'redeem' &&
        this.isAnyItemBooked &&
        !this.isAlreadyBookedAnyVoucher &&
        !this.isBooked
      );
    },
    computedGiftCardID() {
      if (this.giftCardID === undefined) {
        if (this.scannedGiftCardID.length > 0) {
          if (this.scannedGiftCardID.includes('GF$')) {
            let giftCardID = this.scannedGiftCardID.split('GF$');
            return giftCardID[1];
          } else {
            return this.scannedGiftCardID;
          }
        }
      } else {
        return this.giftCardID;
      }

      return '';
    },
  },

  watch: {
    scannedGiftCardID(val) {
      if (val === '' || val === null) {
        this.authorizedActions = null;
        this.expectedAction = null;
        this.availableBalance = null;
      }
    },
    showDialog(val) {
      if (!val) {
        this.voucherValue = '';
        this.scannedGiftCardID = '';
        this.authorizedActions = null;
        this.expectedAction = null;
        this.availableBalance = null;
        this.returnedGiftCardID = null;
      }

      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (val) {
        this.$root.$emit('barcodeScanner.pause');
      } else {
        this.$root.$emit('barcodeScanner.unpause');
      }
    },
  },

  methods: {
    redeem() {
      this.createVoucher();
    },
    activate() {},
    getVoucherDetails() {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.ZMYLE.DETAILS, {
          voucher_id: this.scannedGiftCardID,
        })
        .then((res) => {
          if (res.data.status === 'SUCCESS') {
            this.authorizedActions = [...res.data.data.authorizedActions];
            this.expectedAction = res.data.data.expectedAction;
            this.availableBalance = res.data.data.availableBalance;
            this.returnedGiftCardID = res.data.data.id;
          } else if (res.data.status === 'FAILED') {
            Events.$emit('showSnackbar', {
              message: res.data.msg,
              color: 'error',
            });
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch(() => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    numpadUpdateValue(value) {
      this.voucherValue = value;
    },
    numpadClickedCustomButton(customButton) {
      this.voucherValue = customButton.value;
    },
    numpadResetField() {
      this.voucherValue = '';
    },
    createVoucher() {
      //SET OUR NEW VALUES ON ITEM
      let item = {
        id: 'ZMYLE_' + this.returnedGiftCardID,
        ean: 'ZMYLE$' + this.returnedGiftCardID,
        name: 'Gutscheinkarte ' + this.returnedGiftCardID + this.$store.getters['settings/currencyData']('symbol'),
        originalSellPrice: -parseFloat(this.voucherBalance),
        sellPrice: -parseFloat(this.voucherBalance),
        selectedExtras: [],
        mealSizeID: 0,
        weight: null,
        taxValue: 0,
        depositgroupID: 0,
        discount: 0,
        warenameOverwrite: null,
        individualBookingNo: null,
      };

      if (this.posType === 'gastro') {
        this.$store.dispatch('pos/gastro/bookItem', item).then(() => {
          this.voucherValue = '';

          this.closeDialog();
        });
      } else if (this.posType === 'retail') {
        this.$store.dispatch('pos/retail/bookItem', item).then(() => {
          this.voucherValue = '';

          this.closeDialog();
        });
      } else if (this.posType === 'wholesaleRetail') {
        this.$store.dispatch('pos/wholesaleRetail/bookItem', item).then(() => {
          this.voucherValue = '';

          this.closeDialog();
        });
      }
    },
  },
};
</script>

<style scoped>
.availableBalance {
  user-select: none;
}

@media only screen and (max-width: 1100px) {
  .availableBalance {
    font-family: Anton;
    font-size: 15px;
    text-align: center;
    padding-top: 10%;
  }
}

@media only screen and (min-width: 1101px) {
  .availableBalance {
    font-family: Anton;
    font-size: 20px;
    text-align: center;
    padding-top: 10%;
  }
}
</style>
