<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="700px" class="pa-0 ma-0 mx-0 extras_dialog">
    <v-card class="ma-0">
      <v-toolbar v-if="$vuetify.breakpoint.mdAndUp" class="bg-grey border-bottom  pr-0 mr-0" dense elevation="1" flat>
        <v-tabs v-model="tab" class="transparent">
          <v-tab v-for="extraTab in extraTabs" :key="extraTab.id" class="transparent" ripple :style="extraTab.minChoose>=1&&!bookingEnabled?'background: rgba(255,154,154,0.5) !important;':''">
            {{ extraTab.name }}
          </v-tab>
        </v-tabs>
      </v-toolbar>

      <v-card-text style="padding: 0; max-height: 600px;min-height: 100px;min-width: 250px;">
        <v-tabs-items v-if="$vuetify.breakpoint.mdAndUp" v-model="tab">

          <v-tab-item v-for="extraTab in extraTabs" :key="extraTab.id">
            <v-card-text v-if="!extraTab.layout">
              <v-card v-for="extra in extraTab.extras" :key="extra.id" flat tile :color="extra.amount>0?'#FFE0B2':''">

                <v-row>
                  <v-col class="text-left" cols="12" md="3">
                    <v-btn icon text @click="removeExtra(extra,extraTab.id)">
                      <v-icon>remove</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col class="text-center" cols="12" md="6">
                    <p>
                      <span>{{ extra.amount }}x</span>
                      <span style="font-weight: bold; font-size: 19px;"><br>{{ extra.name }}</span>
                      <span><br>{{ extra.sellPrice | currency }}</span>
                    </p>
                  </v-col>

                  <v-col class="text-right" cols="12" md="3">
                    <v-btn icon text @click="addExtra(extra,extraTab.id)">
                      <v-icon>add</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

              </v-card>
            </v-card-text>
            <v-row v-else-if="extraTab.layout" class="text-center ma-0">
              <v-col v-show="extra.active" v-for="extra in extraTab.extras" :key="extra.id" cols="6" lg="3" md="3"
                     sm="3">
                <v-card class="pt-1 pl-1 extra-card text-black pr-1 ma-0 rounded-0 image_size" elevation="0"
                        @click="addExtra(extra,extraTab.id)" :color="extra.amount>0?'#FFE0B2':'#F1F9FF'"
                        :style="{
                  /*'background-image': 'url(' + 'https://img.3pos.de/unsafe/300x150/billsys_' + api.auth.posID+ '/original/' + extra.productImage  + ')',*/
                }">
                  <v-row class="pa-0 ma-0" no-gutters>


                    <v-col class="mx-auto pa-0" cols="2">
                      <v-icon class="text-primary rounded" @click.stop="removeExtra(extra,extraTab.id)">remove
                      </v-icon>
                    </v-col>

                    <v-col class="pointer  mx-auto pt-1 pb-0" cols="6">
                                          <span class="mt-0 text-primary mx-auto">
                                            {{ extra.amount }}
                                           </span>
                    </v-col>
                    <v-col class="mx-auto pointer   pa-0" cols="2" @click.stop="addExtra(extra,extraTab.id)">
                      <v-icon class="text-primary rounded">add</v-icon>
                    </v-col>

                    <v-col class="text-center pb-0 pt-1" cols="12">
                      <v-avatar color="#2699FB" size="60" style="border: 1px solid #2699FB">
                        <img
                            v-if="!!extra.productImage"
                            :src="'https://img.3pos.de/unsafe/100x100/billsys_' + api.auth.posID + '/original/' + extra.productImage"/>
                        <h1 v-else class="text-white pt-2">{{ (extra.name && extra.name.substring(0, 1)) || 'u' }}</h1>
                      </v-avatar>
                    </v-col>
                    <v-col class="pb-0 pointer pt-1 ma-0 font-weight-bolder text-white" cols="12">
                     <span class="font-size-md font-weight-light" style="color: #2699FB !important;">
                       {{ extra.name }}
                     </span>
                      <h6 class="font-weight-bolder mt-0 font-size-md text-light mx-auto"
                          style="color: #2699FB !important;"
                      >{{
                          extra.sellPrice | currency
                        }}</h6>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

          </v-tab-item>
        </v-tabs-items>
        <v-expansion-panels v-else
                            style="z-index: 1 !important;"
                            class="elevation-0 pa-0 ma-0 pt-2"
                            focusable
                            v-model="panel"
        >
          <v-expansion-panel
              v-for="extraTab in extraTabs"
              :key="extraTab.id"
              class="elevation-0 pa-0 mx-0"
              style="z-index: 1 !important; border: 1px solid #D8D8D8;"
          >
            <v-expansion-panel-header class="elevation-0 px-4 py-0 ma-0"
                                      :class="(checkColorLightOrDark(getExtraTabColor(extraTab)?getExtraTabColor(extraTab):'#fff') === 'light' ? 'text-black' : 'text-light')"
                                      :color="getExtraTabColor(extraTab)" :style="extraTab.minChoose>=1&&!bookingEnabled?'background: rgba(255,154,154,0.5) !important;':''"
            >

            <div
                  class="d-flex justify-space-between pb-0 pl-0 pr-4 ma-0"
              >
                <h5 class="font-weight-bold body-2 ma-0 align-self-center">
                  {{ extraTab.name }}
                </h5>
              </div>
            </v-expansion-panel-header>

            <!--<v-divider class="pa-0 ma-0"></v-divider>-->

            <v-expansion-panel-content color="white" class="pa-0 ma-0 less-padding">
              <div v-if="!extraTab.layout" class="overflow-auto" style="max-height: 365px;text-align: center;">
                <div v-for="extra in extraTab.extras" :key="extra.id" class="border-top" :style="extra.amount>0?'background-color: #FFE0B2;':''">

                  <v-row no-gutters>
                    <v-col class="text-left px-2" cols="3" md="3" sm="3" align-self="center">
                      <v-icon size="22" class="ma-1" @click="removeExtra(extra,extraTab.id)">remove</v-icon>
                    </v-col>

                    <v-col class="text-center" cols="6" sm="6" md="6">
                      <p>
                        <span>{{ extra.amount }}x</span>
                        <span style="font-weight: bold; font-size: 19px;"><br>{{ extra.name }}</span>
                        <span><br>{{ extra.sellPrice | currency }}</span>
                      </p>
                    </v-col>

                    <v-col class="text-right px-2" cols="3" sm="3" md="3" align-self="center">
                      <v-icon size="22" class="ma-1" @click="addExtra(extra,extraTab.id)">add</v-icon>
                    </v-col>
                  </v-row>

                </div>
              </div>
              <div v-else class="overflow-auto text-center" style="max-height: 365px;">
                <div v-for="extra in extraTab.extras" :key="extra.id" @click="addExtra(extra,extraTab.id)"
                     class="items-inner" :style="extra.amount>0?'background-color: #FFE0B2 !important;':''">
                  <div class="ma-0 " style="line-height: 14px;font-size: 11px;">{{ extra.name || $t('generic.lang_unknown') }}</div>
                  <div class="items-image img-circle">
                    <img v-if="!!extra.productImage"
                         class="img-circle"
                         :src="'https://img.3pos.de/unsafe/100x100/billsys_' + api.auth.posID + '/original/' + extra.productImage"/>
                    <h4 v-else class="text-white">
                      {{ extra.name ? extra.name.substring(0, 1) : '' }}
                    </h4>
                  </div>

                  <h6 class="font-weight-bolder ma-0 font-size-md text-light mx-auto"
                      style="color: #FF6969 !important;">{{
                      extra.sellPrice | currency
                    }}</h6>
                  <div class="items-details">
                    <v-icon size="22" class="text-muted float-left rounded-circle"
                            style="background-color: lightgray !important;"
                            @click.stop="removeExtra(extra,extraTab.id)">remove
                    </v-icon>
                    <span>{{ extra.amount }}x</span>
                    <v-icon size="22" class="text-muted float-right rounded-circle"
                            style="background-color: lightgray !important;" @click.stop="addExtra(extra,extraTab.id)">
                      add
                    </v-icon>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-if="loading" style="text-align:center;">
          <v-progress-circular indeterminate size="80" class="my-auto mx-auto center"
                               color="primary"></v-progress-circular>
        </div>
      </v-card-text>


      <v-card-actions v-if="$vuetify.breakpoint.smAndDown" class="border-top pt-0 pb-0 pr-2 pl-1">
        <v-btn color="#FF6969" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer/>
        <v-btn :disabled="!bookingEnabled" class="text-white" color="#FF6969" elevation="0" rounded width="60"
               @click="editExtras">
          {{ $t('generic.lang_apply') }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else class="border-top pt-0 pb-0 pr-2 pl-1">
        <v-btn color="primary" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer/>
        <v-btn :disabled="!bookingEnabled" color="primary" elevation="0" outlined @click="editExtras">
          {{ $t('generic.lang_apply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapState} from "vuex";
import {ENDPOINTS} from "../../../config";
import {checkColorLightOrDark} from "@/plugins/checkColorLightOrDark";

export default {
  name: "ItemEditExtraDialog",
  props: {
    item: Object,
    itemGroup: Object,
    mealSize: Object,
    showDialog: Boolean,
  },
  data() {
    return {
      tab: null,
      dialog: false,
      loading: false,
      extraTabs: [],
      extraTabRestrictions: {},
      panel: 0,
      selectedItem: this.item
    }
  },
  watch: {
    showDialog(val) {
      if (val)
        this.loading = false;

      this.dialog = val;
    },

    dialog(val) {
      if (!val) {
        //this.cancelDialog();
      } else {
        this.getExtras();
      }
    },
  },
  computed: {
    ...mapState([
      'api',
    ]),
    bookingEnabled() {
      let hasErrors = false;

      for (let tabID in this.extraTabRestrictions) {
        let selectedInExtraTab = this.extraTabRestrictions[tabID].tabCounter;

        /* extraTab.extras.forEach((extra) => {
           selectedInExtraTab += extra.amount;
         });*/

        if (this.extraTabRestrictions[tabID].maxChoose > 0 && (selectedInExtraTab > this.extraTabRestrictions[tabID].maxChoose))
          hasErrors = true;

        if (this.extraTabRestrictions[tabID].minChoose > 0 && (selectedInExtraTab < this.extraTabRestrictions[tabID].minChoose))
          hasErrors = true;
      }

      return !hasErrors;
    }
  },
  methods: {
    checkColorLightOrDark,
    getExtraTabColor(extraTab){
      if(extraTab.hasOwnProperty('id') && extraTab.color){
        return extraTab.color
      }
      return '';
    },
    async getExtras() {
      this.loading = true;
      this.extraTabs = [];
      let mealSize = this.mealSize;

      if (mealSize === null) {
        mealSize = 0;
      } else {
        mealSize = this.mealSize.id;
      }
      this.axios.post(ENDPOINTS.POS.GASTRO.ITEMGARNISH, {
        itemMealSizeId: mealSize,
        itemId: this.item.id,
        itemGroupId: this.item.itemgroupID
      }).then((res) => {
        if (res.data.success === true) {
          this.extraTabs = res.data.extras;
          this.extraTabRestrictions = res.data.extraTabChooser;

          this.extraTabs.forEach((extraTab, index) => {
            extraTab.extras.forEach(extra => {
              this.item.selectedExtras.forEach(selected => {
                if (selected.extraTabID === extraTab.id) {
                  if (extra.id === selected.id) {
                    this.extraTabRestrictions[extraTab.id].tabCounter += selected.amount;
                    extra.amount = selected.amount;
                  }
                }
              });
            });

          })
        } else if (res.data.success === false) {

          let errormsgSerialReq = "";

          Events.$emit("showSnackbar", {
            message: res.data.code,
            color: "error"
          });
          this.$emit("failed")
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
          this.$emit("failed")
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
        this.$emit("failed");
      }).finally(() => {
        this.loading = false;
      });
    },

    removeExtra(extra, tabID) {
      if (extra.amount > 0) {
        extra.amount--;
        this.extraTabRestrictions[tabID].tabCounter--;
      }
    },
    addExtra(extra, tabID) {
      //GET TOTAL AMOUNT
      /*let totalAmount = extraTab.extras.reduce((total, extra) => {
        return total + extra.amount;
      }, 0);*/

      let totalAmount = this.extraTabRestrictions[tabID].tabCounter;

      //CHECK IF WE REACHED MAX VALUE
      if (this.extraTabRestrictions[tabID].maxChoose > 0 && (totalAmount >= this.extraTabRestrictions[tabID].maxChoose)) {
        return;
      }

      extra.amount++;
      this.extraTabRestrictions[tabID].tabCounter++;

      if(this.extraTabRestrictions[tabID].maxChoose===1
          && this.extraTabRestrictions[tabID].tabCounter===1
          && this.tab<this.extraTabs.length
          && this.panel<this.extraTabs.length
          && this.extraTabs.length>1
      ){
        this.tab++;
        this.panel++;
      }
    },
    editExtras() {
      //SUBMIT CHANGES
      let extras = [];
      this.extraTabs.forEach((extraTab) => {
        extraTab.extras.forEach((extra) => {
          if (extra.amount > 0) {
            extras.push(extra);
          }
        });
      });
      this.$emit("selectExtras", extras);
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
  mounted() {
    this.dialog = this.showDialog;
  }
}
</script>

<style scoped>
.extras_app_bar .v-slide-group__prev {
  display: none !important;
}

.items-inner {
  border-radius: 3px;
  text-align: center;
  padding: 5px 5px 5px;
  margin: 5px 5px;
  border: 1px lightgray solid;
  width: 30%;
  display: inline-block;
  vertical-align: top;
}

.items-image {
  display: block;
  margin: 0 auto;
  max-width: 50px;
  background-color: #FF6969;
}

.items-image img {
  display: inline-block;
  max-width: 100%;
  border: 0;
}

.img-circle {
  border-radius: 50%;
  height: 50px;
}

.mobile-extras-color::before {
  background-color: #FF6969;
}

.mobile_extras_name {
  font-size: 9px !important;
}

#hider {
  background-color: #F1F9FF;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  right: 0;
  display: none;
}

.pointer {
  cursor: pointer;
}

.extra-card *, .extra-card-mobile * {
  cursor: pointer !important;
  user-select: none !important;
}

.extra-card {
  user-select: none !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.v-expansion-panel-content__wrap {
  padding: 2px 2px 2px 2px !important;
}

.scroller {
  height: 100%;
}
</style>
