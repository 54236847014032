<template>
  <v-btn v-if="this.$vuetify.breakpoint.smAndDown" class="ma-0 swipeout-action" small style="width: 20px !important" @click="toggle">
    <span v-if="showButtonText">{{ $t('accounting.lang_nav_accounting_dailyabX') }}</span>
    <span class="btnIconSize" style="font-size: 28px;">X</span>
  </v-btn>
  <div v-else>
    <button class="btn-icon-vertical btn-square br-tr btn-transition btn btn-outline-primary" @click="toggle">
      <span class="btnIconSize" style="font-size: 28px;">X</span><br><span v-if="showButtonText">{{ $t('accounting.lang_nav_accounting_dailyabX') }}</span>
    </button>
  </div>
</template>

<style scoped>
.btn-icon-vertical {
  padding: 7px !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>

<script>
import {ENDPOINTS} from "../../../config";
import {Events} from "../../../plugins/events";
import {mapState} from "vuex";
import {printDataFromPrinter} from "../../../plugins/printing/printerController";

export default {
  name: "ButtonsPrintXReport",

  computed: {
    ...mapState([
      'pos',
      'api',
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    }
  },

  methods: {
    getActiveShift() {
      this.axios.post(ENDPOINTS.POS.ACTIVESHIFT.GET, {
        cashierID: this.api.auth.cashierID
      }).then((res) => {
        if (res.data.success) {
          //ATTACH ACTIVE SHIFT
          this.$store.dispatch("pos/updateActiveShift", res.data.activeShift).then(() => {
            this.printXReport();
          });
        } else {
          this.loadingXPrinting = false;

          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        }
      }).catch(() => {
        this.loadingXPrinting = false;

        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_printProblemConnected'),
          color: "error"
        });
      });
    },
    printXReport() {
      this.loadingXPrinting = true;

      //CHECK IF SHIFT ID IS SET
      if (this.pos.activeShift.uuid === null) {
        this.getActiveShift();

        return;
      }

      this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.PRINTX, {
        reportType: "X",
        reportID: this.pos.activeShift.uuid
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          // EPSON EPOS PRINT
          printDataFromPrinter(this.invoicePrinter,res.data.printXML).then(()=>{

          }).catch((err)=>{
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_printProblemConnected'),
              color: "error"
            });
          }).finally(()=>{
            this.loadingXPrinting = false;
          })

        } else {
          this.loadingXPrinting = false;

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_printProblemConnected'),
            color: "error"
          });
        }
      }).catch(() => {
        this.loadingXPrinting = false;

        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_printProblemConnected'),
          color: "error"
        });
      });
    },
    toggle(){
      if(Number(this.$store.getters['settings/getSettingValue']('displayOpenTableWarning'))>0&&this.$store.getters['permissions/checkModule'](2)&&this.posType==='gastro'){
        this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.INFO).then(res=>{
          if(res.data.success){
            if(res.data.tables && res.data.tables.length>0){
              this.$swal({
                title: this.$t('generic.lang_warning'),
                text: this.$t('generic.lang_dailyReportOpenTablesWarningMSG'),
                icon: "warning",
                showCancelButton: true,
                showLoaderOnConfirm: true,
                confirmButtonText: this.$t('generic.lang_next'),
                cancelButtonText: this.$t('generic.lang_cancel'),
                preConfirm: () => {
                  this.printXReport()
                },
                allowOutsideClick: () => !this.$swal.isLoading,
              });
            }else{
              this.printXReport()
            }
          }
        }).catch(err=>{
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred')+' : '+err.message,
            color: "error"
          });
        })
      }else{
        this.printXReport()
      }
    }

  },
  props: {
    posType: String,
    showButtonText: Boolean
  }
}
</script>