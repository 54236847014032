<template>
  <div>
    
  <v-dialog v-model="showDialog" persistent scrollable max-width="700px" @keydown.enter="selectedItem === null || !selectedItem.active ? null : selectItem()">
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('erp.lang_ware_search') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0"/>
      <v-card-text style="min-height: 130px;">
        <v-text-field style="margin-top: 20px;" :loading="loadingSearch" outlined :label="$t('erp.lang_posItemName')+', EAN'"
                      :placeholder="$t('erp.lang_posItemName')+', EAN'"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="focusTextInput" autofocus v-model="searchInput"></v-text-field>
      </v-card-text>

      <v-card-text v-if="selectedItem === null" style="padding: 0; max-height: 500px; margin-top: -35px;">
        <v-list>
          <v-list-item v-for="item in showItems" :key="item.id" @click="selectedItem = item">
            <v-list-item-content v-if="!item.isVariation">
              <v-list-item-title v-text="item.name"></v-list-item-title>
              <v-list-item-subtitle>
                <span v-if="item.description && item.description.length > 0">{{ item.description.substring(0,100) }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content v-else>
              <v-list-item-title v-text="item.name"></v-list-item-title>
              <v-list-item-subtitle>
                <span v-if="item.variationSize.length > 0">
                  {{ $t('generic.lang_size') }}: {{
                    item.variationSize
                  }}
                </span> <br>

                <span v-if="item.variationColor.length > 0">{{
                  $t('erp.lang_warecreate_color')
                }}: {{ item.variationColor }}
                </span><br>

                <span v-if="item.description && item.description.length > 0">{{ item.description.substring(0,100) }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-text style="padding: 0; margin-top: -35px;">
        <v-tabs v-model="tab" background-color="#424242" dark centered v-if="selectedItem !== null" style="margin-top: 30px;">
          <v-tab id="tab0">{{ $t('generic.lang_set_overall') }}</v-tab>
          <v-tab id="tab1">{{ $t('erp.lang_Allergene') }} / {{ $t('erp.lang_Zusatzstoffe') }}</v-tab>

          <v-tab-item>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ $t('generic.lang_name') }}</td>
                    <td>{{ selectedItem.name }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('generic.lang_status') }}</td>
                    <td>
                      <v-chip label small color="success" v-if="selectedItem.active">{{ $t('settings.lang_settings_activ') }}</v-chip>
                      <v-chip label small color="error" v-if="!selectedItem.active">{{ $t('generic.lang_inactive') }}</v-chip>
                    </td>
                  </tr>
                  <tr>
                    <td>EAN</td>
                    <td>{{ selectedItem.ean }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('erp.lang_groosSalesPrice') }}</td>
                    <td>{{ selectedItem.sellPrice | currency }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>

          <v-tab-item>
            <v-container style="padding: 30px;">
              <v-row>
                <v-col cols="12">
                  <div v-if="selectedItem.allergene.length > 0">
                    <p style="margin-bottom: 0;"><b>{{ $t('erp.lang_Allergene') }}:</b></p>

                    <ul style="margin-left: 15px;">
                      <li v-for="allergen in selectedItem.allergene" :key="allergen.id">{{ allergen.name }}</li>
                    </ul>
                  </div>

                  <v-alert v-else text color="info">
                    {{ $t('erp.lang_noAllergensPresent') }}
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-divider></v-divider>

                  <div v-if="selectedItem.zusatzstoffe.length > 0">
                    <p style="margin-bottom: 0;"><b>{{ $t('erp.lang_Zusatzstoffe') }}:</b></p>

                    <ul style="margin-left: 15px;">
                      <li v-for="additive in selectedItem.zusatzstoffe" :key="additive.id">{{ additive.name }}</li>
                    </ul>
                  </div>

                  <v-alert v-else text color="info">
                    {{ $t('erp.lang_noIngredientsPresent') }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-card-text>

      <v-card-actions style="margin-top: 35px;">
        <v-btn color="blue darken-1" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer/>
        <v-btn color="success" :disabled="selectedItem === null || !selectedItem.active" @click="selectItem">{{ $t('accounting.lang_book') }}
        </v-btn>
      </v-card-actions>

      <BookItem ref="itemBooking" :pos-type="posType" @itemBooked="closeDialog" @voucherBooked="closeDialog"></BookItem>

    </v-card>
  </v-dialog>
  <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard" style="z-index: 299 !important;">
    <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                        :accept="hideTouchKeyboard"
                        :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                        :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                        :options="touchKeyboard.options" class="internalWidthExpanded"/>
  </div>
  </div>
</template>

<style scoped>
.v-list .v-list-item:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
</style>

<script>
import {mapState} from "vuex";
import BookItem from "../../../mixins/pos/BookItem";
import {ENDPOINTS} from "@/config";
import KeyboardMixIns from "../../../mixins/KeyboardMixIns";

export default {
  name: "ButtonsSearchItemsDialog",

  props: {
    posType: String,
    showDialog: Boolean
  },

  components: {
    BookItem
  },
  mixins:[KeyboardMixIns],
  data() {
    return {
      searchInput: "",
      showItems: [],
      selectedItem: null,
      awaitingSearch: null,
      loadingSearch: false,
      tab: null,
    }
  },

  watch: {
    showDialog(val) {
      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (val) {
        this.$root.$emit('barcodeScanner.pause')
        if(document.getElementById("tab0")) this.tab = 0;
      } else {
        this.$root.$emit('barcodeScanner.unpause')
        if(document.getElementById("tab0")) this.tab = null;
      }
    },
    searchInput(val) {
      if(this.selectedItem !== null)
        this.selectedItem = null;

      this.loadingSearch = true;
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        val && val !== this.select && this.searchForItem(val)
      }, 500);
    },
    tab(val) {
      if(val == 0) {
        document.getElementById("tab0").focus();
      } else {
        document.getElementById("tab1").focus();
      }
    }
  },
  computed: {
    ...mapState(['app'])
  },
  methods: {
    closeDialog() {
      //RESET DATA
      this.resetData();

      this.$emit("closeDialog");
    },
    //THIS FUNCTION IS FOR GETTING ITEMS FROM AVAILABLE ITEMS
    searchForItem(v) {
      this.axios.post(ENDPOINTS.ERP.ITEM["SEARCH2"], {
        query: v,
        limit: 100,
      }).then((res) => {
        this.showItems = res.data;
        this.loadingSearch = false;
      })

      this.loading = false;
    },
    selectItem() {
      if(this.app.useAPIForPOSActions && (this.posType === "retail" || this.posType==="wholesaleRetail") ) {
        this.axios.post(ENDPOINTS.POS.RETAIL.SCAN, {
          query: this.selectedItem.ean
        }).then((res) => {
          if(res.data.success)
            this.$refs.itemBooking.selectItemByData(res.data.itemgroup, res.data.item, res.data.overwrite.weight);
        });
      }else {
        this.$refs.itemBooking.selectItemEAN(this.selectedItem.ean);
      }
    },
    resetData() {
      this.searchInput = "";
      this.selectedItem = null;
      this.showItems = [];
    },
    focusTextInput(event) {
      this.loadingSearch = false;
      this.selectedItem = null;
      this.showTouchKeyboard(event);
    },
  },
}
</script>