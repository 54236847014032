<template>
  <v-dialog v-model="showDialog" persistent max-width="700">
    <v-card>
      <v-toolbar class="pa-1" v-bind:style="{
        background: this.$vuetify.breakpoint.smAndDown ? '#FF6969' : 'white'
      }" flat>
        <v-toolbar-title class="pa-1" v-bind:style="{
        color: this.$vuetify.breakpoint.smAndDown ? 'white' : ''
      }">{{ customer.first_name }} {{ customer.last_name }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeDialog">
          <v-icon :color="this.$vuetify.breakpoint.smAndDown ? 'white' : ''">close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- ALLERGENS -->
      <v-toolbar flat color="grey" class="lighten-3">
        <v-toolbar-title>{{ $t('erp.lang_Allergene') }}</v-toolbar-title>
      </v-toolbar>
      <v-progress-linear indeterminate color="primary" v-if="this.loader"/>
      <v-list>
        <v-list-item v-for="allergen in allergens" :key="allergen.id">
          <v-list-item-title>{{ allergen.name }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="this.$vuetify.breakpoint.smAndDown ? '#FF6969' : 'info'" @click="closeDialog" text>
          {{ $t('generic.lang_close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ENDPOINTS} from "@/config";

export default {
  name: "InvoiceCustomerInfoDialog",

  props: {
    showDialog: Boolean,
    customer: Object
  },
  data: () => {
    return {
      loader: false,
      allergens: []
    }
  },
  watch: {
    showDialog(val) {
      if (val && this.customer) {
        this.loader = true;
        this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.LUNCHBEE.ALLERGENS.GETWITHNAMES, {
          customerId: this.customer.id
        }).then((res) => {
          if (res.data.success) {
            this.allergens = res.data.allergens || []
          }
        }).finally(() => {
          this.loader = false
        })
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    }
  }
}
</script>
