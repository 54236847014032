<template>
  <div>
    <template v-if="showButton">

      <button v-show="!this.hideButton"
              class="btn-icon-vertical btn-square  br-tr btn-transition btn btn-outline-primary"
              @click="openDialog">
        <i class="btn-icon-wrapper" style="display:block !important;">
          <font-awesome-icon :icon="['fal', 'exchange-alt']"/>
        </i><span v-if="showButtonText">{{ $t('generic.lang_cashier_mergeTable') }}</span>
      </button>

      <v-dialog v-model="dialogOpen" max-width="600px" scrollable persistent >
        <v-card>
          <v-toolbar v-if="$vuetify.breakpoint.smAndDown" color="#FF6969" dense flat>
            <v-toolbar-title class="text-white">{{ $t('generic.lang_cashier_mergeTable') }}</v-toolbar-title>

            <v-spacer/>

            <v-btn class="text-white" icon @click="dialogOpen = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-toolbar v-else dense flat>
            <v-toolbar-title>{{ $t('generic.lang_cashier_mergeTable') }}</v-toolbar-title>

            <v-spacer/>

            <v-btn icon @click="dialogOpen = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider class="ma-0"/>
          <v-card-text v-if="loading" class="text-center">
            <v-progress-circular color="primary" indeterminate size="40"
                                 style="margin-top: 20px;"></v-progress-circular>
          </v-card-text>

          <v-tabs v-if="!loading" :color="$vuetify.breakpoint.smAndDown? '#FF6969' : 'primary'" centered>
            <v-tab>{{ $t('generic.lang_cashier_mergeTable') }}</v-tab>
            <v-tab>{{ $t('generic.lang_cashier_mergeTableParties') }}</v-tab>

            <v-tab-item>
              <v-card-text style="min-height: 400px;">
                <v-layout style="margin-top: 20px;" wrap>
                  <v-flex md5>
                    <v-select
                        v-model="selectedParties"
                        dense
                        :items="pos.gastro.table && pos.gastro.table.parties || []"
                        :label="$t('generic.lang_parties')"
                        chips
                        deletable-chips
                        item-text="name"
                        item-value="name"
                        multiple
                        outlined
                    ></v-select>
                  </v-flex>

                  <v-flex md6 offset-md1>
                    <v-select
                        v-model="selectedTable"
                        dense
                        :items="filteredTables"
                        :label="$t('generic.lang_gastro_mergeTableWith')"
                        chips
                        deletable-chips
                        item-text="name"
                        item-value="name"
                        outlined
                    >
                      <template slot="item" slot-scope="data">
                        {{ data.item.name }} <span class="grey--text" style="margin-left: 10px;">({{
                          data.item.room.name
                        }})</span>
                      </template>
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-card-text>

              <v-card-actions>
                <v-btn color="error" text @click="dialogOpen = false">{{ $t('generic.lang_cancel') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    :class="this.$vuetify.breakpoint.smAndDown? 'text-white' : ''"
                    :color="this.$vuetify.breakpoint.smAndDown? '#FF6969' : 'success'"
                    :disabled="loading2 || selectedTable === null || selectedTable === '' || selectedParties.length === 0"
                    :elevation="this.$vuetify.breakpoint.smAndDown? '0' : '3'"
                    :loading="loading2"
                    @click="mergeTable">{{ $t('generic.lang_next') }}
                </v-btn>
              </v-card-actions>
            </v-tab-item>

            <v-tab-item>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-card-title class="subtitle-1">{{$t('generic.lang_parties')}}</v-card-title>
                    <v-list v-if="filteredParties.length > 0" class="pa-0"
                            elevation="1" outlined>
                      <template v-for="(party, index) in filteredParties">
                        <v-list-item :disabled="selectedPartiesToMerge.includes(party)"
                                     :key="party+index" :style="{backgroundColor: (selectedPartiesToMerge.includes(party) ? '#ffb3bd' : '')}"
                                     class="border-bottom"
                                     two-line @click="addParty(party)">
                          <!--:style="{backgroundColor: (invoiceItem.isVoid ? '#ffb3bd' : '')}"-->
                          <v-list-item-content>
                            <v-list-item-title>{{party}}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-col>
                  <v-col cols="6">
                    <v-card-title class="subtitle-1">{{ $t('generic.lang_selectedParties') }}</v-card-title>
                    <v-list v-if="selectedPartiesToMerge.length > 0" class="pa-0"
                            elevation="1" outlined>
                      <v-list-item v-for="(party, index) in selectedPartiesToMerge"
                                   :key="party"
                                   class="border-bottom"
                                   two-line @click="addParty(party)">
                        <v-list-item-content>
                          <v-list-item-title>{{party}}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions >
                <v-btn :disabled="loadingPartyMerge" color="error" text @click="dialogOpen = false">
                  {{ $t('generic.lang_cancel') }}
                </v-btn>
                <v-spacer/>
                <div class="d-block text-right">
                  <v-btn :color="$vuetify.breakpoint.smAndDown? '#FF6969' : 'primary'"
                         :disabled="loadingPartyMerge"
                         :loading="loadingPartyMerge"
                         class="text-white" @click="warningDialog=true;warning='tableParties'">
                    {{ $t('generic.lang_cashier_mergeTableParties') }}
                  </v-btn>
                  <v-btn :color="$vuetify.breakpoint.smAndDown? '#FF6969' : 'primary'"
                         :disabled="loadingPartyMerge||selectedPartiesToMerge.length<2"
                         :loading="loadingPartyMerge"
                         class="text-white" @click="warningDialog=true;warning='parties'">
                    {{ $t('generic.lang_mergeSelectedParties') }}
                  </v-btn>
                </div>
                <v-dialog v-model="warningDialog" width="500">
                  <v-card>
                    <v-card-title class="headline">{{ this.$t('generic.lang_warning') }}</v-card-title>

                    <v-card-text v-if="warning==='tableParties'" v-html="$t('generic.lang_mergeAllTablePartiesMSG').replace('{{$tableName}}','<b>'+(pos.gastro && pos.gastro.table && pos.gastro.table.name)+'</b>')">
                    </v-card-text>
                    <v-card-text v-else>
                      {{$t('generic.lang_mergeSelectedPartiesMSG')}}
                    </v-card-text>

                    <v-card-actions>
                      <v-btn color="error" text @click="warningDialog = false">{{ $t('generic.lang_no') }}</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="success" text @click="mergeTableParties">{{ $t('generic.lang_yes') }}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-actions>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<style scoped>
.btn-icon-vertical {
  padding: 0 !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>

<script>
import {library} from '@fortawesome/fontawesome-svg-core'

import {faExchangeAlt} from '@fortawesome/pro-light-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {ENDPOINTS} from "@/config";
import {mapGetters, mapState} from "vuex";
import {mergeInvoiceArray} from "@/plugins/pos/mergeInvoiceArrays";
import {Events} from "@/plugins/events";

library.add(
    faExchangeAlt
);

export default {
  name: "ButtonsTableMerge",

  components: {
    'font-awesome-icon': FontAwesomeIcon
  },

  props: {
    hideButton: {
      type: Boolean,
      default: false
    },
    posType: String,
    showButtonText: Boolean
  },

  data() {
    return {
      dialogOpen: false,
      warningDialog: false,
      warning: null,
      loading: false,
      loading2: false,
      loadingPartyMerge: false,
      tables: [],
      parties: [],
      selectedParties: [],
      selectedTable: null,
      selectedPartiesToMerge:[],
    }
  },
  computed: {
    ...mapGetters({
      lockedTables:'pos/gastro/lockedTables',
    }),
    filteredTables() {
      if (!(this.tables && this.tables.length > 0)) {
        return [];
      }
      return this.tables.filter(table => {
        return table.name !== this.gastroTableName && !this.lockedTables.includes(table.name);
      });
    },
    filteredParties(){
      if(this.pos.gastro.table && this.pos.gastro.table.name===0){
        return [];
      }

      return Object.keys(this.pos.gastro.orderedItems);
    },
    ...mapState([
      'pos',
    ]),
    ...mapGetters({
      currentParty: "pos/gastro/currentParty"
    }),
    showButton() {
      return !(this.currentParty && this.currentParty.hasOwnProperty('is_payed') && this.currentParty.is_payed);
    },
    gastroTableName() {
      if (this.pos.gastro) {
        if (this.pos.gastro.table) {
          return this.pos.gastro.table.name
        }
      }
      return 0
    }
  },

  watch: {
    dialogOpen(val) {
      if (val) {
        this.$socket.emit("tableplan.getLockedTables");

        this.collectData();
        if(this.pos.gastro.table && this.pos.gastro.table.parties && this.pos.gastro.table!==0){
          if(this.pos.gastro.table.parties.length<=1&&this.selectedParties.length<1){
            this.selectedParties.push(this.pos.gastro.table.parties[0].name);
          }
        }
      }else{
        this.resetData();
      }
    },
  },

  methods: {
    openDialog() {

      //check if we have open items
      if (this.pos.gastro.openItems && this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name) && this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
        Events.$emit("showSnackbar", {
          message: this.$t('erp.lang_errorOrderFirst'),
          color: "warning"
        });
      } else {
        //CHECK IF TABLE IS UNDEFINED
        if (this.pos.gastro.table === undefined)
          return;

        //CHECK IF WE ARE ON TRESENVERKAUF
        if (this.gastroTableName === 0)
          return;

        this.dialogOpen = true;
      }

    },
    addParty(party) {
      if(this.selectedPartiesToMerge.includes(party)){
        let index = this.selectedPartiesToMerge.findIndex((el)=>el===party)
        if(index>=0){
          this.selectedPartiesToMerge.splice(index,1);
        }
      }else{
        this.selectedPartiesToMerge.push(party)
      }
    },
    resetData() {
      this.selectedParties = [];
      this.selectedPartiesToMerge = [];
      this.selectedTable = null;
    },
    collectData() {
      this.loading = true;
      this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.GET).then((res) => {
        if (res.data.success) {
          this.tables = res.data.tables;

          this.loading = false;
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    mergeTable() {
      this.loading2 = true;

      // ENDPOINTS.POS.GASTRO.MERGETABLE , old endpoint for merging tables

      this.axios.post(ENDPOINTS.POS.GASTRO.MERGETABLEWOPARTY, {
        currentTable: this.gastroTableName,
        selectedParties: this.selectedParties,
        targetTable: this.selectedTable
      }).then((res) => {
        this.loading2 = false;
        this.dialogOpen = false;
        if (res.data.success) {
          /*this.$store.dispatch("pos/gastro/setTable", {
            table: {
              name: this.selectedTable,
              parties: res.data && res.data.parties || []
            }
          }).then(() => {
            //SET ALL NEW POSITIONS
            (res.data && res.data.parties || []).forEach((party) => {
              this.$store.commit("pos/gastro/setOrderedItems", {
                partyName: party.name,
                orders: party.orderPositions
              });
            });

            this.dialogOpen = false;
          });*/
          if (this.$vuetify.breakpoint.smAndDown) {
            this.$router.replace({name: 'pos.gastro.tresenverkauf'})
          } else {
            this.$router.replace({name: 'pos.gastro.tables'})
          }
        } else {
          if (res.data.msg === "is Payed true") {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorPayedOrderMergeSplit'),
              color: "error"
            });
          }
        }
      }).catch((err) => {
        this.dialogOpen = false;
        this.loading2 = false;
      });
    },
    mergeTableParties() {
      let endpoint=null;
      let form={};
      let destinationKeys = [];
      let destinationArray = [];
      let sourceArray = [];
      let mergedArray = [];
      //CLOSE WARNING DIALOG
      this.warningDialog = false;

      this.loadingPartyMerge = true;

      if(this.warning==='parties'){
        endpoint=ENDPOINTS.POS.GASTRO.MERGESELECTEDPARTIES;
        form.table=this.gastroTableName;
        form.sourceParties=this.selectedPartiesToMerge;
        form.targetParty=this.selectedPartiesToMerge[0];
      }else{
        endpoint=ENDPOINTS.POS.GASTRO.MERGETABLEPARTIES;



        //TRY TO COLLECT DATA FOR MERGING + MERGE THEM
        try {
          destinationKeys = Object.keys(this.pos.gastro.orderedItems);
          //USE ONLY FIRST OBJECT(PARTY) OF ARRAY(THE DESTINATION ARRAY)
          destinationArray = this.pos.gastro.orderedItems[destinationKeys[0]];

          //THEN USE ALL OTHER OBJECTS(PARTIES) FOR SOURCE ARRAY
          for (let x = 1; x < (destinationKeys.length); x++) {
            this.pos.gastro.orderedItems[destinationKeys[x]].forEach((orderedItem) => {
              sourceArray.push(orderedItem);
            });
          }

          //MERGE BOTH ARRAYS
          mergedArray = mergeInvoiceArray(sourceArray, destinationArray);
        } catch (err) {
          this.loadingPartyMerge = false;
        }

        //CHECK IF THERE IS DATA
        if (sourceArray.length === 0) {
          this.loadingPartyMerge = false;
          return;
        }
        
        form.table=this.gastroTableName
        form.targetParty=destinationKeys[0]
        form.orderedItems=mergedArray
      }
      //MAKE AXIOS CALL TO SAVE THE PARTY-MERGE
      this.axios.post(endpoint, form).then((res) => {
        if (res.data.success) {
          //DELETE TABLE PARTIES
          if(this.warning==='parties'){
            //DELETE TABLE PARTIES
            for (let x = 1; x < (this.selectedPartiesToMerge.length); x++) {
              this.$store.commit("pos/gastro/deleteTableParty", this.selectedPartiesToMerge[x]);
            }

            //UPDATE ORDERED ITEMS
            this.$store.commit("pos/gastro/setOrderedItems", {
              partyName: form.targetParty,
              orders: res.data.data
            });

            this.$root.$emit("mergedTableParties");
            this.dialogOpen = false;

          }else{
            for (let x = 1; x < (destinationKeys.length); x++) {
              this.$store.commit("pos/gastro/deleteTableParty", destinationKeys[x]);
            }

            //UPDATE ORDERED ITEMS
            this.$store.commit("pos/gastro/setOrderedItems", {
              partyName: destinationKeys[0],
              orders: mergedArray
            });

            this.$root.$emit("mergedTableParties");
            this.dialogOpen = false;
          }
        }else{
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')+':'+err,
          color: "error"
        });
      }).finally(()=>{
        this.loadingPartyMerge = false;
        this.warning = null;
      })
    },

  }
}
</script>
