<template>
  <div>
    <button v-if="this.$vuetify.breakpoint.smAndDown" class="br-tr btn-transition btn mr-1 text-white bg-primary"
            style="height: 50px !important;width: 50px !important;padding:  4px 6px  6px  6px !important"
            @click="openDialog">
      <i class="btn-icon-wrapper ">
        <font-awesome-icon style="font-size:40px" :icon="['fal', 'info-square']"/>
      </i><span v-if="showButtonText">{{ $t('generic.lang_info') }}</span>
    </button>

    <button v-else class="btn-icon-vertical btn-square btn-transition btn btn-outline-primary" @click="openDialog">
      <i class="btn-icon-wrapper">
        <font-awesome-icon :icon="['fal', 'info-square']"/>
      </i><span v-if="showButtonText">{{ $t('generic.lang_info') }}</span>
    </button>

    <ButtonsItemInfoDialog v-if="selectedItem" :item="selectedItem" :pos-type="posType" :show-dialog="showDialog"
                           @closeDialog="showDialog = false"/>
  </div>
</template>

<style scoped>
.btn-icon-vertical {
  padding: 0 !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>

<script>
import ButtonsItemInfoDialog from "./ButtonsItemInfoDialog";

import {library} from '@fortawesome/fontawesome-svg-core'
import {faInfoSquare} from '@fortawesome/pro-light-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {mapState} from "vuex";

library.add(
    faInfoSquare
);

export default {
  name: "ButtonsItemInfo",

  components: {
    'font-awesome-icon': FontAwesomeIcon,
    ButtonsItemInfoDialog
  },

  props: {
    posType: String,
    showButtonText: Boolean
  },

  data() {
    return {
      showDialog: false
    }
  },

  computed: {
    ...mapState([
      'pos'
    ]),
    selectedItem() {
      if (this.posType === "retail") {
        return this.pos.retail.selectedItem;
      }
      
      if (this.posType === "wholesaleRetail") {
        return this.pos.wholesaleRetail.selectedItem;
      }

      if (this.posType === "gastro") {
        if (this.pos.gastro.selectedOpenItem !== null) {
          return this.pos.gastro.selectedOpenItem;
        } else if (this.pos.gastro.selectedOrderedItem !== null) {
          return this.pos.gastro.selectedOrderedItem;
        }
      }

      return null;
    }
  },

  methods: {
    openDialog() {
      if (this.selectedItem === null)
        return;

      if (this.selectedItem.ean.includes("V$") || this.selectedItem.ean.includes("GF$") || this.selectedItem.ean.includes("ZMYLE$") || this.selectedItem.ean.includes("MYWORLD$"))
        return;

      this.showDialog = true;
    }
  }
}
</script>
