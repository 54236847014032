<template>
  <v-card elevation="0" class="pa-0">
    <v-card-text>
      <v-container
          id="container"
          class="py-6 px-6"
          fluid
      >
        <v-row dense>
          <!-- start loader -->
          <v-col v-for="i in loading ? 12 : 0" :key="i" cols="2">
            <event-loader-component/>
          </v-col>
          <!-- end loader -->

          <!-- start events listing -->
          <v-col
              class="text-center px-5"
              :key="i"
              v-for="(event, i) in loading ? [] : filteredEvents"
              cols="2"
          >
            <event-component :event="event" @click="setEvent($event)"/>
          </v-col>

          <!-- start unavailable events message -->
          <v-col v-if="events && events.length<1 && !loading" cols="12">
            <v-alert outlined type="info">{{
                $t('dashboard.lang_noNewEvents')
              }}
            </v-alert>
          </v-col>
          <!-- end unavailable events message -->
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import EventComponent from './EventComponent';
import EventLoaderComponent from './EventLoaderComponent';
import {ENDPOINTS} from "../../../../config";
import {Events} from "../../../../plugins/events";

export default {
  name: 'EventsComponent',
  components: {EventComponent, EventLoaderComponent},
  data() {
    return {
      events: [],
      loading: false
    };
  },
  inject:['date'],
  computed: {
    filteredEvents(){
      return _.orderBy(this.events,'is_featured','desc')
    }
  },
  methods: {
    setEvent(event) {
      this.$emit('input', event);
    },
    getEvents() {
      this.loading = true;
      this.axios.post(ENDPOINTS.POS.EVENTBEE.EVENTS.GET_ALL, {
        date: this.$moment.utc(this.date()).unix(),
      }).then(res => {
        if (res.data.status) {
          this.events = res.data.data || [];
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
        });
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  mounted() {
    this.getEvents();
  }
};
</script>

<style scoped>
#container {
  border-radius: 25px !important;
  position: relative;
  background-color: #efefef !important;
}

#container::before {
  content: ' ';
  width: 300px;
  height: 2px;
  background-color: #929292;
  position: absolute;
  top: 0;
  left: 0 !important;
  right: 0 !important;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10%;
}

.event-elt-col {
  min-width: 295px !important;
  max-width: 100% !important;
}
</style>