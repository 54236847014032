<template>
    <div>
        <button @click="openDialog" class="btn-icon-vertical btn-square  br-tr btn-transition btn btn-outline-primary">
            <i class="btn-icon-wrapper">
                <font-awesome-icon :icon="['fal', 'bell']"/>
            </i>
            <span v-if="showButtonText">{{ $t('generic.lang_course') }}</span>
        </button>
        <v-dialog v-model="dialogOpen" scrollable max-width="70%">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title>{{ $t('erp.lang_nav_settings_gangSteuerung') }}</v-toolbar-title>

                    <v-spacer/>

                    <v-btn icon @click="closeDialog">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text v-if="dialogOpen">
                    <v-layout style="margin-top: 40px;">
                        <v-flex shrink md1>
                            <v-text-field
                                    style="margin-left: 10px;"
                                    v-model="itemAmount"
                                    class="mt-0"
                                    hide-details
                                    single-line
                                    type="number"
                            />
                        </v-flex>

                        <v-flex md9>
                            <v-slider style="margin-left: 20px;" v-model="itemAmount" min="0" :max="selectedItem.amount" thumb-label="always"/>
                        </v-flex>

                        <v-flex md1>
                            <v-btn style="margin-left: 20px;" @click="itemAmount = selectedItem.amount" text color="primary">{{ $t('generic.lang_all') }}</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-text>

                <v-toolbar flat>
                    <v-toolbar-title>{{$t('generic.lang_selectCourse')}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                    <v-layout>
                        <v-flex md12>
                            <v-btn outlined color="primary" v-for="(course) in courses.courses" :disabled="course === selectedCourse" :key="course.id" @click="selectCourse(course)">{{ course.name }}</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-text>

                <v-card-actions v-if="!loading">
                    <v-btn text color="error" @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
                    <v-spacer/>
                    <v-btn color="success" :disabled="selectedCourse === null || itemAmount < 1" @click="saveCourse">{{ $t('generic.lang_save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped>
    .btn-icon-vertical {
        padding: 0 !important;
    }
</style>

<script>
    import {library} from '@fortawesome/fontawesome-svg-core'

    import {
        faBell
    } from '@fortawesome/pro-light-svg-icons';

    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
    import {mapState} from "vuex";
    import {Events} from "@/plugins/events";

    library.add(
        faBell
    );

    export default {
        name: "ButtonsCourses",

        components: {
            'font-awesome-icon': FontAwesomeIcon
        },

        props: {
            posType: String,
            showButtonText: Boolean
        },

        data() {
            return {
                dialogOpen: false,
                loading: false,
                loading2: false,
                itemAmount: 0,
                selectedCourse: null
            }
        },

        computed: {
            ...mapState([
                'courses',
                'pos'
            ]),
            selectedItem() {
                if(this.posType === "retail") {
                    return this.pos.retail.selectedItem;
                }
                
                if(this.posType === "wholesaleRetail") {
                    return this.pos.wholesaleRetail.selectedItem;
                }

                if(this.posType === "gastro") {
                    if(this.pos.gastro.selectedOpenItem !== null) {
                        return this.pos.gastro.selectedOpenItem;
                    }
                    else if(this.pos.gastro.selectedOrderedItem !== null) {
                        return this.pos.gastro.selectedOrderedItem;
                    }
                }

                return null;
            }
        },

        watch: {
            dialogOpen(val) {
                if(!val) {
                    this.resetData();
                }
            },
            resetData() {
                this.loading2 = false;
            },
        },

        methods: {
            selectCourse(course) {
                this.selectedCourse = course;
            },
            resetData() {
                this.itemAmount = 0;
                this.selectedCourse = null;
                this.item = {};
            },
            closeDialog() {
                this.resetData();
                this.dialogOpen = false;
            },
            openDialog() {
              if(!(this.courses.courses && this.courses.courses.length>0)){
                Events.$emit("showSnackbar", {
                  message: this.$t('generic.lang_noCoursesFound'),
                  color: "error"
                })
                return;
              }
                if (this.selectedItem !== null) {
                    this.dialogOpen = true;
                }
            },
            saveCourse() {
                this.$store.dispatch("pos/setCourse", {
                    posType: this.posType,
                    course: this.selectedCourse
                }).then(() => {
                    this.closeDialog();
                });
            }
        }
    }
</script>
