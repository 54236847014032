<template>
  <v-card
    @click="setEvent"
    elevation="0"
    id="event-card"
    class="mx-auto pa-0 pb-2 transparent"
  >
    <!-- start: featured image -->
    <v-card-title class="pa-0">
      <!--      -->
      <div
        class="pa-0 rounded-md"
        :style="{
          position: 'relative',
          width: '100%',
          height: '160px'
        }"
      >
        <div
          class="pa-0 rounded-md v-image__image--cover overflow-hidden"
          id="event-card-image-bg"
          :style="{
            position: 'relative',
            height: '100%',
            width: '100%',
            backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.95) 100%), url('${event.image}')`
          }"
          :title="event.title"
        >
          <strong v-if="this.event.group" id="event-card-group-name">
            {{ this.event.group.name }}
          </strong>
        </div>
      </div>
    </v-card-title>
    <!-- end: featured image -->

    <v-card-text class="pa-0">
      <!-- start: event details -->
      <div
        id="event-title"
        class="pa-0 pt-0 pb-1"
      >
        {{ event.alias ? event.alias : event.title }}
      </div>
      <v-card-subtitle
        v-if="this.$vuetify.breakpoint.mdAndUp"
        class="text-left text-justify pa-0 pt-1"
        id="event-subtitle"
        v-html="event.short_description"
      >
      </v-card-subtitle>
      <!-- end: event details -->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'EventComponent',
  props: {
    event: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    setEvent() {
      this.$emit('click',this.event)
    }
  }
};
</script>

<style scoped>
#event-card-image-bg {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

#image,
#event-card {
  border-radius: 29px !important;
}

#event-card-group-name {
  color: white;
  position: absolute;
  left: 5px;
  bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#event-title {
  line-height: 1.5em;
  height: 3em;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
  word-break: normal;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: #272727;
  max-width: 100%;
  width: 100%;
}

#event-subtitle,
#event-date-range {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #515151;
  max-width: 100%;
  max-height: 80px;
}

.featured-event-badge > span.v-badge__wrapper.primary {
  background-color: transparent !important;
  border-color: transparent !important;
}

</style>

<style>
#image > .v-image__image--cover {
  background-size: initial !important;
}
</style>
