<template>
  <div
      class="ma-0 pa-0"
      v-bind:class="[this.$vuetify.breakpoint.mdAndUp ? 'mt-6' : 'mt-0 px-0']"
  >
    <!-- thumbnail -->
    <v-skeleton-loader
        v-bind:class="[this.$vuetify.breakpoint.smAndDown ? '' : 'radius-md']"
        class="mx-auto"
        id="image-loader"
        max-width="100%"
        min-height="300"
        height="300"
        type="image"
    ></v-skeleton-loader>

    <!-- title -->
    <v-skeleton-loader
        class="mt-3 ml-0 pl-0"
        max-width="100%"
        type="article"
    ></v-skeleton-loader>

    <!-- tickets -->
    <v-row class="pt-10">
      <v-col cols="12">
        <v-skeleton-loader
            class="mt-3 ml-0 pl-0"
            max-width="150"
            type="text"
        ></v-skeleton-loader>
      </v-col>

      <v-col v-for="i in 4" :key="i" cols="12">
        <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="image"
            max-height="150"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'EventDetailsLoaderComponent'
};
</script>

<style>
#image-loader > .v-skeleton-loader__image {
  height: 300px !important;
}
</style>