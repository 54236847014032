<template>
  <v-flex id="posGrid">
    <div class="d-flex flex-wrap">
      <div v-for="(gridItem, index) in gridItems" :key="gridKey(index, gridItem)"
           :style="{height: gridItemHeight, width: gridItemWidth, padding: '2px'}">
        <Itemgroup v-if="gridItem.type === 'waregroup' || gridItem.type === 'waregroup_virtual'" :gridWidth="gridItemWidthProp" :gridHeight="gridItemHeightProp"
                   @click.native="selectGridItem(gridItem,index)" :itemgroup="gridItem.gridItem" />

        <Item v-if="gridItem.type === 'item'" :gridWidth="gridItemWidthProp" :gridHeight="gridItemHeightProp"
              :item="gridItem.gridItem" :pos-type="posType" :item-name-style="getItemNameStyle"
              :item-price-style="getItemPriceStyle"/>

        <v-card v-if="gridItem.type === 'empty_placeholder'" height="100%" width="100%" elevation="1"></v-card>
      </div>
      <div v-if="currentGridLevel > 1" :style="{height: gridItemHeight, width: gridItemWidth, padding: '2px'}">
        <v-card height="100%" width="100%" elevation="1" @click="goBack" dark>
          <v-card-text class="fill-height pa-2">
            <p class="text-center h-100 d-flex align-center justify-center ma-0" :style="getConfigStyle">{{$t('generic.lang_prev')}}</p>
          </v-card-text>
        </v-card>
      </div>

      <div v-if="$vuetify.breakpoint.smAndDown">
        <br><br><br><br><br><br><br><br><br><br><br>
      </div>
    </div>
  </v-flex>
</template>

<script>
import Itemgroup from "./Itemgroup";
import Item from "./Item";

import {mapGetters, mapState} from "vuex";
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "../../config";

export default {
  name: "Grid",

  components: {
    Item,
    Itemgroup
  },

  props: {
    posType: String,
    responsive: String,
  },

  data() {
    return {
      mounted: false,
      limit: {
        page: 1,
        length: 12
      },
      refPosGridHeight: 0,
      refPosGridWidth: 0,
      selectedGridItem1: null,
      selectedGridItem2: null,
      defaultFontSize: 12,
      height: 0,//grid item height after rendering
      indexes: [],
      next:true,
      prev:true,
      isEmptyWareGroup:false,
      gridItems: [],
    }
  },
  watch: {
    height(val) {
      this.height = val
    },
    isDelivery(val){
      this.getGridItems();
    },
    posLayoutTemplate(v){
      this.getGridItems();
    }
  },
  mounted() {
    this.$nextTick(this.getBodyHeight);

    Events.$on('resetGrid', () => {
      this.goBack();
    });

    this.calculateGridWidthHeight();



    window.addEventListener("resize", this.calculateGridWidthHeight);

    window.addEventListener("orientationchange", this.calculateGridWidthHeight);

    this.$root.$on("pos." + this.posType + ".paginateDown", this.paginateDown);

    this.$root.$on("pos." + this.posType + ".paginateUp", this.paginateUp);

    this.getGridItems();
  },

  computed: {
    ...mapState([
      'pos',
      'app',
      'settings',
      'posLayoutTemplates'
    ]),
    ...mapGetters({
      'isDelivery':'pos/gastro/isDeliveryService'
    }),
    getItemNameStyle() {
      let size = (Number(this.settings.settings.layoutSettings_fontSize > 0)) ? Number(this.settings.settings.layoutSettings_fontSize) : this.defaultFontSize;
      let height = (this.height) ? (this.height - (size + size * 0.2) - 4) + 'px' : '100%';

      return {
        fontSize: size + 'px !important',
        lineHeight: (size + size * 0.2) + 'px !important',
        height: height,
      };
    },
    getItemPriceStyle() {
      let size = Number(this.settings.settings.layoutSettings_fontSize) - 2;

      return {
        fontSize: size + 'px !important',
        lineHeight: (size + size * 0.2) + 'px !important',
      };
    },
    getConfigStyle() {
      let size = Number(this.settings.settings.layoutSettings_fontSize);

      return {
        fontSize: size + 'px !important',
        lineHeight: (size + size * 0.2) + 'px !important',
      };
    },
    gridWidth() {
      if (this.posLayoutTemplate !== null)
        return this.posLayoutTemplate.gridWidth;

      return 5;
    },
    gridHeight() {
      if (this.posLayoutTemplate !== null)
        return this.posLayoutTemplate.gridHeight;

      return 4;
    },
    gridItemWidth() {
      return ((this.refPosGridWidth * 0.98) / this.gridWidth) + "px";
    },
    gridItemWidthProp() {
      return ((this.refPosGridWidth * 0.98) / this.gridWidth);
    },
    gridItemHeight() {
      return ((this.refPosGridHeight * 0.98) / this.gridHeight) + "px";
    },
    gridItemHeightProp() {
      return ((this.refPosGridHeight * 0.98) / this.gridHeight);
    },
    currentGridLevel() {
      if (this.selectedGridItem1 === null) {
        return 1;
      }

      if (this.selectedGridItem2 === null) {
        return 2;
      }

      return 3;

    },
    selectedGridItem() {
      if (this.selectedGridItem1 !== null) {
        if (this.selectedGridItem2 !== null) {
          return this.selectedGridItem2;
        }

        return this.selectedGridItem1;
      }

      return null;
    },
    posLayoutTemplate() {
      let posLayoutTemplate = null;

      if (this.settings.settings.hasOwnProperty("posRetailLayoutTemplate") || this.settings.settings.hasOwnProperty("posGastroLayoutTemplate")|| this.settings.settings.hasOwnProperty("posDeliveryLayoutTemplate")) {
        //FIND TEMPLATE
        let templateID = 0;
        if (this.posType === "retail") {
          templateID = parseInt(this.settings.settings.posRetailLayoutTemplate);
        } else if (this.posType === "wholesaleRetail") {
          templateID = parseInt(this.settings.settings.posRetailLayoutTemplate);
        } else if(this.isDelivery){
          templateID = parseInt(this.settings.settings.posDeliveryLayoutTemplate);
        } else if (this.posType === "gastro") {
          templateID = parseInt(this.settings.settings.posGastroLayoutTemplate);
        }

        if (templateID > 0) {
          let template = this.posLayoutTemplates.templates.find((template) => {
            if (template.id === templateID) {
              return true;
            }
          });

          if (template) {
            posLayoutTemplate = template;
          }
        }
      }

      return posLayoutTemplate;
    },
    posLayoutTemplateAssignments() {

      let posLayoutTemplate = null;

      if (this.posLayoutTemplate !== null)
        posLayoutTemplate = this.posLayoutTemplate.JSON;

      //CHECK IF WE HAVE ASSIGNMENTS
      if (this.selectedGridItem !== null) {
        if (this.selectedGridItem.hasOwnProperty("template")) {
          if (this.selectedGridItem.template.assignments.length === 0) {
            //CHECK IF WE ARE IN WAREGROUP
            if (this.selectedGridItem.type === "waregroup") {
              // --- AUTO FILL UP ---
              // WE ARE IN WAREGROUP BUT WAREGROUP IS EMPY
              // -> SET posLayoutTemplate as "null" so later we fill up the waregroup
              posLayoutTemplate = null;
            } else {
              posLayoutTemplate = {};
            }
          } else {
            // CHECK IF ASSIGNMENTS HAS ONLY ONE ELEMENT AND ITS NULL
            if(this.selectedGridItem.template.assignments.length === 1&&this.selectedGridItem.template.assignments[0]===null){
              //CHECK IF WE ARE IN WAREGROUP
              if (this.selectedGridItem.type === "waregroup") {
                // --- AUTO FILL UP ---
                // WE ARE IN WAREGROUP BUT WAREGROUP IS EMPY
                // -> SET posLayoutTemplate as "null" so later we fill up the waregroup
                posLayoutTemplate = null;
              } else {
                posLayoutTemplate = {};
              }
            }else{
              posLayoutTemplate = this.selectedGridItem.template.assignments;
            }
          }
        }
      }

      // CHECK IF WE HAVE KEY WITH NULL
      if(posLayoutTemplate !== null) {
        if(posLayoutTemplate.hasOwnProperty("null")) {
          // REMOVE THIS ENTRY
          delete posLayoutTemplate[null];
        }
      }
      return posLayoutTemplate;
    },
    isServerSideRendering(){
      // @TODO => NEEDS OPTIMIZATION TEMPORARY
      // USING WHEN USING API FOR POS ACTIONS IS ENABLED WE AUTOMATICALLY ENABLE THE SERVERSIDE RENDERING OF THE POS GRID
      return Number(this.$store.getters['settings/getSettingValue']('layoutSettings_useServerSideRendering')) === 1 || this.app.useAPIForPOSActions;
    }
  },

  methods: {

    paginateUp(){
      if(!this.isServerSideRendering || (!this.posLayoutTemplate)){
        if(this.limit.page !== 1) {
          this.limit.page--;
          this.getGridItems();
        }
      }else {
        if(this.prev||this.isEmptyWareGroup){
          if(this.limit.page !== 1) {
            this.limit.page--;
            this.getGridItems();
          }
        }
      }
    },

    paginateDown(){
      if(!this.isServerSideRendering || (!this.posLayoutTemplate)){
        this.limit.page++;
        this.getGridItems();
      }else {
        if(this.next||this.isEmptyWareGroup){
          this.limit.page++;
          this.getGridItems();
        }
      }
    },
    calculateGridWidthHeight(){
      this.refPosGridHeight = document.documentElement.clientHeight - 140;
      this.refPosGridWidth = this.getGridOffsetWidth();
    },
    async getGridItems() {
      this.gridItems = [];
      let availableGridItems = [];
      try {


        //DEFAULT: USE ALL ITEMGROUPS
        //CHECK IF WE HAVE TEMPLATE OR NOT
        if (this.posLayoutTemplateAssignments === null && (this.posLayoutTemplate===null||!this.isServerSideRendering)) {

          if (this.selectedGridItem !== null) {

            // availableGridItems = this.itemsByItemgroups[this.selectedGridItem.gridItem.id];

            let sortFunction = "items/paginateActiveItemsByItemgroupSortByName";

            if(this.settings.settings.layoutSettings_itemgroupsSorting == "asc_alpha") {
              sortFunction = "items/paginateActiveItemsByItemgroupSortByNameAsc";
            } else if (this.settings.settings.layoutSettings_itemgroupsSorting == "desc_alpha") {
              sortFunction = "items/paginateActiveItemsByItemgroupSortByNameDesc";
            } else if (this.settings.settings.layoutSettings_itemgroupsSorting == "asc_price") {
              sortFunction = "items/paginateActiveItemsByItemgroupSortByPriceAsc";
            } else if (this.settings.settings.layoutSettings_itemgroupsSorting == "desc_price") {
              sortFunction = "items/paginateActiveItemsByItemgroupSortByPriceDesc";
            }

            let items = await this.$store.dispatch(sortFunction, {
              itemgroupId: this.selectedGridItem.gridItem.id,
              limit: (this.gridWidth * this.gridHeight),
              page: this.limit.page
            });

            items.forEach((item) => {

              // excluding variations from autofill
              if(item.isVariation){
                return;
              }

              availableGridItems.push({
                type: "item",
                gridItem: {
                  id: item.id,
                  name: item.name,
                  faIcon: item.faIcon,
                  backgroundColor: item.backgroundColor,
                  ean: item.ean,
                  sellPrice: item.sellPrice,
                  taxValue: item.taxValue,
                  variationSize: item.variationSize,
                  variationColor: item.variationColor,
                  productImage : item.productImage,
                  deliveryPrice : item.deliveryPrice,
                  pickupPrice : item.takeAwayPrice,
                }
              });
            });

            this.gridItems = availableGridItems;
            return;
          } else {
            //SHOW WAREGROUPS
            let itemgroups = await this.$store.dispatch("itemgroups/getItemgroups");            

            itemgroups.forEach((itemgroup) => {
              availableGridItems.push({
                type: "waregroup",
                gridItem: {
                  id: itemgroup.id,
                  name: itemgroup.name,
                  faIcon: itemgroup.faIcon,
                  itemgroupImage: itemgroup.itemgroupImage,
                  backgroundColor: itemgroup.backgroundColor
                }
              });
            });
          }

          // availableGridItems = _.orderBy(availableGridItems, 'gridItem.name');
        } else {

          if(this.isServerSideRendering){

            if(!this.isEmptyWareGroup){
              this.isEmptyWareGroup=false;
              await this.getServerSideLayout().then(res=>{
                if(res.data.success){
                  availableGridItems = res.data.grid;
                  this.prev = res.data.prev;
                  this.next = res.data.next;
                  if(this.selectedGridItem !== null && this.selectedGridItem.type==='waregroup' && Array.isArray(availableGridItems) && availableGridItems.length === 0){
                    this.isEmptyWareGroup=true;
                    this.getGridItems();
                  }
                }else{
                  this.$swal({
                    title: this.$t('generic.lang_errorOccurred'),
                    text: this.$t('generic.lang_failedLoadingLayoutData'),
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_retry'),
                    icon: "warning",
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                      this.getGridItems();
                    },
                    allowOutsideClick: () => !this.$swal.isLoading(),
                  });
                }
              }).catch(err=>{
                this.$swal({
                  title: this.$t('generic.lang_errorOccurred'),
                  text: this.$t('generic.lang_failedLoadingLayoutData'),
                  cancelButtonText: this.$t('generic.lang_cancel'),
                  confirmButtonText: this.$t('generic.lang_retry'),
                  icon: "warning",
                  showLoaderOnConfirm: true,
                  preConfirm: () => {
                    this.getGridItems();
                  },
                  allowOutsideClick: () => !this.$swal.isLoading(),
                });
              })
            }
          }else{
            //GET LAST ITEM OF OBJECT(ASSIGNMENTS)
            let lastPosition = Object.keys(this.posLayoutTemplateAssignments)[Object.keys(this.posLayoutTemplateAssignments).length - 1];
            lastPosition = Number(lastPosition)
            // SETTING UP THE START AND THE END POSITION FOR EACH PAGE TO AVOID LOADING ALL THE DATA
            let lastPositionOfPage = this.currentGridLevel===1?(this.gridWidth*this.gridHeight*this.limit.page):((this.gridWidth*this.gridHeight*this.limit.page) -1);
            let firstPositionOfPage = ((this.limit.page-1)*(this.gridWidth*this.gridHeight))+1;

            for (let position = firstPositionOfPage; position <= lastPosition && position<= lastPositionOfPage; position++) {
              if (!this.posLayoutTemplateAssignments.hasOwnProperty(position.toString())) {
                //EMPTY WAREGROUP
                availableGridItems.push({
                  type: "empty_placeholder"
                });
              } else {
                let assignment = this.posLayoutTemplateAssignments[position];

                // CHECK IF ASSIGNMENT IS NULL
                if (assignment === null) {
                  availableGridItems.push({
                    type: "empty_placeholder"
                  });

                  continue;
                }

                if (assignment.type === "waregroup") {
                  //FIND ORIGIN ITEMGROUP
                  let itemgroupFind = await this.$store.dispatch("itemgroups/getItemgroupByID", assignment.id);

                  if (itemgroupFind) {
                    if(!itemgroupFind.show) {
                      availableGridItems.push({
                        type: "empty_placeholder"
                      });
                    }
                    else {
                      let itemgroup = Object.assign({}, itemgroupFind);

                      if (assignment.color !== null) {
                        itemgroup.backgroundColor = assignment.color;
                      }

                      if (assignment.icon != null) {
                        itemgroup.faIcon = assignment.icon;
                      }

                      //PUSH ITEMGROUP
                      availableGridItems.push({
                        type: "waregroup",
                        gridItem: {
                          id: itemgroup.id,
                          name: itemgroup.name,
                          faIcon: itemgroup.faIcon,
                          itemgroupImage: itemgroup.itemgroupImage,
                          backgroundColor: itemgroup.backgroundColor
                        },
                        template: {
                          level: assignment.level,
                          assignments: assignment.assignments,
                          position: assignment.position
                        }
                      });
                    }
                  } else {
                    availableGridItems.push({
                      type: "empty_placeholder"
                    });
                  }
                }

                if (assignment.type === "waregroup_virtual") {
                  //PUSH ITEMGROUP
                  availableGridItems.push({
                    type: "waregroup_virtual",
                    gridItem: {
                      name: assignment.name,
                      faIcon: (assignment.icon !== null ? assignment.icon : ""),
                      itemgroupImage: (assignment.itemgroupImage !== null ? assignment.itemgroupImage : ""),
                      backgroundColor: (assignment.color !== null ? assignment.color : ""),
                    },
                    template: {
                      level: assignment.level,
                      assignments: assignment.assignments,
                      position: assignment.position
                    }
                  });
                }

                if (assignment.type === "item") {
                  //FIND ORIGIN ITEM
                  let itemToFind = await this.$store.dispatch("items/getItemByID", assignment.id);

                  if (itemToFind) {
                    if (itemToFind.active === 0) {
                      availableGridItems.push({
                        type: "empty_placeholder"
                      });
                    } else {
                      let item = Object.assign({}, itemToFind);

                      if (assignment.color !== null) {
                        item.backgroundColor = assignment.color;
                      }

                      if (assignment.icon != null) {
                        item.faIcon = assignment.icon;
                      }

                      //PUSH
                      availableGridItems.push({
                        type: "item",
                        gridItem: {
                          id: item.id,
                          name: item.name,
                          faIcon: item.faIcon,
                          backgroundColor: item.backgroundColor,
                          ean: item.ean,
                          sellPrice: item.sellPrice,
                          taxValue: item.taxValue,
                          variationSize: item.variationSize,
                          variationColor: item.variationColor,
                          productImage: item.productImage
                        },
                        template: {
                          level: assignment.level,
                          assignments: assignment.assignments,
                          position: assignment.position
                        }
                      });
                    }
                  } else {
                    availableGridItems.push({
                      type: "empty_placeholder"
                    });
                  }
                }
              }
            }
          }

        }

        // NOW PAGINATE
        let counter = 0;
        let counter2 = 1;
        let gridItems = [];
        let length = this.gridWidth * this.gridHeight;

        //REMOVE ONE GRID ITEM, FOR BACK BUTTON
        // ONLY WHEN THERE IS NO TEMPLATE
        if (this.currentGridLevel > 1) {
          length--;
        }
        if(this.isServerSideRendering && this.posLayoutTemplate){
          gridItems = availableGridItems;
        }else if(this.posLayoutTemplateAssignments===null){
          if (parseInt(this.responsive) === 0) {
            availableGridItems.forEach((gridItem) => {
              if ((counter >= ((this.limit.page - 1) * length)) && (counter2 <= length)) {
                gridItems.push(gridItem);

                counter2++;
              }
              counter++;
            });
          } else {
            availableGridItems.forEach((gridItem) => {
              gridItems.push(gridItem);
            });
          }
        }else{
          gridItems = availableGridItems;
        }
        this.gridItems = gridItems;
      }
      catch(e) {
        console.log(e);
      }
    },
    async getServerSideLayout(){
      return this.axios.post(ENDPOINTS.SETTINGS.POSLAYOUT.TEMPLATE.RENDER,{
        templId:this.posLayoutTemplate.id,
        pageId:this.limit.page-1,
        position:this.currentGridLevel===1 || this.indexes,
        mobile:0,
      })
    },
    getBodyHeight() {
      this.height = this.gridItemHeightProp - 8;
    },
    gridKey(index, gridItem) {
      if (gridItem.type === "empty_placeholder")
        return gridItem.type + '_' + index;

      return gridItem.type + '_' + gridItem.gridItem.id + '_' + index;
    },
    getGridOffsetWidth() {
      let documentElement = document.getElementById("posGrid");

      if (documentElement === undefined || documentElement === null)
        return 0;

      return documentElement.offsetWidth;
    },
    selectGridItem(gridItem,index) {
      if (gridItem.type !== "item") {
        this.limit.page = 1;

        if (gridItem.hasOwnProperty("template")) {
          if (gridItem.template.level === 1) {
            this.selectedGridItem1 = gridItem;
          }

          if (gridItem.template.level === 2) {
            this.selectedGridItem2 = gridItem;
          }
        } else {
          this.selectedGridItem1 = gridItem;
        }
      }
      this.indexes.push(Number(index));
      this.getGridItems();
    },
    goBack() {
      this.indexes.splice(this.indexes.length-1,1);
      //RESET PAGINATION
      this.limit.page = 1;

      if (this.currentGridLevel === 2) {
        this.selectedGridItem1 = null;
      }

      if (this.currentGridLevel === 3) {
        this.selectedGridItem2 = null;
      }


      this.isEmptyWareGroup=false;
      this.gridItems = [];
      this.getGridItems();
    },
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.calculateGridWidthHeight);
    window.removeEventListener('resize', this.calculateGridWidthHeight);
    this.$root.$off("pos." + this.posType + ".paginateDown", this.paginateDown);
    this.$root.$off("pos." + this.posType + ".paginateUp", this.paginateUp);
    this.gridItems = [];
    delete this.gridItems;
  },
  destroyed() {
    window.removeEventListener('orientationchange', this.calculateGridWidthHeight);
    window.removeEventListener('resize', this.calculateGridWidthHeight);

    this.$root.$off("pos." + this.posType + ".paginateDown", this.paginateDown);
    this.$root.$off("pos." + this.posType + ".paginateUp", this.paginateUp);

  }
}
</script>
