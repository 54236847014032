<template>
  <v-dialog
    v-model="showDialog"
    max-width="450px"
    persistent
    scrollable
  >
    <v-card>
      <v-toolbar
        :color="this.$vuetify.breakpoint.smAndDown ? '#FF6969' : ''"
        dense
        flat
      >
        <v-toolbar-title
          :class="this.$vuetify.breakpoint.smAndDown ? 'text-white' : ''"
          >{{ $t('generic.lang_discount') }}
        </v-toolbar-title>

        <v-spacer />

        <v-btn
          :class="this.$vuetify.breakpoint.smAndDown ? 'text-white' : ''"
          icon
          @click="closeDialog"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0" />
      <v-card-text class="pa-0">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :max="this.maxAmountOfDiscount"
                :min="0"
                v-model="discountValue"
                :label="$t('generic.lang_discount')"
                aria-autocomplete="none"
                filled
                outlined
                suffix="%"
                type="number"
                @keyup.enter="discount()"
                :rules="[(v) => !!v, (v) => v && v >= 0 && v <= this.maxAmountOfDiscount]"
              />
              <NumpadComponent
                ref="numpad"
                show-discount-buttons
                :discountsMatrix="discountsMatrix"
                @resetField="numpadResetField"
                @updateValue="numpadUpdateValue"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions
        :class="this.$vuetify.breakpoint.smAndDown ? 'card-footer pa-1' : ''"
      >
        <v-container class="pa-1">
          <v-row no-gutters>
            <v-col cols="6">
              <v-btn
                :color="
                  this.$vuetify.breakpoint.smAndDown ? 'primary' : '#FF6969'
                "
                :elevation="this.$vuetify.breakpoint.smAndDown ? 0 : ''"
                :disabled="
                  discountValue.length === 0 ||
                  parseInt(discountValue) < 0 ||
                  parseInt(discountValue) > maxAmountOfDiscount
                "
                :rounded="this.$vuetify.breakpoint.smAndDown"
                :small="this.$vuetify.breakpoint.smAndDown"
                :text="this.$vuetify.breakpoint.smAndDown"
                class="ma-0"
                @click="discountInvoice()"
              >
                {{ $t('generic.lang_overall_discount') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="item"
              align="end"
              cols="6"
            >
              <v-btn
                :color="
                  this.$vuetify.breakpoint.smAndDown ? '#FF6969' : 'primary'
                "
                :disabled="!this.canApplyDiscount"
                :elevation="this.$vuetify.breakpoint.smAndDown ? '0' : ''"
                :rounded="this.$vuetify.breakpoint.smAndDown"
                :small="this.$vuetify.breakpoint.smAndDown"
                class="ma-0"
                v-bind:class="
                  this.$vuetify.breakpoint.smAndDown ? 'text-white' : ''
                "
                @click="discount"
              >
                {{ $t('generic.lang_apply') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
      <div
        id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard"
        style="z-index: 299 !important"
      >
        <vue-touch-keyboard
          v-if="touchKeyboard.visible"
          id="onScreenKeyboard"
          :accept="hideTouchKeyboard"
          :cancel="hideTouchKeyboard"
          :defaultKeySet="touchKeyboard.keySet"
          :input="touchKeyboard.input"
          :layout="touchKeyboard.layout"
          :options="touchKeyboard.options"
          class="internalWidthExpanded"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import KeyboardMixIns from "../../../mixins/KeyboardMixIns";
import { mapState } from 'vuex';
import Numpad from '@/components/pos/Numpad';
import NumpadComponent from '@/components/pos/NumpadComponent';
import { roundNumber } from '@/plugins/round';
import { format } from 'mathjs';

export default {
  name: 'ButtonsDiscountDialog',

  components: {
    NumpadComponent,
    Numpad,
  },
  mixins: [KeyboardMixIns],
  props: {
    showDialog: Boolean,
    posType: String,
    item: Object,
  },

  data() {
    return {
      loading: false,
      discountValue: '',
      discountPrice: '',
    };
  },

  watch: {
    showDialog(val) {
      if (this.item !== null && this.item !== undefined) {
        this.itemAmount = this.item.amount;
        if (this.item.discount > 0) {
          this.discountValue = this.item.discount;
        } else {
          this.discountValue = '';
        }
      } else {
        this.discountValue = '';
      }

      this.dialogOpen = val;

      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (val) {
        this.$root.$emit('barcodeScanner.pause');
      } else {
        this.$root.$emit('barcodeScanner.unpause');
      }
    },
    discountValue() {
      if (this.discountValue > this.maxAmountOfDiscount) {
        this.discountValue = this.maxAmountOfDiscount;
      }

      if (this.discountValue < 0) {
        this.discountValue = 0;
      }

      if (
        this.discountValue >= 1 &&
        this.item !== undefined &&
        this.item !== null
      ) {
        this.calculatePrice();
      }
    },
  },

  computed: {
    ...mapState(['settings']),
    canApplyDiscount() {
      return (
        this.discountValue?.length > 0 &&
        parseInt(this.discountValue) >= 0 &&
        parseInt(this.discountValue) <= this.maxAmountOfDiscount
      );
    },
    discountsMatrix(){
      return this.settings.settings && this.settings.settings.discountDialogMatrix ? JSON.parse(this.settings.settings.discountDialogMatrix) : {};
    },
    maxAmountOfDiscount() {
      let max = Number(this.settings.settings.maxDiscount);
      return max || 100;
    },
  },

  methods: {
    calculatePrice() {
      this.discountPrice =
        ((this.item.sellPrice * ((100 - this.discountValue) / 100) +
          Number.EPSILON) *
          100) /
        100;
      this.discountPrice = format(this.discountPrice, { precision: 14 });
      this.discountPrice = roundNumber(this.discountPrice, 2);
    },
    closeDialog() {
      this.$refs.numpad.resetField();
      this.numpadResetField();
      this.$emit('closeDialog');
    },
    discountPrice2() {
      this.$store
        .dispatch('pos/priceDiscount', {
          posType: this.posType,
          discountPrice: parseFloat(this.discountPrice.replace(',', '.')),
          amount: this.itemAmount,
        })
        .then(() => {
          this.closeDialog();
        });
    },
    discount() {
      if (!this.canApplyDiscount) return;
      this.$store
        .dispatch('pos/discount', {
          posType: this.posType,
          discount: parseFloat(this.discountValue),
          amount: this.itemAmount,
        })
        .then(() => {
          this.closeDialog();
        });
    },
    discountInvoice() {
      this.$store
        .dispatch('pos/discountInvoice', {
          posType: this.posType,
          discount: parseFloat(this.discountValue),
        })
        .then(() => {
          this.closeDialog();
        });
    },
    numpadUpdateValue(value) {
      this.discountValue = value;
    },
    numpadResetField() {
      this.discountValue = '';
    },
  },
};
</script>
