var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex justify-space-between white pa-2 radius-sm",attrs:{"role":"button"},on:{"click":function($event){return _vm.toggle()}}},[_c('div',{staticClass:"d-flex align-center"},[_c('font-awesome-icon',{staticClass:"primary--text d-inline",attrs:{"icon":['fal','tags'],"size":"2x"}}),_c('span',{staticClass:"font-weight-bold ml-4 d-inline"},[_vm._v(_vm._s(_vm.$t('generic.lang_oneMoreStep')))])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('v-btn',{attrs:{"rounded":"","text":""}},[_c('font-awesome-icon',{staticClass:"primary--text d-inline",attrs:{"icon":['fal',
                    _vm.expanded
                      ? 'chevron-up'
                      : 'chevron-down'],"size":"2x"}})],1)],1)]),_c('v-expand-transition',[(_vm.expanded)?_c('div',{staticClass:"white radius-sm"},_vm._l((_vm.sortedAttributes),function(attribute,index){return _c('v-card',{key:attribute.uuid,staticClass:"white mx-0 pa-2 mx-0 px-4",attrs:{"flat":""}},[_c('v-card-title',[_c('font-awesome-icon',{staticClass:"primary--text d-inline",attrs:{"icon":['fal','tag']}}),_c('span',{staticClass:"font-weight-bold ml-4 d-inline"},[_vm._v(_vm._s(attribute.name)+" ")]),(attribute.required)?_c('span',{staticClass:"error--text pl-3"},[_vm._v("("+_vm._s(_vm.$t('generic.lang_required'))+")")]):_c('span',{staticClass:"pl-3"},[_vm._v("("+_vm._s(_vm.$t('generic.lang_optional'))+")")])],1),(attribute.type === 'options')?_c('v-card-text',[_c('v-radio-group',{model:{value:(_vm.filledAttributes[attribute.uuid]),callback:function ($$v) {_vm.$set(_vm.filledAttributes, attribute.uuid, $$v)},expression:"filledAttributes[attribute.uuid]"}},[_c('v-row',{staticClass:"mx-0"},_vm._l((attribute.attributes),function(option,index){return _c('v-col',{key:option + '-' + index,attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-radio',{attrs:{"label":option,"value":option}})],1)}),1)],1)],1):_c('v-card-text',[_c('v-text-field',{staticClass:"pt-0",attrs:{"outlined":"","dense":"","rules":[
              v =>
                (attribute.required ? !!v : true) ||
                _vm.$t('generic.lang_requiredField')
            ],"type":attribute.type},model:{value:(_vm.filledAttributes[attribute.uuid]),callback:function ($$v) {_vm.$set(_vm.filledAttributes, attribute.uuid, $$v)},expression:"filledAttributes[attribute.uuid]"}})],1),(_vm.attributes.length - 1 !== index)?_c('v-divider',{staticClass:"ma-0"}):_vm._e()],1)}),1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }