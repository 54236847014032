<template>
    <div>
        <button @click="showDialog = true" class="btn-icon-vertical btn-square  br-tr btn-transition btn btn-outline-primary">
            <i class="btn-icon-wrapper" style="display:block !important;">
                <font-awesome-icon :icon="['fal', 'gifts']"/>
            </i><span v-if="showButtonText">{{ $t('erp.lang_voucher') }}</span>
        </button>

        <ButtonsVoucherDialog :pos-type="posType" :show-dialog="showDialog" @closeDialog="showDialog = false"></ButtonsVoucherDialog>
    </div>
</template>

<script>
    import {library} from '@fortawesome/fontawesome-svg-core'
    import {
        faGifts
    } from '@fortawesome/pro-light-svg-icons';
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
    import ButtonsVoucherDialog from "./ButtonsVoucherDialog";
    library.add(
        faGifts
    );

    export default {
        name: "ButtonsVoucher",

        props: {
            posType: String,
            showButtonText: Boolean
        },

        components: {
            ButtonsVoucherDialog,
            'font-awesome-icon': FontAwesomeIcon
        },

        data() {
            return {
                showDialog: false
            }
        }
    }
</script>

<style scoped>
@media (min-width: 900px) {
  .btn-icon-vertical {
    padding: 0 !important;
  }
}

    @media only screen and (max-height: 850px) {
        .btn-icon-vertical .btn-icon-wrapper {
            font-size: 150% !important;
        }
    }
</style>