<template>
  <div>
    <template v-if="showButton">
      <button v-show="!this.hideButton"
              class="btn-icon-vertical btn-square  br-tr btn-transition btn btn-outline-primary"
              @click="openDialog">
        <i class="btn-icon-wrapper mdi mdi-call-split" style="display:block !important;">

        </i>

        <span v-if="showButtonText">{{ $t('generic.lang_tableSplit') }}</span>
      </button>

      <v-dialog v-model="dialogOpen" max-width="500px" scrollable persistent>
        <v-card>
          <v-toolbar :color="this.$vuetify.breakpoint.smAndDown? '#FF6969' : 'transparent'" dense flat>
            <v-toolbar-title :class="this.$vuetify.breakpoint.smAndDown? 'text-white' : 'text-black'">{{ $t('generic.lang_tableSplit') }}
            </v-toolbar-title>

            <v-spacer/>

            <v-btn :class="this.$vuetify.breakpoint.smAndDown? 'text-white' : 'text-black'" icon
                   @click="dialogOpen = false">
              <v-icon>close</v-icon>
            </v-btn>
            <template v-slot:extension>
              <v-progress-linear v-if="loading" color="#ffb3bd" indeterminate/>
            </template>
          </v-toolbar>
          <v-divider class="ma-0"/>
          <v-card-text class="pa-1" style="height: 600px;">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-card class="pa-0" flat>
                    <v-card-title>{{ $t('generic.lang_table') }}</v-card-title>
                    <v-card-subtitle>{{ $t('erp.lang_ware_total') }}:
                      {{ getTotalSumOfArray(filteredInvoiceItems) | currency }}
                    </v-card-subtitle>
                    <v-list :color="areInvoiceItemsEmpty?'':'primary'"  v-if="filteredInvoiceItems.length > 0" class="pa-0 mb-2"
                            elevation="1" outlined>
                      <v-list-item :disabled="areInvoiceItemsEmpty" @click="selectAllInvoiceItems">
                        <v-list-item-title class="font-weight-bold text-center" :class="areInvoiceItemsEmpty?'':'white--text'">{{$t('generic.lang_selectAll')}}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                    <v-list v-if="filteredInvoiceItems.length > 0" class="pa-0"
                            elevation="1" outlined>
                      <v-list-item v-for="(invoiceItem, index) in filteredInvoiceItems"
                                   :key="invoiceItem.randomPositionID"
                                   :disabled="invoiceItem.isVoid || invoiceItem.ean.includes('V$') || invoiceItem.ean.includes('MYWORLD$') || invoiceItem.ean.includes('ZMYLE$') || invoiceItem.ean.includes('GF$') || invoiceItem.amount <= 0"
                                   :style="{backgroundColor: (invoiceItem.isVoid ? '#ffb3bd' : '')}"
                                   class="border-bottom"
                                   two-line
                                   @click="selectInvoiceItem(invoiceItem, index)">
                        <v-list-item-content>
                          <v-list-item-title class="wrap-text">{{ invoiceItem.amount }}x {{ invoiceItem.name }}</v-list-item-title>
                          <v-list-item-subtitle>{{ itemSellPrice(invoiceItem) | currency }}</v-list-item-subtitle>

                          <v-col cols="12" class="px-0"
                                 v-if="Array.isArray(invoiceItem.selectedExtras) && invoiceItem.selectedExtras.length > 0">
                            <div v-if="true">
                              <div class="text-wrap" style="font-size: 12px "
                                   v-for="(extra,i) in invoiceItem.selectedExtras"
                                   :key="extra.name+i">
                                &#9656; {{ extra.amount }}x {{ extra.name }} {{ extra.sellPrice | currency }}
                              </div>
                            </div>
                          </v-col>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>


                <v-col cols="6">
                  <v-card class="pa-0" flat>
                    <v-card-title>{{ $t('generic.lang_split_table') }}</v-card-title>
                    <v-card-subtitle>{{ $t('erp.lang_ware_total') }}: {{ getTotalSumOfArray(splitItems) | currency }}
                    </v-card-subtitle>
                    <v-list  :color="areSplitItemsEmpty?'':'primary'" v-if="filteredInvoiceItems.length > 0" class="pa-0 mb-2"
                            elevation="1" outlined>
                      <v-list-item :disabled="areSplitItemsEmpty"  @click="selectAllSplitItems">
                        <v-list-item-title class="font-weight-bold text-center" :class="areSplitItemsEmpty?'':'white--text'">{{$t('generic.lang_unselectAll')}}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                    <v-list v-if="splitItems.length > 0" class="pa-0" elevation="1">
                      <v-list-item v-for="(splitItem, index) in splitItems" :key="splitItem.randomPositionID"
                                   :disabled="splitItem.isVoid || splitItem.amount <= 0"
                                   :style="{backgroundColor: (splitItem.isVoid ? '#ffb3bd' : '')}" class="border-bottom"
                                   two-line @click="selectSplitItem(splitItem, index)">
                        <v-list-item-content>
                          <v-list-item-title class="wrap-text">{{ splitItem.amount }}x {{ splitItem.name }}</v-list-item-title>
                          <v-list-item-subtitle>{{ itemSellPrice(splitItem) | currency }}</v-list-item-subtitle>

                          <v-col cols="12" class="px-0"
                                 v-if="splitItem.amount > 0 && Array.isArray(splitItem.selectedExtras) && splitItem.selectedExtras.length > 0">
                            <div v-if="true">
                              <div class="text-wrap" style="font-size: 12px "
                                   v-for="(extra,i) in splitItem.selectedExtras"
                                   :key="extra.name+i">
                                &#9656; {{ extra.amount }}x {{ extra.name }} {{ extra.sellPrice | currency }}
                              </div>
                            </div>
                          </v-col>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions v-if="!loading"
                          :class="this.$vuetify.breakpoint.smAndDown? 'card-footer pt-1 pb-1 pl-0 pr-0' : ''">
            <v-btn :small="this.$vuetify.breakpoint.smAndDown" color="error" text @click="dialogOpen = false">
              {{ $t('generic.lang_cancel') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="(splitItems.filter((elt) => elt.amount > 0).length<1) || splitLoading"
                   :elevation="this.$vuetify.breakpoint.smAndDown? '0' : '3'" :loading="splitLoading"
                   :x-small="this.$vuetify.breakpoint.smAndDown" color="success"
                   @click="showCashoutDialog = true;invoiceSplit=true">{{ $t('accounting.lang_gastro_Rechnungssplit') }}
            </v-btn>
            <v-btn :class="this.$vuetify.breakpoint.smAndDown? 'text-white' : 'text-white'"
                   :color="this.$vuetify.breakpoint.smAndDown? '#FF6969' : 'success'"
                   :disabled="(splitItems.filter((elt) => elt.amount > 0).length<1) || showTablesDialog || splitLoading"
                   :elevation="this.$vuetify.breakpoint.smAndDown? '0' : '3'"
                   :loading="showTablesDialog || splitLoading"
                   :x-small="this.$vuetify.breakpoint.smAndDown"
                   @click="showTablesDialog = true">{{ $t('generic.lang_gastro_Tischsplit') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ButtonsTableSplitTablesDialog :show-dialog="showTablesDialog" @closeDialog="showTablesDialog = false"
                                     @tablePartySelected="tablePartySelected"></ButtonsTableSplitTablesDialog>

      <v-dialog v-if="this.$vuetify.breakpoint.smAndDown" v-model="showCashoutDialog" fullscreen scrollable>
        <v-card>
          <v-toolbar v-if="$vuetify.breakpoint.smAndDown" color="#FF6969" dense flat>
            <v-toolbar-title class="text-white">{{ $t('generic.lang_cashier_checkout') }}</v-toolbar-title>

            <v-spacer/>

            <v-btn class="text-white" icon @click="showCashoutDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <mobile-cashout v-if="this.$vuetify.breakpoint.smAndDown && showCashoutDialog" v-model="showCashoutDialog"
                            :colors="this.colors"
                            :invoice-items-overwrite="splitItems.filter((elt) => elt.amount > 0)"
                            :new-order-array="invoiceItems.filter((elt) => elt.amount > 0)"
                            :pos-type="this.posType"
                            dont-show-back
                            :split-redirect="this.filteredInvoiceItems.filter((elt) => elt.amount > 0).length<1"
                            @finishInvoiceSplit="redirect"
                            @closeDialog="closeDialog"/>
          </v-card-text>
        </v-card>
      </v-dialog>

      <CashoutDialog v-if="!this.$vuetify.breakpoint.smAndDown && showCashoutDialog" open-on-mounted
                     :invoice-items-overwrite="splitItems.filter((elt) => elt.amount > 0)"
                     :split="invoiceSplit&&filteredInvoiceItems.filter((elt) => elt.amount > 0).length>0"
                     :new-order-array="invoiceItems.filter((elt) => elt.amount > 0)" :pos-type="posType"
                     :show-dialog="showCashoutDialog" @closeDialog="closeDialog"></CashoutDialog>

      <!-- Error Message -->
      <v-dialog :value="errorMessage.length > 0" persistent max-width="600">
        <v-card>
          <v-card-text class="text-center" style="font-size: 20px; padding-top: 25px;">{{ errorMessage }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="closeErrorDialog" text>{{ $t('generic.lang_understand') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<style scoped>

.wrap-text {
  white-space: normal;
}

.btn-icon-vertical {
  padding: 0 !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}

@media (min-width: 900px) {
  .btn-icon-vertical {
    padding: 0 !important;
  }
}
</style>

<script>
import {library} from '@fortawesome/fontawesome-svg-core'

import {faLineColumns} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {mapGetters, mapState} from "vuex";
import CashoutDialog from "../cashout/CashoutDialog";
import MobileCashout from "@/components/pos/mobile/wedgets/MobileCashout";
import ButtonsTableSplitTablesDialog from "./ButtonsTableSplitTablesDialog";
import {ENDPOINTS} from "@/config";
import {mergeInvoiceArray} from "@/plugins/pos/mergeInvoiceArrays";
import {Events} from "../../../plugins/events";

library.add(
    faLineColumns
);

export default {
  name: "ButtonsTableSplit",

  components: {
    ButtonsTableSplitTablesDialog,
    CashoutDialog,
    MobileCashout,
    'font-awesome-icon': FontAwesomeIcon
  },

  props: {
    posType: String,
    showButtonText: Boolean,
    hideButton: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      colors: {
        primary: "#FF6969"
      },
      loading: false,
      splitLoading: false,
      dialogOpen: false,
      showTablesDialog: false,
      showCashoutDialog: false,
      invoiceItems: [],
      splitItems: [],
      errorMessage: "",
      errorRedirect: false,
      invoiceSplit: false,
    }
  },

  computed: {
    ...mapState([
      'pos',
      "deposit"
    ]),
    ...mapGetters({
      orderedItems: 'pos/gastro/orderedItems',
      currentParty: "pos/gastro/currentParty"
    }),
    showButton() {
      return !(this.currentParty && this.currentParty.hasOwnProperty('is_payed') && this.currentParty.is_payed);
    },
    filteredInvoiceItems() {
      return this.invoiceItems.filter((invoiceItem) => {
        return true;
      });
    },
    areInvoiceItemsEmpty() {
      return this.invoiceItems.filter((invoiceItem) => {
          return invoiceItem.amount>0;
      })?.length===0;
    },
    areSplitItemsEmpty() {
      return this.splitItems.filter((splitItem) => {
          return splitItem.amount>0;
      })?.length===0;
    },
    computedSplitItems() {
      return this.splitItems.filter((item) => {
        return item.amount > 0
      });
    }
  },

  watch: {
    showCashoutDialog(val) {
      if (val === false)
        this.getGastroTableFromAPI(this.pos.gastro.table.name)
    },
    dialogOpen(val) {
      if (val) {
        this.invoiceItems = this._.cloneDeep(this.orderedItems);
        let copy = this._.cloneDeep(this.orderedItems);

        this.splitItems = copy.map((elt) => {
          elt.amount = 0;
          return elt;
        });
      } else {
        this.invoiceItems = [];
        this.splitItems = [];
      }
    }
  },

  methods: {
    selectAllInvoiceItems(){
      this.invoiceItems.forEach((item,index)=>{
        if(item.amount>0){
          this.splitItems[index].amount+=item.amount;
          item.amount=0;
        }
      })
    },
    selectAllSplitItems(){
      this.splitItems.forEach((item,index)=>{
        if(item.amount>0){
          this.invoiceItems[index].amount+=item.amount;
          item.amount=0;
        }
      })
    },
    closeDialog() {
      if (this.$vuetify.breakpoint.smAndDown && this.filteredInvoiceItems.filter((elt) => elt.amount > 0).length < 1) {
        this.$router.go();
      }
      this.getGastroTableFromAPI(this.pos.gastro.table.name);
      this.showCashoutDialog = false;
      this.invoiceSplit = false;
    },
    getGastroTableFromAPI(tablename) {
      this.loading = true;
      this.invoiceItems = [];
      this.splitItems = [];
      this.axios.post(ENDPOINTS.POS.GASTRO.ORDERS.GET, {
        tableName: ((tablename === null) ? 0 : tablename)
      }).then((res) => {
        if (res.data.success) {
          if (this.pos.gastro.party.name && res.data.orders[this.pos.gastro.party.name] !== undefined) {
            this.invoiceItems = this._.cloneDeep(res.data.orders[this.pos.gastro.party.name].filter((elt) => elt.amount > 0));
            this.$store.commit("pos/gastro/setOrderedItems", {
              partyName: this.pos.gastro.party.name,
              orders: res.data.orders[this.pos.gastro.party.name]
            });


            let copy = this._.cloneDeep(res.data.orders[this.pos.gastro.party.name].filter((elt) => elt.amount > 0));
            this.splitItems = copy.map((elt) => {
              elt.amount = 0;
              return elt;
            });
          } else {
            this.invoiceItems = [];
            this.splitItems = [];
          }
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.loading = false;
      });
    },
    /**
     * @description open split dialog
     */
    async openDialog() {

      //check if we have open items
      if (this.pos.gastro.openItems && this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name) && this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
        Events.$emit("showSnackbar", {
          message: this.$t('erp.lang_errorOrderFirst'),
          color: "warning"
        });
      } else {
        //CHECK IF TABLE IS UNDEFINED
        if (this.pos.gastro.table === undefined)
          return;

        //CHECK IF WE ARE ON TRESENVERKAUF
        if (this.pos.gastro.table.name === 0)
          return;

        this.dialogOpen = true;
      }
    },
    selectInvoiceItem(item, itemIndex) {
      if (this.splitLoading)
        return;

      //CHECK IF ITEM IS VOUCHER
      if (item.ean.includes("V$") || item.ean.includes("GF$") || item.ean.includes("ZMYLE$") || item.ean.includes("MYWORLD$"))
        return;

      let splitItem = this.splitItems.find((splitItem) => {
        return splitItem.randomPositionID === item.randomPositionID;
      });

      //CALC AMOUNT TO CHANGE -> WE NEED TO DO THIS BECAUSE AMOUNT CAN ALSO BE WEIGHT, ETC.
      let addAmount = 1;

      if (item.amount < 1 && item.amount > 0)
        addAmount = item.amount;

      //CHECK IF RETOURE ITEM ALREADY EXISTS
      if (!splitItem) {
        let newItem = Object.assign({}, item);
        newItem.amount = addAmount;
        this.splitItems.push(newItem);
        ////console.log("!splitItem")
      } else {
        splitItem.amount += addAmount;
        splitItem.amount = this.roundToTwo(splitItem.amount);
        ////console.log("splitItem")
      }

      //CHECK AMOUNT
      if (item.amount > 1) {
        item.amount -= addAmount;
        item.amount = this.roundToTwo(item.amount);
        ////console.log("item.amount > 1")
      } else {
        item.amount -= addAmount;
        item.amount = this.roundToTwo(item.amount);
        ////console.log("item.amount < 1", item.amount)
        //this.$delete(this.invoiceItems, itemIndex);
      }
    },
    selectSplitItem(item, itemIndex) {
      if (this.splitLoading || item.amount <= 0)
        return;

      let invoiceItem = this.invoiceItems.find((invoiceItem) => {
        return invoiceItem.randomPositionID === item.randomPositionID;
      });

      //CALC AMOUNT TO CHANGE -> WE NEED TO DO THIS BECAUSE AMOUNT CAN ALSO BE WEIGHT, ETC.
      let addAmount = 1;

      if (item.amount < 1 && item.amount > 0)
        addAmount = item.amount;

      //CHECK IF INVOICE ITEM ALREADY EXISTS
      if (!invoiceItem) {
        let newItem = Object.assign({}, item);
        newItem.amount = addAmount;

        this.invoiceItems.push(newItem);
      } else {
        invoiceItem.amount += addAmount;
        invoiceItem.amount = this.roundToTwo(invoiceItem.amount);
      }

      //CHECK AMOUNT
      if (item.amount > 1) {
        item.amount -= addAmount;
        item.amount = this.roundToTwo(item.amount);
      } else {
        item.amount -= addAmount;
        item.amount = this.roundToTwo(item.amount);
        //this.$delete(this.splitItems, itemIndex);
      }
    },
    getTotalSumOfArray(positions) {
      let total2 = 0;

      positions.forEach((item) => {
        if (!item.isVoid) {
          total2 += item.amount * (((100 - item.discount) / 100) * item.sellPrice);
        }
      });

      return total2;
    },
    roundToTwo(value) {
      return +(Math.round(value + "e+2") + "e-2");
    },
    openCashoutDialog() {
      this.showCashoutDialog = true;
    },
    tablePartySelected(payload) {
      this.showTablesDialog = false;

      this.splitLoading = true;

      //GET POSITIONS FROM TARGET TABLE
      this.axios.post(ENDPOINTS.POS.GASTRO.ORDERS.GET, {
        tableName: payload.table.name
      }).then((res) => {
        if (res.data.success) {
          let tableOrders = res.data.orders;

          let destinationArray = [];
          let sourceArray = this.splitItems.filter(item => item.amount > 0);
          //let sourceArray = this.splitItems;
          let mergedArray = [];

          //TRY TO COLLECT DATA FOR MERGING + MERGE THEM
          try {
            if (tableOrders.hasOwnProperty(payload.partyName))
              destinationArray = tableOrders[payload.partyName];

            //MERGE BOTH ARRAYS
            mergedArray = mergeInvoiceArray(sourceArray, destinationArray);
          } catch (err) {
            this.splitLoading = false;
          }

          // FILTER SOURCE POSITIONS (UNSET EMPTY POSITIONS)
          let filteredSourcePositions = this.invoiceItems.filter((invoiceItem) => {
            return invoiceItem.amount > 0;
          });

          //MAKE AXIOS CALL
          //SAVE TABLE SPLIT IN DATABASE
          this.axios.post(ENDPOINTS.POS.GASTRO.SPLITTABLE, {
            sourceTable: this.pos.gastro.table.name,
            sourceParty: this.pos.gastro.party.name,
            sourcePositions: filteredSourcePositions,
            targetTable: payload.table.name,
            targetParty: payload.partyName,
            targetPositions: mergedArray
          }).then((res) => {
            if (res.data.success) {
              if (res.data.hasOwnProperty("status"))
                if (res.data.status === "warning") {
                  this.errorRedirect = true;
                  this.errorMessage = res.data.msg;
                  return;
                }

              this.redirect();
            } else {
              if (res.data.hasOwnProperty("msg")) {
                if (res.data.msg === "is Payed true") {
                  this.errorMessage = this.$t('generic.lang_errorPayedOrderMergeSplit');
                } else {
                  this.errorMessage = res.data.msg;
                }
              }
            }

            this.splitLoading = false;
          }).catch(() => {
            this.splitLoading = false;
          })
        }
      }).catch(() => {
        this.splitLoading = false;
      });
    },
    closeErrorDialog() {
      this.errorMessage = "";

      if (this.errorRedirect) {
        this.errorRedirect = false;
        this.redirect();
      }
    },
    redirect() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$router.go();
      } else {
        this.$router.replace({
          name: "pos.gastro.tables"
        });
      }
    },
    depositGroup(item) {
      if (item.depositgroupID === 0)
        return null;

      //FIND DEPOSIT GROUP
      let depositGroup = this.deposit.deposits.find((depositGroup) => {
        return depositGroup.id === item.depositgroupID;
      });

      if (!depositGroup)
        return null;

      return depositGroup;
    },
    itemSellPrice(item) {
      let sellPrice = item.sellPrice;

      // -----------------------------------------------------
      // --------------------- DISCOUNT -----------------------
      //Discount ONLY IN %
      if (item.sellPrice === item.originalSellPrice && item.discount > 0) {
        sellPrice = ((100 - item.discount) / 100) * sellPrice;
      }

      //DISCOUNT WITH PRICE CHANGE
      if (item.sellPrice !== item.originalSellPrice && item.discount === 0) {
        sellPrice = 1 * sellPrice;
      }

      //DISCOUNT IN % AND PRICECHANGE
      if (item.sellPrice !== item.originalSellPrice && item.discount > 0) {
        sellPrice = 1 * (((100 - item.discount) / 100) * sellPrice)
      }
      // -----------------------------------------------------

      //CHECK DEPOSIT
      let depositGroup = this.depositGroup(item);
      if (depositGroup) {
        sellPrice += depositGroup.priceTotal;
      }

      sellPrice = parseFloat(sellPrice).toFixed(2);

      return sellPrice;
    },
  }
}
</script>
