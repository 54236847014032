<template>
  <v-layout class="mb-1">
    <v-flex>
      <v-card
        @click="addGFOrV('V$')"
        tile
        ripple
        flat
        height="100%"
        :color="activeBtn === 1 ? '#3AC47D' : ''"
        class="pt-3 pb-2 text-center"
        style="margin-right: 1px"
      >
        <h5>V$</h5>
      </v-card>
    </v-flex>

    <v-flex>
      <v-card
        @click="addGFOrV('GF$')"
        tile
        ripple
        flat
        height="100%"
        :color="activeBtn === 2 ? '#3AC47D' : ''"
        class="pt-3 pb-2 text-center"
        style="margin-left: 1px"
      >
        <h5>GF$</h5>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "ButtonsAddGFOrV",

  data() {
    return {
      activeBtn: null,
    };
  },

  methods: {
    addGFOrV(prefix) {
      if (prefix == "V$") {
        if (this.activeBtn == 1) {
          this.activeBtn = null;
          this.$emit("addGFOrV", "");
          return;
        }
        this.activeBtn = 1;
      } else {
        if (this.activeBtn == 2) {
          this.activeBtn = null;
          this.$emit("addGFOrV", "");
          return;
        }
        this.activeBtn = 2;
      }

      this.$emit("addGFOrV", prefix);
    },
  },
};
</script>
