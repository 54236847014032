<template>
  <div>
    <button class='btn-icon-vertical btn-square pa-0 br-tr border-right btn-transition btn btn-outline-primary' @click="dialog=true">
      <i class="btn-icon-wrapper">
        <font-awesome-icon style="font-size: 25px" :icon="['fad','map-marker-edit']"/>
      </i>
    </button>
    <v-dialog v-model="dialog" persistent max-width="60%" no-click-animation>
      <v-card>
        <v-toolbar dense flat>
          <v-toolbar-title>{{ disableZipCodeCheck ? $t('generic.lang_deliveryArea') : $t('generic.lang_zipCodePicker') }}</v-toolbar-title>

          <v-spacer/>

          <v-btn icon @click="dialog=false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="ma-0"/>
        <v-card-text class="overflow-auto position-relative" style="height: 350px;">
          <v-container fluid class="px-0">
            <v-row justify="center">
              <v-col cols="10">
                <v-menu v-if="dialog"
                    v-model="focus"
                    :close-on-content-click="false"
                    :nudge-right="0"
                    transition="scroll-y-transition"
                    offset-y style="width:100%;"
                    bottom nudge-top="10"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="search"
                        v-on="on"
                        outlined
                        dense
                        prepend-inner-icon="mdi-map-marker-radius-outline"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        @blur="focus=false;"
                        :loading="loading"
                        aria-autocomplete="none"
                        @focus="showTouchKeyboard" >
                    </v-text-field>
                  </template>
                  <v-card max-height="300" width="100%" max-width="100%">
                    <v-list>
                      <template v-if="areas && areas.length>0">
                        <v-list-item v-for="(area,i)  in areas" :key="i" @click="selectDeliveryArea(area)" two-line>
                          <v-list-item-content>
                            <v-list-item-title v-if="disableZipCodeCheck">
                              {{ area.areaName }} {{ area.city ? ` - ${area.city}` : ''}} {{ area.zipCode? `(${area.zipCode})`:'' }}
                            </v-list-item-title>
                            <v-list-item-title v-else>
                              {{ area.zipCode }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ $t('generic.lang_min') }}:{{area.minOrder|currency}}/ {{ $t('generic.lang_cost') }}:{{area.deliveryCost|currency}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <v-icon>mdi-car</v-icon>{{area.carDeliveryTime }}{{ $t('generic.lang_min') }}, <v-icon>mdi-moped</v-icon>{{area.motorcycleDeliveryTime }}{{ $t('generic.lang_min') }}
                            </v-list-item-subtitle>
                          </v-list-item-content>

                        </v-list-item>
                      </template>

                      <template v-else>
                        <v-list-item >
                          <v-list-item-content>
                            {{ $t('generic.lang_no_data') }}
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                    </v-list>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="success" @click="setDeliveryInfo()" :disabled="!selectedDeliveryArea||(selectedDeliveryArea&&selectedDeliveryArea.zipCode!==search)">
            {{ $t('generic.lang_select') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard" style="z-index: 299 !important;">
        <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                            :accept="hidetouchKeyboard"
                            :cancel="hidetouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidthExpanded"/>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {library} from "@fortawesome/fontawesome-svg-core"
import {
  faMapMarkerEdit
} from "@fortawesome/pro-duotone-svg-icons";


library.add(faMapMarkerEdit)

import {mapState,mapGetters} from "vuex"
import {ENDPOINTS} from "@/config";
import KeyboardMixIns from "../../../mixins/KeyboardMixIns";
import randomString from "randomstring";

export default {
  name: "ButtonsDeliveryArea",
  components:{
    'font-awesome-icon':FontAwesomeIcon,
  },
  mixins:[KeyboardMixIns],
  data(){
    return{
      search:'',
      dialog:false,
      focus:false,
      loading:false,
      awaitingSearch:null,
      areas:null,
      selectedDeliveryArea:null,
    }
  },
  watch:{
    dialog(val) {
      //SET ITEM FREETEXT IF AVAILABLE
      if (val) {
        if(!this.disableZipCodeCheck){
          this.search=this.deliveryInfo?this.deliveryInfo.zipCode:(this.pos.gastro.customer?this.pos.gastro.customer.zip:(this.$route.query.hasOwnProperty('hasMultipleArea')&&this.$route.query.hasMultipleArea)?this.$route.query.hasMultipleArea:'');
          this.searchZipCode()
        }
      } else {
        this.focus=false;
      }

      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (val) {
        this.$root.$emit('barcodeScanner.pause');

      } else {
        this.focus=false;
        this.selectedDeliveryArea=null,
        this.$root.$emit('barcodeScanner.unpause');
      }
    },
    search: function (val) {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
          this.searchZipCode();
      }, 500);
    },
  },
  computed:{
    ...mapState(['pos']),
    ...mapGetters({
      delivery:'pos/gastro/getDelivery',
      deliveryInfo:'pos/gastro/getDeliveryInfo',
    }),
    disableZipCodeCheck() {
      return Number(this.$store.getters['settings/getSettingValue']('lb_disableZipCodeCheck')) === 1
    }
  },
  methods:{
    searchZipCode(){
      this.loading = true;
      this.areas=[];
      const postData = {}

      if(this.disableZipCodeCheck){
        postData.areaName = this.search;
      }else {
        postData.zip = this.search;
      }

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.DELIVERYAREA.SEARCH, postData).then((res) => {
        if(res.data && res.data.length>0){
          if(res.data && res.data.length>0){
            this.areas=res.data;
          }
        }
        this.focus=true;
      }).catch((err) => {
        console.log(err)
      }).finally(()=>{
        this.loading = false;
      })
    },
    selectDeliveryArea(area){
      if(this.disableZipCodeCheck){
        this.search = `${area.areaName}` + (area.city ? ` - ${area.city}` : '') + (area.zipCode? `(${area.zipCode})`:'')
      }else {
        this.search = area.zipCode;
      }
      this.selectedDeliveryArea=area
      this.focus = false;
    },
    setDeliveryInfo(){
      this.$store.commit('pos/gastro/setDeliveryInfo',this.selectedDeliveryArea);
      this.$store.dispatch('pos/gastro/setDelivery',true);
      this.dialog=false;
    },
  },

  mounted() {
    this.$root.$on('showDeliveryAreaDialog',()=>{
      this.dialog=true;
    })
  },

  destroyed(){
    this.$root.$off('showDeliveryAreaDialog')
  }
}
</script>

<style scoped>

</style>
