<template>
  <div>
    <button
      @click="showDialog = true"
      class="btn-icon-vertical btn-square br-tr btn-transition btn btn-outline-primary pa-0"
    >
      <i class="btn-icon-wrapper" style="display: block !important"><font-awesome-icon :icon="['fal', getCourseIcon]" /> </i>
      <span v-if="showButtonText">{{ $t('generic.lang_pager') }}</span>
    </button>
    <ButtonsCourseDialog
      :pos-type="posType"
      :show-dialog="showDialog"
      @closeDialog="showDialog = false"
    />
  </div>
</template>

<style scoped>
.btn-icon-vertical {
  padding: 0 !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ButtonsCourseDialog from './ButtonsCourseDialog';
import { mapState } from 'vuex';
import {
  faBarcode,
  faCheeseburger,
  faCocktail,
  faPersonCarry,
  faCroissant,
  faFish,
  faFrenchFries,
  faGifts,
  faConciergeBell,
  faHamburger,
  faHotdog,
  faMeat,
  faPizzaSlice,
  faSalad,
  faSandwich,
  faShishKebab,
  faSoup,
  faTaco,
  faUtensilsAlt,
  faClipboardList,
} from '@fortawesome/pro-light-svg-icons';

library.add(
  faHotdog,
  faBarcode,
  faCocktail,
  faPersonCarry,
  faMeat,
  faGifts,
  faConciergeBell,
  faHamburger,
  faCheeseburger,
  faFrenchFries,
  faPizzaSlice,
  faShishKebab,
  faFish,
  faCroissant,
  faSalad,
  faTaco,
  faSandwich,
  faSoup,
  faUtensilsAlt,
  faClipboardList
);

library.add(faClipboardList);

export default {
  name: 'ButtonsCourse',
  components: {
    ButtonsCourseDialog,
    'font-awesome-icon': FontAwesomeIcon,
  },
  props: {
    posType: String,
    showButtonText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapState(['pos']),
    getCourseIcon() {
      if (this.pos.gastro.selectedCourse === null) {
        return 'clipboard-list';
      } else {
        if (
          this.pos.gastro.selectedCourse.courseIcon === null ||
          this.pos.gastro.selectedCourse.courseIcon === ''
        ) {
          return 'utensils-alt';
        } else {
          return this.pos.gastro.selectedCourse.courseIcon;
        }
      }
    },
  },
};
</script>
