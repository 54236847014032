<template>
  <v-dialog v-model="showDialog" max-width="450px" persistent scrollable @keydown.enter="increaseInvoice()">
    <v-card>
      <v-toolbar :color="this.$vuetify.breakpoint.smAndDown? '#FF6969' : ''" dense flat>
        <v-toolbar-title :class="this.$vuetify.breakpoint.smAndDown? 'text-white' : ''">
          {{$t('generic.lang_invoiceSurcharge')}}
        </v-toolbar-title>

        <v-spacer/>

        <v-btn :class="this.$vuetify.breakpoint.smAndDown? 'text-white' : ''" icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0"/>
      <v-card-text class="pa-0">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="discountValue" :label="$t('generic.lang_invoiceSurcharge')" aria-autocomplete="none"
                            filled outlined suffix="%" type="number"/>
              <NumpadComponent ref="numpad" show-discount-buttons @resetField="numpadResetField"
                               @updateValue="numpadUpdateValue"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions :class="this.$vuetify.breakpoint.smAndDown? 'card-footer pa-1' : ''">
        <v-container class="pa-1">
          <v-row no-gutters>
            <v-col cols="6">
              <v-btn :color="this.$vuetify.breakpoint.smAndDown? 'primary' : '#FF6969'"
                     :elevation="this.$vuetify.breakpoint.smAndDown? 0 : ''"
                     :rounded="this.$vuetify.breakpoint.smAndDown"
                     :small="this.$vuetify.breakpoint.smAndDown"
                     :text="this.$vuetify.breakpoint.smAndDown" class="ma-0"
                     @click="increaseInvoice()">
                {{$t('generic.lang_invoiceSurcharge')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard" style="z-index: 299 !important;">
        <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                            :accept="hideTouchKeyboard"
                            :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidthExpanded"/>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import KeyboardMixIns from "../../../mixins/KeyboardMixIns";
import Numpad from "@/components/pos/Numpad";
import NumpadComponent from "@/components/pos/NumpadComponent";

export default {
  name: "ButtonsIncreaseDialog",

  components: {
    NumpadComponent,
    Numpad,
  },
  mixins: [KeyboardMixIns],
  props: {
    showDialog: Boolean,
    posType: String,
    item: Object
  },


  data() {
    return {
      loading: false,
      discountValue: "",
    }
  },

  watch: {
    showDialog(val) {
      if (this.item !== null && this.item !== undefined) {
        this.itemAmount = this.item.amount;
        if (this.item.discount > 0) {
          this.discountValue = this.item.discount;
        } else {
          this.discountValue = ""
        }
      } else {
        this.discountValue = "";
      }

      this.dialogOpen = val;

      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (val) {
        this.$root.$emit('barcodeScanner.pause')
      } else {
        this.$root.$emit('barcodeScanner.unpause')
      }
    },
    discountValue() {
      if (this.discountValue > 100) {
        this.discountValue = 100;
      }
    }
  },

  methods: {
    closeDialog() {
      this.$refs.numpad.resetField();
      this.numpadResetField()
      this.$emit("closeDialog");
    },
    increaseInvoice() {
      this.$store.dispatch("pos/increaseInvoice", {
        posType: this.posType,
        value: parseFloat(this.discountValue)
      }).then(() => {
        this.closeDialog();
      });
    },
    numpadUpdateValue(value) {
      this.discountValue = value;
    },
    numpadResetField() {
      this.discountValue = '';
    },
  },
}
</script>