<template>
  <div>
    <v-skeleton-loader
      class="mx-auto radius-top-md"
      type="card"
    ></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: 'EventLoaderComponent'
};
</script>
